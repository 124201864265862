import { getEnvConfig } from './utils';

// Load the global configuration file
const globalConfig = getEnvConfig();

/**
 * The function `getLatestEventDetails` returns the latest event details based on the start time from a
 * given event ID name in the CONFIG object.
 * @param eventIdName - The `eventIdName` parameter is a string that represents the name of the event
 * ID for which you want to retrieve the latest event details.
 * @returns The function `getLatestEventDetails` is returning the latest event details based on the
 * `start` time of the events associated with the `eventIdName`. It sorts the events in descending
 * order based on the `start` time and returns the first event (latest event) or `null` if there are no
 * events associated with the `eventIdName`.
 */
export function getLatestEventDetails(eventIdName) {
    return(globalConfig['events'][eventIdName].sort((a, b) => b.start - a.start)[0] || null);
}