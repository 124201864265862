import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { LargeProjectCard } from 'components';
import './LargeProjectCardWrapper.scss';

const LargeProjectCardWrapper = (props) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        // Start the closing animation
        setIsClosing(true);
      
        // Set a timeout that matches the animation duration
        setTimeout(() => {
          props.setShowLargeCard(false); // Replace with your method to close the portal
        }, 100); // This should match the animation duration
    };      

    return ReactDOM.createPortal(
        <div className="overlay" onClick={handleClose}>
            <div className={isClosing ? 'closing-animation' : 'portal-animation'}>
            <LargeProjectCard
                index={props.index}
                className={`${props.className}`}
                projectData={props.projectData}
                editMode={props.editMode}
                setShowLargeCard={props.setShowLargeCard}
                isApplied={props.isApplied}
                setIsApplied={props.setIsApplied}
                handleEditProjectClick={props.handleEditProjectClick}
                handleClose={handleClose}
            />
            </div>
        </div>,
        document.getElementById("modal-root")
    );

}

export default LargeProjectCardWrapper;