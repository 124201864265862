import React, {useState, useEffect, useContext, useRef } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import './ProjectCardForm.scss';
import AuthContext from 'app/AuthContext';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CreatableSelect, { components } from 'react-select';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import questionIcon from 'icons/SJ-Icon-Question.png';
import { LargeProjectCard } from 'components';
import { createUserProject, updateUserProject, deleteUserProject, getDefaultUserTitles } from 'lib/user';
import { convertToMarkdown, markdownToPlainText, calculateEffectiveLength, limitContent, getCurrentDateFormatted, sanitizeData } from 'lib/utils';
import { sendEventToAnalytics } from 'lib/reporting';
import { getParamValURL } from 'lib/utils';
import { getTagOpts, getProjStatusOpts, getProjCategoryOpts } from 'lib/project';
import { useNavigate } from 'react-router-dom';
//import { getRoles } from 'lib/project';

const ProjectCardForm = ({ handleActionCompleted, loadUserProjects, profileImage, projectData, editPostMode }) => {

    // Default items for the default options for dropdowns, etc.
    const defaultTitles = getDefaultUserTitles();

    const initialRender = useRef(0);
    const [showLargeCard, setShowLargeCard] = useState(true);
    const {authToken, userMetadata, logout} = useContext(AuthContext);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [inputRoleValue, setInputRoleValue] = useState('');
    const [inputTagValue, setInputTagValue] = useState('');
    const navigate = useNavigate();

    // This is used to load the project data from sessionStorage if there is an incomplete project form for both edited and new posts.
    let savedData = undefined;
    if (editPostMode) {
        savedData = sessionStorage.getItem('personalProjcetData');
    } else {
        savedData = sessionStorage.getItem('newProjectData');
    }
    
    // Get tag value
    let tagList = getParamValURL("tag", window.location.href);
    if (!tagList) { tagList = []; }

    // A dictionary of the form data that we want to save.
    let parsedData = {};

    // TODO: savedData is undefined when the component mounts. This is a hacky fix.
    try {
        if (typeof savedData !== "undefined" && savedData !== null) {
            parsedData = JSON.parse(savedData);
            if (parsedData && typeof parsedData === 'object') {
                if (tagList.length > 0) {
                    if (Array.isArray(parsedData.tags)) {
                        const uniqueValues = tagList.filter(value => !parsedData.tags.includes(value));
                        parsedData.tags.push(...uniqueValues);
                    } else {
                        parsedData.tags = tagList;
                    }
                }
            }
        }
    } catch (e) {
        if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error('Invalid JSON:', e); }
    }

    /* Initialie a state variable called `formData` using the `useState` hook in
    React. The initial value of `formData` is an object with some properties. 
    The values of these properties are either taken from the `parsedData` object if it exists, 
    or set to default values if `parsedData` is undefined. */
    const [formData, setFormData] = useState({
        category: parsedData?.category || 'Category',
        taggedAs: parsedData?.taggedAs || '',
        tags: parsedData?.tags || tagList,
        title: parsedData?.title || '',
        description: parsedData?.description || '',
        status: parsedData?.status || 'Status',
        missionStatement: parsedData?.missionStatement || '',
        solution: parsedData?.solution || '',
        recruitingFor: parsedData?.recruitingFor || '',
        roles: parsedData?.roles || [],
        link: parsedData?.link || '',
    });

    // Items for the categories dropdown
    const categoryOptions = getProjCategoryOpts();
    // Items for the status dropdown
    const statusOptions = getProjStatusOpts();

    // Initialize an empty array to store tagGroupedOptions
    const tagGroupedOptions = getTagOpts();
    // Function to generate options for Form.Control from tagGroupedOptionsArray
    const generateOptions = () => {
        return tagGroupedOptions.map(option => (
            <option key={option.label}>{option.label}</option>
        ));
    };

    const [tags, setTags] = useState(formData.tags);
    const tagsListRef = useRef(null);
    const [availableTags, setAvailableTags] = useState(tagGroupedOptions);
    const [tagFilter, setTagFilter] = useState('All');
    // Used as a reference to the role item in the list where a new role is added
    const rolesListRef = useRef(null);
    // Constants used for handling the setting of individual roles
    const [roles, setRoles] = useState(formData.roles);
    const [availableRoles, setAvailableRoles] = useState(defaultTitles);
    const [roleFilter, setRoleFilter] = useState('All');

    // Handler function for the tag filter dropdown
    const handleTagInputChange = (input) => {
        // Limit the input value to 36 characters
        if (input && input.length > 36) {
            return;
        }
        setInputTagValue(input);

        if(input.length > 0) {
            debouncedAPIcallRefTags.current(input);
        } else {
            // If input is empty, reset the availableTags to tagGroupedOptions.
            setAvailableTags(tagGroupedOptions);
        }
        handleFormDataChange('taggedAs', input);
    };

    // Handler function for the role filter dropdown
    const handleRoleInputChange = (input) => {
        // TODO: Constantly calling the main baker API, use a separate service, ElasticSearch perhaps?
        // if(input.length > 0) {
        //     debouncedAPIcallRef.current(input);
        // } else {
        //     // If input is empty, reset the availableRoles to groupedOptions.
        //     setAvailableRoles(availableRoles);
        // }

        // Limit the input value to 36 characters
        if (input && input.length > 36) {
            return;
        }
        setInputRoleValue(input);

        setAvailableRoles(defaultTitles);
        handleFormRoleDataChange('recruitingFor', [input]);
    };

    // Activated when selecting a tag from the dropdown
    const handleTagSelect = (selectedOption) => {
        if (selectedOption) {
            handleAddTag(selectedOption.value);
        }
    };
    
    // Activated when adding a role from the dropdown
    const handleRoleSelect = (selectedOption) => {
        if (selectedOption) {
            handleAddRole(selectedOption);
        }
    };

    // Filters tags
    const filteredTags = () => {
        if (tagFilter === 'All') {
            return availableTags;
        }
        return availableTags.filter(group => group.label === tagFilter);
    };

    // TODO: Reintroduce for role filtering in the dropdown
    const filteredRoles = () => {
        if (roleFilter === 'All') {
            return availableRoles;
        }
        return availableRoles.filter(group => group.label === roleFilter);
    };

    // No category name for the tag dropdown
    // const filteredRoles = () => {
    //     if (roleFilter === 'All') {
    //         return defaultTitles.flatMap(group => group.options);
    //     }
    //     const filteredGroup = defaultTitles.find(group => group.label === roleFilter);
    //     return filteredGroup ? filteredGroup.options : [];
    // };

    // Debounce the fetch tags API call to avoid making too many requests
    const debouncedAPIcallRefTags = useRef(debounce((input) => {
        // Your API call logic here
        fetchTags(input);
    }, 100));

    // Debounce the fetch roles API call to avoid making too many requests
    // const debouncedAPIcallRef = useRef(debounce((input) => {
    //     // Your API call logic here
    //     fetchRoles(input);
    // }, 100));
    
    const fetchTags = async (input) => {
        try {
            const fetchedTags = {
                "results": [
                    {'name': 'VentureCafe20231102'}, 
                    {'name': 'PSLWG20240201'}
                ]
            };
            
            // console.log(availableTags)
            // Let's filter out the tags that are already in the availableTags before adding them
            // TODO: This gives this error ""Failed to fetch tags: TypeError: fetchedTags.filter is not a function"
            //  but the feature works. This addl statement fixes it because it's expecting an array, but the feature
            //  doesn't work.
            //  
            // Access the 'results' property to get the array
            //const tagsArray = fetchedTags.results;
            // const newTags = fetchedTags.filter(fetchedTag => 
            //     !availableTags.some(existingTag => existingTag.value === fetchedTag.value)
            // );
        
            const newTags = fetchedTags.filter(fetchedTag => 
                !availableTags.some(existingTag => existingTag.value === fetchedTag.value)
            );
            
            setAvailableTags(newTags);
        } catch (error) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                console.error("Failed to fetch tags:", error);
            }
        }
    };    

    /**
     * The function `fetchRoles` is an asynchronous function that fetches roles based on an input and
     * updates the available roles list.
     * @param input - The `input` parameter is a query string used to search for roles. It is passed to
     * the `getRoles` function to retrieve a list of roles that match the query.
     */
    // const fetchRoles = async (input) => {
    //     try {
    //         // TODO: Constantly calling the main baker API, use a separate service, ElasticSearch perhaps?
    //         //  OR short-term: hard code it in this react app, see fetchTags()

    //         const { isSuccess: getRolesSuccess, resp: getRolesResp, err: getRolesErr } = 
    //             await getRoles([{'key': 'query', 'value': input}]);
    
    //         if (getRolesSuccess) {
    //             const fetchedRoles = getRolesResp.results.map(roleObj => ({ value: roleObj, label: roleObj.name }));
    //             // Let's filter out the roles that are already in the availableRoles before adding them
    //             const newRoles = fetchedRoles.filter(fetchedRole => 
    //                 !availableRoles.some(existingRole => existingRole.value === fetchedRole.value)
    //             );
                
    //             setAvailableRoles(newRoles);
    //         }
    //     } catch (error) {
    //         if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Failed to fetch roles:", error); }
    //     }
    // }; 

    // Handles the display for adding a role or not being able to add a role
    const NoOptionsMessage = (props) => {
        const {
            selectProps: { inputValue }
        } = props;
        
        const handleClick = () => {
            let formattedInput;
            // Format tag input values
            if (props.selectProps.onCreateOption.name === "handleAddTag") {
                // formattedInput = inputValue.toLowerCase();
                // Remove all special characters except dashes '-'
                formattedInput = inputValue.replace(/[^\w]+/g, '');
            } else {
                formattedInput = inputValue.replace(/\b\w/g, char => char.toUpperCase());
            }
            props.selectProps.onChange({ value: formattedInput, label: formattedInput });
            props.selectProps.onInputChange('');  // Clear the input value
            props.selectProps.onMenuClose();      // Close the dropdown
        }        
      
        return (
            <components.NoOptionsMessage {...props}>
                <div className="select-no-options" onClick={handleClick}>
                    {inputValue ? `Add: ${inputValue}` : "No options"}
                </div>
            </components.NoOptionsMessage>
        );
    };
    
    // Placeholders for the user-facing form
	const [placeholders, setPlaceHolders] = useState({
		title: "No company yet? Give your startup a name.",
		description: 'A (what) that does (what).',
		missionStatement: 'Why pursue this? Convey the core purpose, value, and impact your business aims to create.',
		solution: 'How does it work? Without having to dive into the proprietary aspects of your product or service, explain how your business solves the problem.',
        roleDescription: 'Provide a brief description of the role and the type of person you are looking for.',
        link: 'Social media, website, etc.'
	});
    
    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [errors, setErrors] = useState({});

    // Input error handling
    const findFormErrors = () => {
        const newErrors = {}
        
        // If the category is empty, we show an error
        if (formData.category === 'Category' || formData.category === 'Pick a category') {
            newErrors.category = 'Please choose a category';
        }
        
        // If the title is empty, we show an error
        if (!formData.title.trim()) {
            newErrors.title = 'Please enter a startup name';
        }

        if (!formData.description.trim()) {
            newErrors.description = 'Please enter a description';
        }

        // If the status is empty, we show an error
        if (formData.status === 'Status' || formData.status === 'What stage are you at?') {
            newErrors.status = 'Please choose a startup status';
        }
    
        // If the description is empty, we show an error
        if (!formData.description.trim()) {
            newErrors.description = 'Please enter a description';
        }
    
        // If the mission statement is empty, we show an error
        if (!formData.missionStatement.trim()) {
            newErrors.missionStatement = 'Please enter a mission statement';
        }
    
        // If the solution is empty, we show an error
        if (!formData.solution.trim()) {
            newErrors.solution = 'Please enter a solution';
        }

        // If there are no roles, we show an error
        if (formData.roles.length === 0) {
            newErrors.roles = 'Please add at least one role';
        }

        return newErrors;
    };

    // Displays API-related error messages to the user
    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    // Handles the change in form data
    const handleFormDataChange = (key, value, maxChars = Infinity, maxLines = Infinity) => {
        const limitedValue = limitContent(value, maxChars, maxLines);
        setFormData((prevState) => ({ ...prevState, [key]: limitedValue }));
        
        if (errors[key]) {
            setErrors((prevState) => {
                const newErrors = { ...prevState };
                delete newErrors[key];
                return newErrors;
        });
        }
    };

    const handleFormRoleDataChange = (key, data, maxChars = Infinity, maxLines = Infinity) => {
        const limitedData = data.map(item => {
            setFormData((prevState) => ({ ...prevState, [key]: limitedData }));
        
            if (errors[key]) {
                setErrors((prevState) => {
                    const newErrors = { ...prevState };
                    delete newErrors[key];
                    return newErrors;
                });
            }

            return {
                ...item,
                role_name: limitContent(item.role_name, maxChars, maxLines),
                label: limitContent(item.label, maxChars, maxLines)
            };
        });
    };

    // Adds a role to the existing roles in the form data
    const handleAddRole = (input) => {
        let roleObj = {}
        if (typeof input !== 'object' && input !== null) {
            roleObj = { role_name: input.role_name, label: input.label, description: '' };
        } else {
            roleObj = input;
        }
        if (typeof roleObj === 'object' && roleObj !== null && !roles.some(role => role.role_name?.toLowerCase() === roleObj.role_name?.toLowerCase())) {
            setRoles((prevRoles) => {
                const newRoles = [...prevRoles, roleObj];
                handleFormRoleDataChange('recruitingFor', []);
                handleFormRoleDataChange('roles', newRoles);
                return newRoles;
            });
        } else {
            // If the role already exists, display an error
            setErrors((prevState) => ({ ...prevState, roles: 'This role already exists' }));
        }
    };

    /**
     * The `handleAddTag` function checks if a tag already exists in an array, and either adds it if it
     * doesn't exist or updates it with the new case if it does.
     * @param tag - The `tag` parameter in the `handleAddTag` function represents the tag that needs to
     * be added or updated in the list of tags. The function first converts the tag to lowercase for
     * case-insensitive comparison and then checks if the tag already exists in the `tags` array. If
     * the tag
     */
    const handleAddTag = (tag) => {
        const lowercaseTag = tag.toLowerCase();
        const tagIndex = tags.findIndex(t => t.toLowerCase() === lowercaseTag);
    
        if (tag && tagIndex === -1) {
            // Tag doesn't exist, add it
            setTags((prevTags) => {
                const newTags = [...prevTags, tag];
                handleFormDataChange('taggedAs', '');
                handleFormDataChange('tags', newTags);
                return newTags;
            });
        } else if (tagIndex !== -1) {
            // Tag exists, update it with the new case
            const updatedTags = [...tags];
            updatedTags[tagIndex] = tag;
    
            setTags((prevTags) => {
                handleFormDataChange('tags', updatedTags);
                return updatedTags;
            });
        }
    };

    const handleUpdateRoleDescription = (roleName, newDescription, maxChars = Infinity, maxLines = Infinity) => {
        const limitedValue = limitContent(newDescription, maxChars, maxLines);

        // Create a new array with the updated role object
        const updatedRoles = roles.map(role => {
          if (role.role_name?.toLowerCase() === roleName?.toLowerCase()) {
            return {
                ...role,
                description: limitedValue
            };
          }
          return role;
        });
        
        // Update the state and form data
        setRoles(updatedRoles);
        handleFormRoleDataChange('roles', updatedRoles);
    };

    const handleRemoveTag = (tag) => {
        const updatedTags = tags.filter((r) => r !== tag);
        setTags(updatedTags);
        handleFormDataChange('tags', updatedTags);
    };
    
    const handleRemoveRole = (role) => {
        const updatedRoles = roles.filter((r) => r !== role);
        setRoles(updatedRoles);
        handleFormDataChange('roles', updatedRoles);
    };
      
    // If a role gets added, this scrolls that role into view if it isn't already in view
    useEffect(() => {
        // Don't scroll on the first render
        if (initialRender.current <= 1) {
          initialRender.current++;
          return;
        }
      
        if ((rolesListRef.current && roles.length > 0) || errors.roles) {
          rolesListRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [roles, errors.roles]);

    // Use an effect to observe changes in the tags array
    useEffect(() => {
        if ((tagsListRef.current && tags.length > 0) || errors.tags) {
          tagsListRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [tags, errors]); 

    // Saves an unfinished project form before the modal closes.
    useEffect(() => {
        return () => {
            // This code will run just before the component unmounts...
            if (formData) {
                if (!actionCompleted) {
                    const sanitizedData = sanitizeData(formData);
                    if (editPostMode) {
                        sessionStorage.setItem('personalProjectData', JSON.stringify(sanitizedData));
                    } else {
                        sessionStorage.setItem('newProjectData', JSON.stringify(sanitizedData));
                    }
                } else {
                    if (editPostMode) {
                        sessionStorage.removeItem('personalProjectData');
                    } else {
                        sessionStorage.removeItem('newProjectData');
                    }
                }
            }
        };
    }, [formData, actionCompleted]); // Re-run this effect if props.formData changes.

    /**
     * The function `deleteProject` is an asynchronous function that attempts to delete a project and
     * handles the success or failure of the deletion.
     */
    async function deleteProject() {
        try {
            // Update the project
            const { isSuccess: deleteProjSuccess, resp: deleteProjResp, err: deleteProjErr } = 
                await deleteUserProject(userMetadata.id, projectData.project_id, authToken);

            if (deleteProjSuccess) {
                handleActionCompleted();
                setActionCompleted(true);
                loadUserProjects(userMetadata.id);
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Project delete || Success:", deleteProjResp); }
                if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("Project", "project_deleted"); }
                // TODO: This is not efficient/performant, need to handle this by closing other modal's
                navigate(`/profile/${userMetadata.id}`);
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Project delete || Error:", deleteProjResp, deleteProjErr); }
                if (deleteProjResp) {
                    // Log out user if the token is invalid/expired.
                    if (deleteProjResp.status_code === "UNAUTHORIZED") {
                        logout();
                    } else {
                        displayError(deleteProjResp.message);
                    }
                } else {
                    displayError("Fatal error, please check back shortly.");
                }
            }
        } catch (error) {
            displayError("Fatal error, please check back shortly.");
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error deleting a project:", error); }
        }
    }

    /**
     * The `updateProject` function is used to update a project with new data, including its
     * visibility, title, category, tags, status, description, mission statement, solution, recruiting
     * roles, and link.
     * @param visibility - The `visibility` parameter is used to determine the visibility of the
     * project. It can have values like "public", "private", or "unlisted" to specify who can view the
     * project.
     */
    async function updateProject(visibility) {
        // Verify form inputs
        const newErrors = findFormErrors();

        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            // Prepare data to submit
            let data = {
                "visibility": visibility,
                "title": formData.title || "(Missing Title)",
                "category": formData.category || "(Missing Category)",
                "tags": formData.tags,
                "status": formData.status || "(Missing Status)",
                "description": formData.description || "(Missing Desc.)",
                "mission_statement": formData.missionStatement || "(Missing Statement)",
                "solution": convertToMarkdown(formData.solution) || "(Missing Solution)",
                "recruiting_roles": formData.roles,
                "link": `https://${formData.link}` || ""
            }

            try {
                // Update the project
                const { isSuccess: updateProjSuccess, resp: updateProjResp, err: updateProjErr } = 
                    await updateUserProject(userMetadata.id, projectData.project_id, data, authToken);

                if (updateProjSuccess) {
                    handleActionCompleted();
                    setActionCompleted(true);
                    loadUserProjects(userMetadata.id);
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Project update || Success: ", updateProjResp); }
                    if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("Project", "project_updated"); }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Project update || Error: ", updateProjResp, updateProjErr); }
                    if (updateProjResp) {
                        if (updateProjResp.status_code === "UNAUTHORIZED") {
                            logout();
                        } else {
                            displayError(updateProjResp.message);
                        }
                    } else {
                        displayError("Fatal error, please check back shortly.");
                    }
                }
            } catch (error) {
                displayError("Fatal error, please check back shortly.");
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error('Error updating the project:', error); }
            }
        }
    }
      
    /**
     * The `submitProject` function is used to submit a project with the provided visibility and form
     * data, performing validation checks and handling any errors that may occur.
     * @param visibility - The `visibility` parameter is a string that represents the visibility
     * setting for the project. It can have one of the following values:
     */
    async function submitProject(visibility) {
        // If it's not a draft, then check for empty fields
        if(visibility !== "private") { var newErrors = findFormErrors(); }

        if ( newErrors && Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {

            // Convert roles to the correct format
            formData.roles = formData.roles.map(role => {
                return {
                    "role_name": role.role_name,
                    "category": role.category,
                    "description": role.description || null
                };
            });

            // Prepare data to submit
            let data = {
                    "visibility": visibility,
                    "title": formData.title || "(Missing Title)",
                    "category": formData.category || "(Missing Category)",
                    "tags": formData.tags,
                    "status": formData.status || "(Missing Status)",
                    "description": formData.description || "(Missing Desc.)",
                    "mission_statement": formData.missionStatement || "(Missing Statement)",
                    "solution": convertToMarkdown(formData.solution) || "(Missing Solution)",
                    "recruiting_roles": formData.roles,
                    "link": `https://${formData.link}` || ""
            }
            
            try {

                console.log("submitProject -> data", data);
                 // Create the project
                const { isSuccess: createProjSuccess, resp: createProjResp, err: createProjErr } = 
                    await createUserProject(userMetadata.id, data, authToken);

                if (createProjSuccess) {
                    handleActionCompleted();
                    setActionCompleted(true);
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Project creation || Success: ", createProjResp); }
                    if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("Project", "project_created"); }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Project creation || Error:", createProjResp, createProjErr); }
                    if (createProjResp) {
                        if (createProjResp.status_code === "UNAUTHORIZED") {
                            logout();
                        } else {
                            displayError(createProjResp.message);
                        }
                    } else {
                        displayError("Fatal error, please check back shortly.");
                    }
                }
            } catch (error) {
                displayError("Fatal error, please check back shortly.");
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error('Error creating a project:', error); }
            }
        }
    }

    /**
     * The function `loadProjectData` is used to load project data into a form and update the form
     * fields accordingly.
     * @param projectData - An object containing data for a project. It has the following properties:
     */
    const loadProjectData = (projectData) => {
        handleFormDataChange('title', projectData.title);
        handleFormDataChange('category', projectData.category);
        const tagNames = projectData.tags.map(tag => tag.display_name ? tag.display_name : tag.id);
        handleFormDataChange('tags', tagNames);
        setTags(tagNames);
        handleFormDataChange('status', projectData.status);
        handleFormDataChange('description', projectData.description);
        handleFormDataChange('missionStatement', projectData.mission_statement);
        handleFormDataChange('solution', projectData.solution);
        handleFormDataChange('link', projectData.link ? projectData.link.substring(8) : '');
        const existingRoles = projectData.recruiting_roles;
        handleFormRoleDataChange('roles', existingRoles);
        setRoles(existingRoles);
    }

    // Trigger loadUserProfile when the component mounts
    useEffect(() => {
        if (projectData) {
            loadProjectData(projectData);
        }
    }, [projectData]);

    // Styles for the category and status dropdowns
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '100%',
            marginRight: '10px',
            borderColor: '#edecec',  // Set border color to gray
            borderRadius: '0.75em',  // Set border radius to 0.75em
            margin: '0px 5px 0px 0px'
        }),
        control: (provided) => ({
            ...provided,
            borderColor: '#edecec',  // Set border color to gray
            borderRadius: '0.75em',  // Set border radius to 0.75em
            margin: '0px 5px 0px 0px'
        })
    }
      
    // Handles the click of the question mark icon next to an input
    const handleQuestionClick = (section) => {
        window.open(`/resources#${section}`, "_blank");
    }

  return (
    <div className="project-card-form-container">
        <Form className='project-card-form'
            onSubmit={(e) => {
                e.preventDefault();
            }}
            >
            <Form.Group controlId="category">
                <Form.Label>Category/Industry</Form.Label>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Select
                        options={categoryOptions}
                        placeholder="Pick a category"
                        value={categoryOptions.find(option => option.value === formData.category)}
                        onChange={(option) => handleFormDataChange('category', option ? option.value : 'Category')}
                        isClearable={true}
                        styles={customStyles}
                    />
                    <span className="icon-wrapper">
                        <img onClick={() => handleQuestionClick('categories')} className='form-icon' src={questionIcon} alt="Question Icon"/>
                    </span>
                </div>
                <Form.Control.Feedback type='invalid'>{errors.category}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="status">
                <Form.Label>Current Status</Form.Label>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Select
                        options={statusOptions}
                        placeholder="What stage are you at?"
                        value={statusOptions.find(option => option.value === formData.status)}
                        onChange={(selectedOption) => handleFormDataChange('status', selectedOption ? selectedOption.value : 'Status')}
                        isClearable={true}
                        styles={customStyles}
                    />
                    <span className="icon-wrapper">
                        <img onClick={() => handleQuestionClick('stages')} className='form-icon' src={questionIcon} alt="Question Icon"/>
                    </span>
                </div>
                <Form.Control.Feedback type='invalid'>{errors.status}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="title">
                <Form.Label>Company/Startup Name</Form.Label>
                <div className="textAreaWrapper">
                    <Form.Control
                        type="text"
                        placeholder={placeholders.title}
                        maxLength={20}
                        value={formData.title === '(Missing Title)' ? '' : formData.title}
                        onChange={(e) => {
                            handleFormDataChange('title', e.target.value);
                        }}
                        isInvalid={ !!errors.title}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                    <span className="char-counter-input-box">{formData.title.length}/{20}</span>
                </div>
            </Form.Group>
            <Form.Group controlId="link">
                <Form.Label>Link (Social Media, Website, Etc.)</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={placeholders.link}
                    maxLength={203}  // Original maxLength + 8 for "https://"
                    value={"https://" + formData.link}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.startsWith("https://")) {
                            handleFormDataChange('link', newValue.substring(8));
                        }
                    }}
                />
                <Form.Control.Feedback type='invalid'>{errors.link}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description">
            <Form.Label>What Your Startup Does</Form.Label>
                <div className="textAreaWrapper">
                    <Form.Control
                    type="text"
                    maxLength={100}
                    placeholder={placeholders.description}
                    value={formData.description === '(Missing Desc.)' ? '' : formData.description}
                    onChange={(e) => handleFormDataChange('description', e.target.value, 100, 3)}
                    isInvalid={ !!errors.description}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                    <span className="char-counter-input-box">{formData.description.length}/{100}</span>
                </div>
            </Form.Group>
            <Form.Group controlId="missionStatement">
                <Form.Label>Your Mission Statement</Form.Label>
                <div className="textAreaWrapper">
                    <Form.Control
                        as={'textarea'}
                        placeholder={placeholders.missionStatement}
                        rows={2}
                        type="text"
                        maxLength={100}
                        value={formData.missionStatement  === '(Missing Statement)' ? '' : formData.missionStatement}
                        onChange={(e) => {
                            handleFormDataChange('missionStatement', e.target.value, 100, 2);
                        }}
                        isInvalid={ !!errors.missionStatement}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.missionStatement}</Form.Control.Feedback>
                    <span className="char-counter-textarea">{formData.missionStatement.length}/{100}</span>
                </div>
            </Form.Group>
            <Form.Group controlId="solution">
                <Form.Label>How Your Solution Works Differently Than Others</Form.Label>
                <div className="textAreaWrapper">
                    <Form.Control
                        as={'textarea'}
                        rows={5}
                        placeholder={placeholders.solution}
                        type="text"
                        value={formData.solution === '(Missing Solution)' ? '' : markdownToPlainText(formData.solution)}
                        onChange={(e) => {
                            handleFormDataChange('solution', e.target.value, 260, 5);
                        }}
                        isInvalid={ !!errors.solution}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.solution}</Form.Control.Feedback>
                    <span className="char-counter-textarea">{calculateEffectiveLength(formData.solution)}/{260}</span>
                </div>
            </Form.Group>
            <Form.Group controlId="taggedAs">
                <Form.Label>Are You at an Event? Select a Tag or Add Your Own</Form.Label>
                <div className="role-input-container">
                    <Form.Control 
                        className='role-filter' 
                        as="select" value={tagFilter} 
                        onChange={(e) => setTagFilter(e.target.value)}
                        styles={customStyles}
                    >
                        <option>All</option>
                        {generateOptions()}
                    </Form.Control>
                    <CreatableSelect 
                        options={filteredTags()}  // Use filteredTags function here
                        onInputChange={handleTagInputChange}
                        onChange={handleTagSelect}
                        value={formData.taggedAs ? { value: formData.taggedAs, label: formData.taggedAs } : null}
                        inputValue={inputTagValue}
                        isClearable
                        isSearchable
                        isCreatable={true}
                        components={{ NoOptionsMessage }}
                        placeholder="Pick or add a tag..."
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} // Required for portal to work correctly
                        menuPlacement={'auto'} // Top or bottom based on the available space
                        formatCreateLabel={(inputValue) => `Add "${inputValue}"`} // This shows the "Add tag" option
                        onCreateOption={handleAddTag} // Called when the new tag option is selected
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index if necessary
                            option: (provided, state) => ({
                                ...provided
                            }),
                            ...customStyles
                        }}
                    />
                </div>
                <Form.Control.Feedback type='invalid'>{errors.tags}</Form.Control.Feedback>
                <div className='roles-container' ref={tagsListRef}>
                    <div className="roles-list">
                        {tags.map((tag, index) => (
                            <div key={index} timeout={500} className="item">
                                <li>
                                    {tag ? '#' + tag : "(unknown)"}
                                    {/* <div className="small-delete-button" onClick={() => handleRemoveTag(tag)} /> */}
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleRemoveTag(tag)}
                                    >
                                        Remove
                                    </Button>
                                </li>
                            </div>
                        ))}
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="recruitingFor">
                <Form.Label>What You're Recruiting</Form.Label>
                <div className="role-input-container">
                    {/* TODO: Re-introduce the filtering of roles by category */}
                    {/* <Form.Control className='role-filter' as="select" value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)}>
                        <option>All</option>
                        <option>General</option>
                        <option>Tech</option>
                        <option>Business</option>
                        <option>Specialty</option>
                    </Form.Control> */}
                    <CreatableSelect 
                        options={filteredRoles()}  // Use filteredRoles function here
                        onInputChange={handleRoleInputChange}
                        onChange={handleRoleSelect}
                        value={formData.recruitingFor ? { value: formData.recruitingFor, label: formData.recruitingFor.role_name } : null}
                        inputValue={inputRoleValue}
                        isClearable
                        isSearchable
                        isCreatable={true}
                        components={{ NoOptionsMessage }}
                        placeholder="Pick or add a role..."
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} // Required for portal to work correctly
                        menuPlacement={'auto'} // Top or bottom based on the available space
                        formatCreateLabel={(inputValue) => `Add "${inputValue}"`} // This shows the "Add role" option
                        onCreateOption={handleAddRole} // Called when the new role option is selected
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided
                            }),
                            ...customStyles
                        }}
                    />
                </div>
                <Form.Control.Feedback type='invalid'>{errors.roles}</Form.Control.Feedback>
                <div className='roles-container' ref={rolesListRef}>
                    <TransitionGroup className="roles-list">
                        {roles.map((role, index) => (
                            <CSSTransition key={index} timeout={500} className="item">
                                <li>
                                    <span className='role-name-description'>
                                        {role.role_name ? role.role_name : "Role"}:
                                        <div className="textAreaWrapper">
                                            <Form.Control
                                                as={'textarea'}
                                                placeholder={placeholders.roleDescription}
                                                rows={4}
                                                type="text"
                                                maxLength={400}
                                                value={role.description}
                                                onChange={(e) => {
                                                    handleUpdateRoleDescription(role.role_name , e.target.value, 400, 4);
                                                }}
                                                isInvalid={ !!errors.role_descriptions}
                                            />
                                            <Form.Control.Feedback type='invalid'>{errors.role_descriptions}</Form.Control.Feedback>
                                            <span className="char-counter-textarea">{role.description ? role.description.length : 0}/{400}</span>
                                        </div>
                                    </span>
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => handleRemoveRole(role)}
                                >
                                    Remove
                                </Button>
                                </li>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </Form.Group>
        </Form>
        <div className="project-card-bottom">
            <div className='prompt'>
                <div>See your final draft below before posting!</div>
            </div>
            {showLargeCard && 
                <LargeProjectCard
                    projectData={{
                        category: formData.category,
                        title: formData.title ? formData.title : '',
                        taggedAs: formData.tags ? formData.tags : [],
                        description: formData.description ? formData.description : placeholders.description,
                        status: formData.status,
                        date_posted: getCurrentDateFormatted(),
                        mission_statement: formData.missionStatement ? formData.missionStatement : placeholders.missionStatement,
                        solution: formData.solution ? formData.solution : placeholders.solution,
                        manager_display_name: userMetadata && userMetadata.display_name ? userMetadata.display_name : '',
                        manager_title: userMetadata && userMetadata.user_tags ? userMetadata.user_tags : '',
                        recruiting_roles: formData.roles,
                        link: formData.link,
                        user_avatar: {url: profileImage},
                        visibility: projectData && projectData.visibility? projectData.visibility : 'private',
                        city: projectData && projectData.city ? projectData.city : null,
                        province: projectData && projectData.province ? projectData.province : null,
                        // Disables the 'Edit Button' on the display card
                        applied: true
                    }}
                    hideCloseBtn = {true}
                    editMode = {true}
                    className={''}
                    index={0}
                    setShowLargeCard={setShowLargeCard}
                />
            }
            <div className='create-project-button-wrapper'>
                {editPostMode ? (
                    <div>
                        <Button className='delete-btn' variant="secondary" onClick={() => deleteProject()} centered="true">
                            Delete
                        </Button>
                        <Button variant="secondary" onClick={() => updateProject('private')} centered="true">
                            Save Draft
                        </Button>
                        <Button variant="primary" onClick={() => updateProject('public')} centered="true">
                            Post to Public
                        </Button>
                    </div>
                ): (
                    <div>
                        <Button variant="secondary" onClick={() => submitProject('private')} centered="true">
                            Save Draft
                        </Button>
                        <Button variant="primary" onClick={() => submitProject('public')} centered="true">
                            Post to Public
                        </Button>
                    </div>
                )}
            </div>
                <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>
            </div>
        </div>
    );
};

export default ProjectCardForm;
