import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import { sendResetPwdLink, verifyCaptcha } from 'lib/user';
import { sendEventToAnalytics } from 'lib/reporting';
import { verifyEmail } from 'lib/utils';

const ResetPasswordForm = (props) => {

    // Alerting
    const [ errors, setErrors ] = useState({});
    // Form inputs
    const [state, setState] = useState({email: ''});

    /* The code `const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;` is assigning the
    value of the environment variable `REACT_APP_RECAPTCHA_USER_SITE_KEY` to the variable
    `reCaptchaKey`. This variable is used as the site key for the reCAPTCHA component. */
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = React.useRef();

    /**
     * The function `findFormErrors` checks for errors in an email input field and returns an object
     * containing any errors found.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any errors
     * found during the validation process.
     */
    const findFormErrors = () => {
        const email = state.email;
        const newErrors = {};
        
        if (!email || email === '') { 
            newErrors.email = 'Please enter your email.';
        } else if (email.length > 320) { 
            newErrors.email = 'Email is too long.';
        } else if (verifyEmail(email) === false) {
            newErrors.email = 'Please enter a valid email address.';
        }
    
        return newErrors;
    }
    
    /**
     * The function `sendResetPwdLink` sends a password reset link to the specified email address using
     * a POST request with form data.
     * @param email - The email parameter is the email address of the user who wants to reset their
     * password.
     */
    async function procResetPwdLink(email) {
        // Send to analytics
        if (process.env.REACT_APP_ENVIRONMENT !== "dev") { sendEventToAnalytics("User", "user_reset_password_request"); }

        try {
            // Send the actual password reset link
            const { isSuccess: sendLinkSuccess, resp: sendLinkResp, err: sendLinkErr } = await sendResetPwdLink(email);

            if (sendLinkSuccess) {
                props.onActionCompleted();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Reset password || Success", sendLinkResp); }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Reset password || Error:", sendLinkResp, sendLinkErr); }
                if (sendLinkResp) {
                    if (sendLinkResp.status_code === "NOT_FOUND") {
                        setErrors({"email": "Account not found."});
                    } else if (sendLinkResp.status_code === "FORBIDDEN") {
                        setErrors({"email": "Account not qualified for password reset. If it signed in via a 3rd party provider such as Google/Gmail, please reset your password through that provider."});
                    } else {
                        setErrors({"email": sendLinkResp.message});
                    }
                } else {
                    setErrors("email", "Fatal error, please check back shortly.");
                }
            }        
        } catch (error) {
            setErrors({"email": "Fatal error, please check back shortly."});
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error resetting password:", error); }
        }
    }

    /**
     * An event handler for a form submission that performs form validation,
     * executes reCAPTCHA verification, and sends a request to the backend for suspicion score
     * verification.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * form submission. It contains information about the event, such as the target element, the type
     * of event, and any additional data associated with the event. In this case, the `event` parameter
     * is used to prevent the default
     */
    async function onFormSubmit(event) {
        event.preventDefault();
        const newErrors = findFormErrors();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
        } else {
            try {
                // Execute reCaptcha
                await refCaptcha.current.callbacks.execute();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Processed site key."); }
                const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
                // Verify captcha
                const { isSuccess: captchaSuccess, resp: captchaResponse, err: captchaErr } = await verifyCaptcha(reCaptchatoken);

                if (captchaSuccess) {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Authenticated the user."); }
                    procResetPwdLink(state.email);
                } else {
                    setErrors({"email": "ReCAPTCHA verification failed. Please try again later."});
                    console.error("ReCAPTCHA response came back with error:", captchaResponse, captchaErr);
                }
            } catch (error) {
                setErrors({'email': 'A general error occurred, please try again later.'});
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Send reset link || Error: ", error); }
            }
        }
    };

    return (
        <Form onSubmit={onFormSubmit} className="default-form-format">
            <Form.Group controlId="formBasicEmail">
                <Form.Control 
                    name="email"  
                    type="email" 
                    placeholder="Email"
                    onChange={(e) => {
                        setState({ ...state, email: e.target.value });
                        setErrors({ ...errors, email: null });
                    }}
                    value={state.email}
                    isInvalid={ !!errors.email}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
            <div className="form-buttons">
                <Button variant="secondary" onClick={() => props.handleCloseModal()} >Cancel</Button>
                <Button variant="primary" type='submit'>Reset</Button>
            </div>
            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    );
};

export default ResetPasswordForm;
