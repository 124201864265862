import React from 'react';
import './TrendingProjectsFeed.scss';
import { ProjectFeed, SectionHeading } from '..';

const TrendingProjectsFeed = ({isHidden, scrollType}) => {
    return (
        <div hidden={!isHidden}>
            <SectionHeading 
                header="Explore other startups." 
                subHeading="Startups of all stages are welcome here."
            />
            <ProjectFeed quantity={6} filters={{promoted: true, visibility_types: 'public'}} scrollType={scrollType}/>
        </div>
    );
}

export default TrendingProjectsFeed;
