import React from 'react';
import { createContext, useState, useRef, useEffect } from 'react';
import { ToastContainer } from "react-toastify";
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ToastCustom.css"

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [refreshKey, setRefreshKey] = useState(0);
	const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
	const [userMetadata, setUserMetadata] = useState(JSON.parse(localStorage.getItem('userMetadata')));
	const authTokenRef = useRef(authToken);

	useEffect(() => {
		authTokenRef.current = authToken;
	}, [authToken]);

	// This will refresh the AuthContext key and force all children to re-render
	const forceRefresh = () => {
		setUserMetadata(JSON.parse(localStorage.getItem('userMetadata')));
		setRefreshKey(prevKey => prevKey + 1);
	};

	const login = (token, metadata) => {
		setAuthToken(token);
		setUserMetadata(metadata);
		localStorage.setItem('authToken', token);
		localStorage.setItem('userMetadata', JSON.stringify(metadata));
		try {
			const decodedToken = jwtDecode(token);
			// Calculate the remaining time before the token expires in milliseconds
			const expiresIn = decodedToken.exp * 1000 - Date.now(); 

			if (expiresIn > 0) {
				setTimeout(() => {
				// If the user is logged in
				if (authTokenRef.current) {
					logout();
					toast.warning('Your session has expired. Please log in again.'); // Show a warning notification
					if (process.env.REACT_APP_ENVIRONMENT === "dev") {
						console.log('Your session has expired. Please log in again.');
					}
				}
				}, expiresIn);
			} else {
				// The token has already expired, log the user out immediately
				logout();
			}
		} catch (error) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error decoding the JWT token:", error); }
		}
	};

	const logout = () => {
		// Checks if there is still a refernce to the most recent authToken
		if (authTokenRef.current) {
			setAuthToken(null);
			setUserMetadata(null);
			localStorage.removeItem('authToken');
			localStorage.removeItem('userMetadata');
			toast.warning('Your session has ended.'); // Show a warning notification
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Logged out of user session.") }
		}
	};

	return (
		<AuthContext.Provider value={{ authToken, login, logout, forceRefresh, userMetadata }}>
		<ToastContainer />
		{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
