import React, { useState } from 'react';
import './ExpandableCard.scss';
import Icon from 'icons/icon';

const ExpandableCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div onClick={handleToggle} className={`large-expandable-item card-style ${isExpanded ? 'expanded' : ''}`}>
      <div className='large-expandable-header'>
        <Icon color="#5D9898" size={30} icon={props.icon}/>
        {props.title}
        <span className={`expandable-arrow ${isExpanded ? 'up' : 'down'}`}></span>
      </div>
      <div className='large-expandable-desc'>
        {props.description}
      </div>
    </div>
  );
};

export default ExpandableCard;
