import { React, useEffect, useState } from 'react';
import { PageHeader } from 'components';
import { FormModal } from 'sub-components';
import { Layout } from 'layout';
import { getParamValURL } from 'lib/utils';
import { activateUser } from 'lib/user';

const AcctActivationPage = () => {

    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isAlertMsgToggleOn, setAlertMsgToggleOn] = useState(false);
    const [alertMsg, setAlertMsg] = useState({ title: '', msg: ''});
    const [userFname, setUserFName] = useState('');

    const acctActCompleted = () => {
        setAlertMsgToggleOn(!isAlertMsgToggleOn);
        setActionCompleted(true);
	}

    const msgClick = () => {
		setActionCompleted(!isAlertMsgToggleOn);
        setAlertMsgToggleOn(false);
	};

    /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
    `useEffect` hook is used to check if the URL or href contains the string "password-reset". If it
    does, it retrieves the password reset token, token ID, and user's first name from the URL
    parameters using the `getParamValURL` function. If the token and token ID exist, call the backend service 
    to verify the token first. If valid, pop up the actual change password form. */
    useEffect(() => {
		// Check if the URL or href contains "password-reset"
		if (window.location.href.includes("token")) {
			let token = getParamValURL("token", window.location.href);
            let tokenId = getParamValURL("id", window.location.href);
            let fName = getParamValURL("name", window.location.href);

            if (fName !== null && fName.length > 0) { fName = fName[0]; setUserFName(fName); }
			if (token !== null && token.length > 0) { token = token[0]; }
			if (tokenId !== null && tokenId.length > 0) { tokenId = tokenId[0]; }

            if (token && tokenId) {
                activateAcct(fName, tokenId, token);
            } else {
                alertMsg.title = "Missing Data";
                alertMsg.msg = `Hello ${fName}! Some required data is missing in the reset password link.`;
                setAlertMsgToggleOn(true);
            }
		}
	}, []); // Empty dependency array means this effect runs once after the initial render

    async function activateAcct(fName, tokenId, token) {
        const { isSuccess: actUserSuccess, resp: actUserResp, err: actUserErr } = await activateUser(tokenId, token);

        if (actUserSuccess) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Acct activation | Success:", actUserResp ); }
            alertMsg.title = "Success!";
            alertMsg.msg = `Hello ${fName}! \n THANK YOU! Account has been verified and activated. You are good to go and can now log in!`;
        } else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Acct activation | Error:", actUserResp, actUserErr); }
            if (actUserResp) {
                if (actUserResp.status_code === "INVALID_REQUEST" || actUserResp.status_code === "NOT_FOUND") {
                    alertMsg.title = "Invalid or Expired Request";
                    alertMsg.msg = `Hello ${fName}! \n The account activation request is no longer valid or has expired. \n Please reach out to our tech support.`;
                } else if (actUserResp.status_code === "INVALID_INPUT") {
                    alertMsg.title = "Invalid Request";
                    alertMsg.msg = `Hello ${fName}! \n The request is invalid. Please try again.`;
                } else {
                    alertMsg.title = "Unexpected Error";
                    alertMsg.msg = `Hello ${fName}! \n An unexpected error has occurred. Please check back shortly.`;
                }
            } else {
                alertMsg.title = "Unexpected Critical Error";
                alertMsg.msg = `Hello ${fName}! An unexpected error has occurred. Please check back shortly.`;
            }
        }
        acctActCompleted();
    }

    return (
        <Layout tabHeader="AcctActivation">
            <div className='colored-background'>
                <PageHeader header={"Account Activation"} subHeader={"Please activate your account."} />
            </div>
            <FormModal
                isToggleOn={isAlertMsgToggleOn}
                handleClick={msgClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={alertMsg.title}
                successfulActionTitle={alertMsg.title}
                successfulActionMessage={
                    <div>
                        {alertMsg.msg}
                    </div>
                }
                >
            </FormModal>
        </Layout>
    );
};

export default AcctActivationPage;
