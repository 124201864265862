import { React } from 'react';
import { PageHeader, SectionHeading, ExpandableCard } from 'components';
import { Layout } from 'layout';

const Resources = () => {

  return (
    <Layout tabHeader="Resources">
        <div className='colored-background'>
            <PageHeader header={"Things to know."} subHeader={"Especially as an early stage entrepreneur."} />
        </div>
        <SectionHeading header="Picking a category" sectionName="categories" subHeading="Which industry best matches your mission statement?"/>
        <div className='large-expandable-list'>
          <ExpandableCard title="Tech" icon="display" description="The tech industry revolves around creating, maintaining, and advancing software, hardware, and various digital platforms and tools. If your startup is centered on innovating or enhancing digital experiences, computational solutions, or advancing modern technology's capabilities, you're venturing into the tech industry."/>
          <ExpandableCard title="Consumer" icon="cart" description="The consumer industry focuses on producing and marketing products or services directly to end-users. If your startup is geared towards satisfying individual consumer needs, desires, or enhancing their daily lives – be it through physical products, e-commerce, or direct services – you're aligned with the consumer industry."/>
          <ExpandableCard title="Healthcare" icon="aid-kit" description="The healthcare industry encompasses the provision and advancement of medical services, equipment, and pharmaceuticals. If your startup aims to improve health outcomes, advance medical technologies, or enhance patient care and experiences, the healthcare sector is where you belong."/>
          <ExpandableCard title="Environment" icon="leaf" description="The environmental sector deals with solutions, practices, and technologies that address ecological challenges. If your mission is to combat climate change, promote sustainable practices, conserve nature, or create green technologies, your startup aligns with the environmental industry."/>
          <ExpandableCard title="Media" icon="mobile2" description="The media industry involves creating, curating, and disseminating content across various platforms like television, radio, print, and digital channels. If your startup's core is storytelling, journalism, entertainment, content creation, or managing and distributing information to mass audiences, you're navigating the media landscape."/>
        </div>
        <SectionHeading header="Identifying your startup status" sectionName="stages" subHeading="Which stage of progress is your startup at?"/>
        <div className='large-expandable-list'>
        <ExpandableCard title="Idea Stage" icon="dice" description="At this foundational phase, entrepreneurs have identified a problem they're passionate about solving. It's all about brainstorming, conceptualizing solutions, and drafting initial business models. This stage is characterized by a lot of ideation with no functional product or active user engagement yet."/>
          <ExpandableCard title="Validation Stage" icon="lab" description="Here, the focus is on testing the idea's feasibility. Entrepreneurs might develop a minimum viable product (MVP) to test the market's response. Feedback is gathered, and the concept is refined based on real-world insights. It's about confirming there's a genuine demand for the solution you're proposing."/>
          <ExpandableCard title="Early Stage" icon="equalizer2" description="Now, with a validated idea, startups enter a phase of further product development and initial market entry. They might secure their first customers, iterate on their product based on wider feedback, and may even seek seed financing. It's about laying the groundwork for growth, often with a small but growing team."/>
          <ExpandableCard title="Scaling Up" icon="rocket" description="Having established product-market fit and a stable business model, the focus shifts to expanding the customer base, entering new markets, and optimizing operations. Startups might pursue larger funding rounds, such as Series A or B, to fuel this growth. This stage involves building out departments, increasing marketing efforts, and refining the business model for broader scalability."/>
          <ExpandableCard title="Mature" icon="cogs" description="At this phase, the company has achieved consistent and predictable growth. There's an established presence in the market, a loyal customer base, and a stable revenue stream. The company may also explore diversifying its offerings, acquiring smaller startups, or even preparing for an IPO or exit strategy."/>
        </div>
    </Layout>
  );
};

export default Resources;
