import React from 'react';
import { PageHeader } from 'components';
import { Layout } from 'layout';

const NotFoundPage = () => {
  return (
    <Layout tabHeader="Page Not Found">
      <div className='colored-background'>
        <PageHeader displayGoHomeBtn={'inline'} displayLearnMoreBtn={'inline'} header={"404: Not Found"} subHeader={"The collaboration you are looking for is not here."} />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
