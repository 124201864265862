import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout } from 'layout';
import { Button, Alert } from 'react-bootstrap';
import blankprofileImage from 'images/blank-profile-picture.png'
import cameraIcon from 'images/icon-camera.png';
import './UserProfile.scss'
import AuthContext from 'app/AuthContext';
import { SmallProjectCard } from 'components';
import { FormModal, ProjectCardForm, CreateAccountForm, SignInForm } from 'sub-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { SyncLoader } from 'react-spinners';
import { updateUserMetadata, getUserProfile, getUserProjects, processUserProfileData, getTitleAndGoal, calculateProfileCompletion, changeUserProfileAvatar } from 'lib/user';
import { getEnvConfig, formatAvatarUrl, getArticle, resizeImage, convertEpochToDate} from 'lib/utils';
import { getUserContents, getContentsByCategory } from 'lib/content';
import { sendEventToAnalytics } from 'lib/reporting';

const UserProfile = () => {	
  	// This is from the param in the URL
  	const { userId } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
  
  	const [ profile, setProfile ] = useState({});
	const [profileCompPercent, setCompletionPercentage] = useState(0);
	const [ accomplishments, setAccomplishments ] = useState([]);
	const [profileAvatar, setProfileAvatar] = useState(profile.user_avatar ? formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts) : blankprofileImage);
	const [projects, setProjects] = useState([]);
	const [timeoutId, setTimeoutId] = useState(null);
	const [isEditProjectToggleOn, setEditProjectToggleOn] = useState(false);
	const [projectToEdit, setProjectToEdit] = useState(null);
	const [actionCompleted, setActionCompleted] = useState(false);
	const { authToken, userMetadata, logout, forceRefresh} = useContext(AuthContext);
	const loggedInUserId = userMetadata ? userMetadata.id : "";
	const [ displayedError, setDisplayedError ] = useState('');

	// const location = useLocation();
	const [isProfileView, setIsProfileView] = useState(false); // Initialize with default value

	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
	const [failedLoading, setFailedLoading] = useState(false);
	
	// Tabs to switch between background & impressive accomplishments
	const [activeTab, setActiveTab] = useState('background');

	// Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

	const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

	const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
			setEditProjectToggleOn(false);
        }
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Toggle tab state
    };

	// Edit button click handler for the user's profile
	const handleEditClick = () => {
		if (authToken) { 
			navigate('/profile/' + userId + '/edit');
		} else {
			handleSignInClick();
		}
	}

	const handleEditProjectClick = (index) => {
		setEditProjectToggleOn(!isEditProjectToggleOn);
		setProjectToEdit(projects[index]);
		if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Startup to edit: ", projects[index]); }
		setActionCompleted(false);
	}

	// Function to handle the completion of an action (e.g., editing a project)
	const handleActionCompleted = () => {
		setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
		const id = setTimeout(() => {
			setEditProjectToggleOn(false);
		}, 1000);
		setTimeoutId(id);
	};

	/* The above code is a JavaScript function called `loadUserContents` that is using the `useCallback`
	hook to asynchronously load a user's contents based on their `userId`. */
	const loadUserContents = useCallback(async (userId) => {
		// Get the user's projects
		const { isSuccess: getContentsSuccess, resp: getContentsResp, err: getContentsErr } = await getUserContents(userId, [{ "key": "category", "value": "accompl" }], authToken);

		if (getContentsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Content Search || Success", getContentsResp); }
			if (getContentsResp.results != null && getContentsResp.results) {
				// Update the state with the user's projects
				setAccomplishments(getContentsByCategory(getContentsResp.results, 'accompl'));
			} else {
				setAccomplishments([]);
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("User Content Search || Error", getContentsResp, getContentsErr); }
			if (getContentsResp) {
				// If unauthorized, log out the user's session
				if (getContentsResp.status_code === "UNAUTHORIZED") { logout(); }
			}
			// TODO: Handle error cases
		}
	}, []);

	/**
	 * The function `loadUserProfile` is an asynchronous function that loads a user's profile and updates
	 * the state with the profile data.
	 * @param userId - The `userId` parameter is the unique identifier of the user whose profile we want
	 * to load.
	 */
	const loadUserProfile = useCallback(async (userId, isProfileView) => {
		setLoading(true);

		// Check if the user is viewing their own profile
		if (loggedInUserId === userId) { setIsProfileView(true); }

		// Get the user's profile
		const { isSuccess: getProfileSuccess, resp: getProfileResp, err: getProfileErr } = 
			await getUserProfile( userId, [{ "key": "details", "value": "true" }, { "key": "tags", "value": "true" }], authToken );

		if (getProfileSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Search || Success", getProfileResp); }
			// Process/format the user profile data
			getProfileResp.results = processUserProfileData(getProfileResp.results);
			// Update the state with the profile data
			setProfile(getProfileResp.results);
			// If the user is viewing their own profile, update the user metadata
			if (isProfileView) { 
				updateUserMetadata(getProfileResp.results.user_avatar, 'user_avatar');
				forceRefresh();
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Profile Search || Error", getProfileResp, getProfileErr); }
			if (getProfileResp) {
				// If unauthorized, log out the user's session
				if (getProfileResp.status_code === "UNAUTHORIZED") { 
					logout(); 
				} else if (getProfileResp.status_code === "NOT_FOUND") {
					navigate('/not-found');
				} else {
					setDisplayedError("There was an error retrieving your profile. Please try again later.");
				}
			} else {
				setDisplayedError("There was a fatal error retrieving your profile. Please try again later.");
			}
		}

    	setLoading(false);
	}, []);

	/**
	 * The function `loadUserProjects` is an asynchronous function that loads the projects of a user and
	 * handles success and error cases.
	 * @param userId - The `userId` parameter is the unique identifier of the user whose projects you want
	 * to load.
	 */
	const loadUserProjects = useCallback(async (userId, isProfileView) => {

		if (loggedInUserId === userId) { setIsProfileView(true); }

		// Set the visibility types for the user's projects
		let visibilityTypes = [{'key': 'visibility_types', 'value': 'public'}];
		if (isProfileView) {
			// If the user is viewing their own profile, show all projects
			visibilityTypes = [{'key': 'visibility_types', 'value': 'public,private'}];
		}
		// Get the user's projects
		const { isSuccess: getProjectsSuccess, resp: getProjectsResp, err: getProjectsErr } = await getUserProjects(userId, visibilityTypes, authToken);

		if (getProjectsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Project Search || Success", getProjectsResp); }
			if (getProjectsResp.results != null && getProjectsResp.results) {
				// Update the state with the user's projects
				setProjects(getProjectsResp.results);
			} else {
				setProjects([]);
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("User Project Search || Error", getProjectsResp, getProjectsErr); }
			if (getProjectsResp) {
				// If unauthorized, log out the user's session
				if (getProjectsResp.status_code === "UNAUTHORIZED") { logout(); }
			}
			// TODO: Handle error cases
		}
	}, []);

	/**
     * The function `uploadFile` is an asynchronous function that handles the uploading of a file,
     * specifically an avatar image, and updates the user's profile with the new avatar image.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * file upload. It is typically an event object that is passed to an event handler function when a
     * file is selected for upload. The `event.target` property refers to the element that triggered
     * the event, and `event.target.files
     */
    const uploadFile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_change_avatar"); }
            setLoading(true);

            // Resize image, expect File object in return, not an actual file/image
            const resizedFileData = await resizeImage(file);
            
            let avatarId = null;
            // Add avatar ID
            if (typeof profile.user_avatar != 'undefined' 
                && profile.user_avatar
                && profile.user_avatar.id) {
                    avatarId = profile.user_avatar.id;
            }

            // Upload image
            const { isSuccess: changeAvatarSuccess, resp: changeAvatarResp, err: changeAvatarErr } = 
                await changeUserProfileAvatar(userId, avatarId, resizedFileData, authToken);

            if (changeAvatarSuccess) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Image Upload || Success", changeAvatarResp); }
                
                setProfile(prevProfile => ({
                    ...prevProfile,
                    "user_avatar": changeAvatarResp.results.user_avatar
                }));

                // Update the profile image in the nav bar
                updateUserMetadata(changeAvatarResp.results.user_avatar, 'user_avatar');
                forceRefresh();
                setLoading(false);
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error uploading profile image:", changeAvatarErr); }
                if (changeAvatarResp) {
                    // If unauthorized, log out the user's session
                    if (changeAvatarResp.status_code === "UNAUTHORIZED") { logout(); }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Empty response:", changeAvatarErr); }
                }
                setLoading(false);
                setFailedLoading(true);
            }
        } else {
            setFailedLoading(true);
        }
    };

	// Handle the click event for the portfolio links
	const handlePortfolioClick = (url) => {
		window.open(url, '_blank');
	}
	
	useEffect(() => {
		setProjects([]);
		loadUserProjects(userId, isProfileView);
	}, [userId, isProfileView, loadUserProjects]);

	useEffect(() => {
		setAccomplishments([]);
		loadUserContents(userId);
	}, [userId, loadUserContents]);
	
	useEffect(() => {
		if (loggedInUserId === userId) { setIsProfileView(true); }
		loadUserProfile(userId, isProfileView);
	}, [userId, isProfileView, loadUserProfile]);

	useEffect(() => {
		setCompletionPercentage(calculateProfileCompletion(profile));
	}, [profile]);

	useEffect(() => {
		if (profile.user_avatar?.url) {
			setProfileAvatar(formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts));
		}
	}, [profile.user_avatar]);

	return (
		<Layout tabHeader="Profile">
			<FormModal
				backdrop='static'
				isToggleOn={isSignUpToggleOn}
				handleClick={handleSignUpClick}
				onClose={checkIfLoggedIn}
				actionCompleted={actionCompleted}
				title={messaging.sign_up.ui.title}
				successfulActionTitle={messaging.sign_up.ui.success.title}
				successfulActionMessage={messaging.sign_up.ui.success.msg}>
				<CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
			</FormModal>
			<FormModal
				backdrop='static'
				isToggleOn={isSignInToggleOn}
				handleClick={handleSignInClick}
				onClose={checkIfLoggedIn}
				actionCompleted={actionCompleted}
				title={messaging.sign_in.ui.title}>
				<SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
			</FormModal>
			<FormModal
				isToggleOn={isEditProjectToggleOn}
				handleClick={handleEditProjectClick}
				actionCompleted={actionCompleted}
				onClose={() => clearTimeout(timeoutId)}
				title="Edit Post"
				successfulActionTitle="Congratulations!"
				successfulActionMessage="Your startup has been successfully updated."
				modalType="project-card-modal">
				{authToken && 
					<ProjectCardForm
					handleActionCompleted={handleActionCompleted}
					projectData={projectToEdit}
					editPostMode={true}
					userMetadata={userMetadata}
					profileImage={profileAvatar}
					loadUserProjects={loadUserProjects}
					/>
				}
			</FormModal>
			<div className='user-profile-page-container'>
				<div className='profile-container'>
					<div className='profile-pic-section'>
						<div className='profile-pic-info'>
							<div className='profile-pic-subsection'>
								{/* <div className="large-profile-circle" style={{backgroundImage: `url(${profileAvatar})`}}>
									{loading && (
										<div className="loader-overlay">
											<SyncLoader color={"#5D9898"} loading={loading} size={25} />
										</div>
									)}
								</div> */}
								<div className="large-profile-circle" style={{backgroundImage: `url(${profileAvatar})`}}>
									{ authToken && userMetadata && loggedInUserId === userId && (
										<div className="profile-pic-subsection-button">
											<div className="profile-picture-wrapper">
												<input
													type="file"
													accept="image/*"
													onChange={uploadFile}
													style={{ display: 'none' }}
													id="fileUploader"
												/>
												<label htmlFor="fileUploader" className="upload-button">
													{loading ? 'Uploading...' : (failedLoading ? 'Failed, try again.' : '')}
												</label>
											</div>
											<button className="camera-icon-button" onClick={() => document.getElementById('fileUploader').click()}>
												<img src={cameraIcon} alt="Camera Icon" />
											</button>
										</div>
									)}
								</div>
								<h2>{(profile.display_name ? profile.display_name : '')}</h2>
								<div className='profile-pic-subsection'>
									<div className='profile-objective'>
										{profile.tags && profile.tags.length > 0 ? (
											(() => {
												const { title: getTitle, goal: getGoal } = getTitleAndGoal(profile.tags);
												return `I am ${getTitle ? getArticle(getTitle) : 'a'} ${getTitle ? getTitle : 'person'}, and I want to ${getGoal ? getGoal : 'decide on a goal for being here'}.`;
											})()
											) : (
											'I am a person, and I want to decide on a goal for being here.'
										)}
									</div>
									{profile.location && (
										<div className='profile-location'>{profile.location ? profile.location : ''}</div>
									)}
									{profile.age && (
										<div className='profile-age'>{profile.age ? `Age: ${profile.age}` : ''}</div>
									)}
									<div className='profile_member_ts'>{profile.member_since_ts ? `Member Since: ${convertEpochToDate(profile.member_since_ts, "short_verbose_date")}` : ''}</div>
								</div>
							</div>
						<div>
					</div>
					</div>
					</div>
					<div className='profile-pic-subsection-button-options'>
						{ authToken && userMetadata ?
							(
								// If the user is logged in, show the edit button if the profile is incomplete
								loggedInUserId === userId ?
								(
										profileCompPercent < 100 ? 
									(
										<div className='profile-pic-subsection-button'>
											<Button onClick={handleEditClick} className='profile-button'>
												Complete Profile
												<div className='progress-bar-container'>
													<div
														className='progress-bar'
														style={{ width: `${profileCompPercent}%` }}
													/>
												</div>
											</Button>
										</div>
									)
									: 
									(
										// If the user is logged in, show the edit button because profile is complete
										<div className='profile-pic-subsection-button'>
											<Button onClick={handleEditClick} className='profile-button'>
												Edit Profile
											</Button>
										</div>
									)
								)
								: 
								(
									// Show nothing
									<div></div>
								)
							)
							:
							(
								// Show the incomplete profile label if the profile is incomplete
								// !profile.title && !profile.location && !profile.age && (
								<div className='profile-pic-subsection-button'>
									<Button className='profile-button'>
											Not Logged In
									</Button>
								</div>
								//)
							)
						}
					</div>
					<Alert variant="danger" className="animated-fast fadeInDownMenu">
						<Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
							{displayedError}
						</Alert.Heading>
					</Alert>
					<div className='profile-details'>
						<div className='profile-intro profile-description-card'>
							<h3>Introduction</h3>
							<div className="divider"></div>
							{profile.intro_summary ? (
								<ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.intro_summary} />
							): (
								<div>(Not specified)</div>
							)}
						</div>
						<div className='portfolio-section'>
							<h3>Portfolios</h3>
							<div className='portfolio-links-container'>
								<div className='portfolio-links-inner-container'>
									{profile?.portfolios?.length > 0 ? (
										profile.portfolios.map((portfolio, index) => (
											<div className='portfolio-link' key={index} onClick={() => handlePortfolioClick(portfolio?.url)} style={{backgroundImage: `url(/portfolio-icons/${portfolio?.icon}.png)`}}></div>
										))
									) : (
										<div>(Not specified)</div>
									)}
								</div>
							</div>
						</div>
						<div className="tabs">
                            <div
                                className={`tab ${activeTab === 'background' ? 'active' : ''}`}
                                onClick={() => handleTabClick('background')}>
                                Background
                            </div>
                            <div className="circle-separator" />
                            <div
                                className={`tab ${activeTab === 'accomplishments' ? 'active' : ''}`}
                                onClick={() => accomplishments?.length && handleTabClick('accomplishments')}>
                                Impressive Accomplishments
								<div className={`number-count ${activeTab === 'accomplishments' ? 'active' : ''}`}>
									{accomplishments?.length ? accomplishments?.length : 0}	
								</div>
                            </div>
                        </div>
						{activeTab === 'background' && (
							<div className='background-section'>
								<div className='profile-skillset profile-description-card'>
									<h3>Experience</h3>
									<div className="divider"/>
									{profile.experience ? (
										<ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.experience} />
									): (
										<div>(Not specified)</div>
									)}
								</div>
								<div className='profile-education profile-description-card'>
								<h3>Education</h3>
								<div className="divider"/>
									{profile.education ? (
										<ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.education} />
									): (
										<div>(Not specified)</div>
									)}
								</div>
							</div>
						)}
						{activeTab === 'accomplishments' && (
                            <div className='gray-vertical-container'>
								<div className='personal-projects-container'>
								<div className='project-cards-feed horizontal-scrolling'>
                                {accomplishments?.map((accomplishment) => (
                                    <div key={accomplishment.id} className="profile-skillset profile-description-card">
										<h3>{accomplishment.title}</h3>
										<div className="divider"></div>
										{accomplishment.body && (
											<ReactMarkdown rehypePlugins={[rehypeRaw]} children={accomplishment.body} />
										)}
                                    </div>
                                ))}
								</div>
								</div>
                            </div>
                        )}	
					</div>
				</div>
				<div className='profile-second-half'>
					<div className='profile-second-half-container'>
						<div className='profile-description-card'>
							<h3>Skills & Expertises</h3>
							<div className="divider"></div>
							<div className='skills-expertise-subsection'>
								<div className='skills-container'>
									{profile.tags && profile.tags.length > 0 ? (
										(() => {
										const skillTags = profile.tags
											.filter(tag => tag.type === 'skill')
											.flatMap(tag => tag.categories.flatMap(category => category.tags.map(skill => skill.display_name)));

										return skillTags.length > 0 ? (
											skillTags.map((skill, index) => (
											<div className='tag skill' key={index}>{skill}</div>
											))
										) : (
											<div className='tag skill'>(Skills not specified)</div>
										);
										})()
									) : (
										<div className='tag skill'>(Skills not specified)</div>
									)}
								</div>
								<div className='expertise-container'>
									{profile.tags && profile.tags.length > 0 ? (
										(() => {
										const expertiseTags = profile.tags
											.filter(tag => tag.type === 'expertise')
											.flatMap(tag => tag.categories.flatMap(category => category.tags.map(expertise => expertise.display_name)));

										return expertiseTags.length > 0 ? (
											expertiseTags.map((expertise, index) => (
											<div className='tag expertise' key={index}>{expertise}</div>
											))
										) : (
											<div className='tag expertise'>(Expertises not specified)</div>
										);
										})()
									) : (
										<div className='tag expertise'>(Expertises not specified)</div>
									)}
								</div>
							</div>
						</div>
						<div className='profile-description-card'>
							<h3>Interests</h3>
							<div className="divider"></div>
							<div className='interests-subsection'>
								<div className='interests-container'>
									{profile.tags && profile.tags.length > 0 ? (
										(() => {
										const interestTags = profile.tags
											.filter(tag => tag.type === 'interest')
											.flatMap(tag => tag.categories.map(category => ({
											category: category.category,
											tags: category.tags
											})));

										return interestTags.length > 0 ? (
											interestTags.map((category, index) => (
											<div key={index} className='category-group'>
												<h3>{category.category}</h3>
												{category.tags.map((tag, idx) => (
												<div className='tag interest' key={idx} style={{ marginBottom: '10px' }}>
													{tag.display_name}
												</div>
												))}
											</div>
											))
										) : (
											<div className='tag interest'>(Interests not specified)</div>
										);
										})()
									) : (
										<div className='tag interest'>(Interests not specified)</div>
									)}
								</div>
							</div>
						</div>

						<div className='personal-projects-container'>
							<h2 className='projects-section-title'>{profile.f_name}'s Startups:</h2>
							<div className='gray-vertical-container-small'>
								<div className='personal-projects'>
									<div className='project-cards-feed horizontal-scrolling'>
										{projects.length === 0 ? (
											// If the user has no projects, show no projects
											<div className='projects-not-found-message'>
												{profile.f_name} has not joined or started a startup yet.
											</div>
										) : (
											projects.map((project, index) => (
											<SmallProjectCard
												key={index}
												index={index}
												projectData={project}
												//Users should not be able to edit the projects of others
												editMode = {authToken && userMetadata && loggedInUserId === userId ? true: false}
												handleEditProjectClick = {handleEditProjectClick}
											/>
											))
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default UserProfile;
