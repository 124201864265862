import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import './index.scss'
import './icomoon.css'

const Layout = (props) => (
    <HelmetProvider>
        <div id="page">
            <Helmet>
                <meta charset="utf-8"/>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                <title>sidejawn | {props.tabHeader}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="author" content="" />
            </Helmet>
            {props.children}
        </div>
    </HelmetProvider>
);

export {Layout};