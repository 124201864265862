import { callAPI, getEnvConfig } from "./utils";

const CONFIG = getEnvConfig();
// Set global timeout value for making backend requests
const TIMEOUT = CONFIG.baker_api.request_timeout ? CONFIG.baker_api.request_timeout: 10000;

/**
 * This function makes an asynchronous API call to retrieve a user's contents based on the provided
 * user ID, options, and authentication token.
 * @param userId - The `userId` parameter in the `getUserContents` function represents the unique
 * identifier of the user whose contents you want to retrieve.
 * @param options - The `options` parameter is an object that contains key-value pairs used to build
 * the query string for the API call. It is expected to be an array of objects with keys `key` and
 * `value`, for example:
 * @param authToken - The `authToken` parameter in the `getUserContents` function is used to provide
 * the authorization token required to access the API endpoint. It is included in the request headers
 * as `'Authorization': 'Bearer <authToken>'`. This token is typically obtained after a user
 * successfully logs in or authenticates with
 * @param [reqTimeout] - The `reqTimeout` parameter in the `getUserContents` function is used to
 * specify the request timeout duration in milliseconds. If the API call takes longer than the
 * specified `reqTimeout` value to respond, the request will be considered timed out. The default value
 * for `reqTimeout` is `TIME
 * @returns The function `getUserContents` returns an object with three properties:
 * 1. `isSuccess`: A boolean value indicating whether the API call was successful or not.
 * 2. `resp`: The response data from the API call.
 * 3. `err`: Any error that occurred during the API call.
 */
export async function getUserContents(userId, options, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
    let queryString = '';

    // Build the query string of options
    if (options != null && Object.keys(options).length > 0) {
        // Expect an array key/value, example: [{'key': 'details', 'value': 'true}, {'key': 'tokens', 'value': 'true}]
        queryString = '?' + options.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
    }

    // Set the request options for the API call
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    };

    // Make the API call to get the user's contents
    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/contents${queryString}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `updateUserContents` updates user content based on metadata operations using
 * asynchronous API calls and returns the results.
 * @param userId - The `userId` parameter in the `updateUserContents` function represents the unique
 * identifier of the user whose contents are being updated.
 * @param data - The `data` parameter in the `updateUserContents` function is an array containing
 * content items to be updated. Each content item may have a `metadata` property that specifies the
 * operation to be performed (e.g., "delete", "update", "create"). The function loops through each
 * content item
 * @param authToken - The `authToken` parameter in the `updateUserContents` function is used to provide
 * the authentication token needed to authorize the API requests. This token is included in the request
 * headers as part of the Authorization field with the format `Bearer `. It helps the
 * server identify and authenticate the
 * @param [reqTimeout] - The `reqTimeout` parameter in the `updateUserContents` function represents the
 * timeout duration for the API request. It specifies the maximum amount of time the function will wait
 * for the API call to complete before timing out. If the API call takes longer than the specified
 * `reqTimeout`, the function will
 * @returns The `updateUserContents` function returns an object containing the results of the API calls
 * made to update, create, or delete content items based on the metadata operation provided in the
 * `data` array. The results include information such as the content ID, success status, response data,
 * and any errors encountered during the API calls.
 */
export async function updateUserContents(userId, data, authToken, reqTimeout=TIMEOUT) {
    const results = [];
    // Set the request headers
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };
    
    // Loop through the data array and update and/or each content item based on the metadata operation
    const updatePromises = data.map(async (content) => {
        let resp = null, err = null, isSuccess = false, response = null, contentId = null;
        try {
            // Update existing content based on the metadata operation only if it is present
            if (content.hasOwnProperty("metadata") && content.metadata.hasOwnProperty("op")) {
                // Prepare the request options without the method type, will set later based on the metadata operation
                const requestOptions = {
                    headers: headers,
                    body: JSON.stringify({ 'data': content })
                };

                // If the metadata operation is "delete", update the method and remove the body data
                if (content.metadata.op === "delete") { 
                    requestOptions.method = 'DELETE'; // Update the method to DELETE
                    // Remove "body" data from the request options for DELETE method as it is not needed
                    delete requestOptions.body;
                    // Make the API call to delete the content
                    response = 
                        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/contents/${content.id}`, requestOptions, reqTimeout);
                } else if (content.metadata.op === "update") {
                    requestOptions.method = 'PATCH';
                    // Make the API call to update the content
                    response = 
                        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/contents/${content.id}`, requestOptions, reqTimeout);
                } else if (content.metadata.op === "create") {
                    // If the metadata operation is not "delete", update the content using the PATCH method
                    requestOptions.method = 'PUT';
                    // Make the API call to add the new content
                    response = 
                        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/contents`, requestOptions, reqTimeout);
                }

                if (response.response && response.response.ok) { isSuccess = true; }
                resp = response?.data;
                err = response?.error;
                contentId = content?.id;
                // Store the results of the API call
                results.push({ contentId, isSuccess, resp, err });
            }
        } catch (error) {
            resp = response?.data;
            err = response?.error;
            contentId = content?.id;
            results.push({ contentId, isSuccess, resp, err });
        }
    });

    // Wait for all update promises to resolve
    await Promise.all(updatePromises);
    return { results };

}

/**
 * The function `getContentsByCategory` retrieves contents based on a specified category from a given
 * data array.
 * @param data - Data is an array of objects where each object represents a category and its contents.
 * Each object has a 'category' property that specifies the category and a 'contents' property that
 * contains the contents related to that category.
 * @param category - The `getContentsByCategory` function takes two parameters: `data` and `category`.
 * The `data` parameter is an array of objects, where each object represents a category and its
 * contents. The `category` parameter is a string that specifies the category for which you want to
 * retrieve the contents
 * @returns the contents of the first result that matches the specified category. If no matching
 * category is found, the function will return `null`.
 */
export function getContentsByCategory(data, category) {
    for (let result of data) {
        if (result.category === category) {
            return result.contents;
        }
    }
    return null; // Return null if no matching category is found
}

/**
 * This function filters contents based on a specified 'notOp' value in their metadata.
 * @param contents - The `contents` parameter is an array of items that you want to filter based on a
 * specific condition.
 * @param notOp - The `notOp` parameter is used to filter the contents based on a specific operation
 * that should not be included in the results. The function `filterContentsByNotOp` takes an array of
 * `contents` and filters out any content where the `op` property in the `metadata` object is
 * @returns An array of contents that do not have a metadata property or have a metadata property with
 * an "op" value different from the "notOp" parameter.
 */
export function filterContentsByNotOp(contents, notOp) {
    if (!contents) {
        return [];
    }
    return contents.filter(content => !content.metadata || content.metadata.op !== notOp);
}

/**
 * The function `updateMetadataOpById` updates the metadata of an accomplishment item in an array based
 * on its ID.
 * @param contents - The `contents` parameter is an array of objects representing accomplishments. Each
 * object in the array has properties such as `id` and `metadata`.
 * @param id - The `id` parameter in the `updateMetadataOpById` function is the identifier of the
 * metadata operation that you want to update in the `contents` array.
 * @param op - The `op` parameter in the `updateMetadataOpById` function represents the new value that
 * you want to update in the metadata of the accomplishment with the specified `id`. This value will be
 * assigned to the `op` property in the metadata object of the matching accomplishment.
 * @returns The `updateMetadataOpById` function takes in an array of `contents`, an `id`, and an `op`
 * object. It filters out any items in the `contents` array that have an empty or null `id`, and then
 * maps over the remaining items. If an item's `id` matches the provided `id`, it updates the
 * `metadata` property of that item with the
 */
export function updateMetadataOpById(contents, id, op) {
    return contents
        .filter(accomplishment => accomplishment.id) // Remove items with empty or null id
        .map(accomplishment => 
            accomplishment.id === id 
            ? { ...accomplishment, metadata: { ...accomplishment.metadata, op } } 
            : accomplishment
    );
}