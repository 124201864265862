import React, { useState, useEffect } from 'react';
import { PageHeader, TrendingProjectsFeed, SectionHeading, CallToAction, Communities, NavBar } from 'components';
import { Layout } from 'layout';
import pitchForm from 'images/pitch-form.png';
import pitchFormMobile from 'images/pitch-form-mobile.png';
import swipingVideo from 'images/swiping-hero-animation.mp4';
import videoNetwork from 'images/video-pitching-network.png';

const EarlyLandingPage = () => {
    const [isSignInToggleOnPrompt, setIsSignInToggleOnPrompt] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [isNow, setIsNow] = useState(true);

    useEffect(() => {
        const setLoginPrompt = () => {
            const params = new URLSearchParams(window.location.search);
            const promptLogin = params.get('prompt_login');
            
            if (promptLogin === 'true') {
                setIsSignInToggleOnPrompt(true);
                setRedirectURL(params.get('redirect'));
            }
        };

        setLoginPrompt();
    }, []);

    const handleToggle = () => {
        setIsNow(!isNow);
    };

    return (
        <Layout tabHeader="Home Page">
            <NavBar isSignInToggleOnPrompt={isSignInToggleOnPrompt} redirectURL={redirectURL} />
            <div className="main-page">
                <div className='colored-background header-section'>
                    <PageHeader 
                        displayDiscoveryBtn={'inline'} 
                        mainPhoto={swipingVideo} 
                        preHeader={"Have a business idea or startup?"} 
                        header={"Pitch here, find partners \n & build together."} 
                        subHeader={"Beta launching in Fall 2024."}
                    />
                    {/* <Banner/> */}
                </div>
                <Communities />
                <div className='pitch-simplification-wrapper'>
                    <SectionHeading 
                        header="Simplify your pitch."
                        subHeading="Organize your pitch so others can understand it."
                    />
                    <picture>
                        <source srcSet={pitchFormMobile} media="(max-width: 1024px)" />
                        <img src={pitchForm} alt="Pitch Form"/>
                    </picture>
                </div>
                <div className='now-vs-later-features'>
                    <div className='custom-switch no-select' onClick={handleToggle}>
                        <div className={`switch-half ${isNow ? 'active' : ''}`}>
                            Now
                        </div>
                        <div className={`switch-half ${!isNow ? 'active' : ''}`}>
                            Fall 2024
                        </div>
                        <div className={`toggle ${isNow ? 'toggle-left' : 'toggle-right'}`} />
                    </div>
                    <div className="gray-container">
                            <TrendingProjectsFeed scrollType="horizontal-scrolling" isHidden={isNow}/>
                        <div className='video-network-section' style={{ display: isNow ? 'none' : 'flex' }}>
                            <SectionHeading 
                                header="Discover potential partners."
                                subHeading={"Video pitches personalize your connections."}
                            />
                            <img src={videoNetwork} alt="Video Network" className="video-network" />
                        </div>
                    </div>
                </div>
                <div className='join-now-wrapper'>
                    <SectionHeading header="Join over 150+ entrepreneurs."/>
                    <div className='skill-tags-section'>
                        <div className='skill-tags'>
                            <div>
                                Sales & Marketing
                            </div>
                            <div>
                                Software Developers
                            </div>
                            <div>
                                UI/UX Designers
                            </div>
                            <div>
                                Product Specialists
                            </div>
                            <div>
                                Advisors
                            </div>
                            <div>
                                Mentors
                            </div>
                            <div>
                                Creatives
                            </div>
                            <div>
                                Operations Managers
                            </div>
                            <div>
                                University Students
                            </div>
                        </div>
                    </div>
                    <CallToAction 
                    actionButtonTitle="Join now" 
                    userActionButtonTitle="Give Us Feedback" 
                    imageDesc="sidejawn partners connecting through startup"
                    />
                </div>
            </div>
        </Layout>
    );
};

export default EarlyLandingPage;