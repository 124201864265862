import React, { Component, useState} from 'react'
import { Modal, Button, Form, Alert} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import '../signUpModal/SignUpModal.scss';
import jsonp from 'jsonp';


const CustomForm = (props) => {
    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [ errors, setErrors ] = useState({});

    // Form inputs
    const [state, setState] = useState({firstName: '', lastName: '', email: ''});

    // ReCAPTCHA
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const bakerURL = process.env.REACT_APP_ENVIRONMENT === "dev" ? "http://127.0.0.1:8000/" : process.env.REACT_APP_API_BASE_URL
    const refCaptcha = React.useRef();

    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    const findFormErrors = () => {
        const firstName = state.firstName;
        const lastName = state.lastName;
        const email = state.email;
        const newErrors = {}
        // Input errors
        if ( !firstName || firstName === '' ) newErrors.firstName = 'Cannot be blank!'
        else if ( firstName.length > 30 ) firstName.firstName = 'First name is too long!'
        if ( !lastName || lastName === '' ) newErrors.lastName = 'Cannot be blank!'
        else if ( lastName.length > 30 ) newErrors.lastName = 'Last name is too long!'
        if ( !email || email === '' ) newErrors.email = 'How dare this be blank?'
        else if ( email.length > 30 ) newErrors.email = 'Email is too long!'
    
        return newErrors
    }

    async function onFormSubmit(event) {
        setShowAlert(false);
        setAlertText("");
        event.preventDefault();
        const newErrors = findFormErrors();
        // Check for errors
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            // Execute reCaptcha
            await refCaptcha.current.callbacks.execute();
            if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                console.log("Google ReCAPTCHA || Processed site key.");
            }
            const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
            // Get the reCaptcha token and create a request to our backend to request for the suspicion score
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ response: reCaptchatoken })
            };
            await fetch(`${bakerURL}/captchaVerification`, requestOptions)
                .then(response => {
                    if(response.status >= 400 && response.status <= 500) {
                        throw new Error("Response came back with error status: " + response.status);
                    }
                    else {
                        return response.json()
                    }
                })
                .then(data => {
                    if(data.success === true) {
                        // The higher the score, the less suspicious the user is according to their IP and Google
                        if(data.score >= 0.5) {
                            if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                                console.log("Google ReCAPTCHA || Authenticated the user.");
                            }
                            createBetaUser();
                        }
                    }
                    else {
                        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                            console.log("Google ReCAPTCHA || Error: " + data.score);
                        }
                        displayError("Your ReCAPTCHA failed. Try again later.");
                    }
                })
                .catch(error => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log("Early Beta Sign Up || Error: ", error.message);
                    }
                    displayError("A general error occurred, please try again later.");
                });
            }
    }

    const subscribeToNewsletter = (email, firstName, lastName) => {
    return new Promise((resolve, reject) => {
        const mailchimpEndpoint =
        'https://sidejawn.us21.list-manage.com/subscribe/post-json?u=d5dfecbae107e2a588ee62a96&id=1e379997b6';
        const data = {
            EMAIL: email,
            FNAME: firstName,
            LNAME: lastName,
        };

        const queryString = new URLSearchParams({
            ...data,
            c: 'callback',
        });

        const url = `${mailchimpEndpoint}&${queryString.toString()}`;

        jsonp(url, { param: 'c' }, (err, data) => {
            if (err) {
                console.error('Error while subscribing:', err);
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

      

    async function createBetaUser() {
        // Check if the user is signing up for the newsletter
        if (document.getElementById('newletter-opt-in').checked) {
            try {
                const newsletterResponse = await subscribeToNewsletter(state.email, state.firstName, state.lastName);
                // Handle the response from the newsletter subscription
                if (newsletterResponse && newsletterResponse.result === 'success') {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log('User subscribed to the newsletter');
                    }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log('Error while subscribing to the newsletter');
                    }
                }
              } catch (error) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                    console.error('Error while subscribing:', error);
                }
              }
        }

        // Start the process for signing up the user
        let url = `${bakerURL}/user/earlyAccess`
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'data': {
                    'first_name' : state.firstName,
                    'last_name' : state.lastName,
                    'email' : state.email
                }
             })
        };
        fetch(url, requestOptions)
        .then(response => {
            if(response.status < 400 || response.status > 500) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                  } else {
                    return { 'data': { 'error': 'Unexpected error occurred' } };
                }
            }
            else {
                throw new Error("Response came back with error status: " + response.status);
            }
        })
        .then(data => {
            if(data != null) {
                if(data.error) {
                    throw new Error(data.error);
                } else if (data.status_code !== "OK") {
                    const status_code = data.status_code;
                    if (status_code === "ERR_DB_CONSTRAINT_FAIL") {
                        displayError("This email is already signed up. We appreciate you!");
                        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                            console.log("Early Beta Sign Up || Error: " + status_code);
                        }
                    }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log("Early Beta Sign Up || Success: ", data);
                    }
                    props.setSuccesfullyEnrolled();
                }
            }
        })
        .catch(error => {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                console.log("Early Beta Sign Up || Error: ", error.message);
            }
            displayError("An unexpected error occurred, please try again later.");
        });
    }

    return (
        <Form onSubmit={onFormSubmit}>
            <Form.Group controlId="formBasicFirstName">
                <Form.Control 
                    name="firstName"
                    placeholder="First Name" 
                    type="text" 
                    onChange={(e) => setState({...state, firstName: e.target.value})}
                    value={state.firstName}
                    isInvalid={ !!errors.firstName}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.firstName }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicLastName">
                <Form.Control
                    name="lastName"
                    placeholder="Last Name"
                    type="text" 
                    onChange={(e) => setState({...state, lastName: e.target.value})}
                    value={state.lastName}
                    isInvalid={ !!errors.lastName}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.lastName }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Control 
                    name="email"  
                    type="email" 
                    placeholder="Email"
                    onChange={(e) => setState({...state, email: e.target.value})}
                    value={state.email}
                    isInvalid={ !!errors.email}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Check 
                type='checkbox'
                id="newletter-opt-in"
                label="Send me email updates"
            />
            <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
            <div className="form-buttons">
                <Button variant="secondary" onClick={() => props.handleCloseModal()} >Cancel</Button>
                <Button variant="primary" type='submit'>Sign Up</Button>
            </div>
            <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>
            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    )
  }

export default class EarlySignUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successFullyEnrolled: false
        }
        this.setSuccesfullyEnrolled = this.setSuccesfullyEnrolled.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    setSuccesfullyEnrolled() {
        this.setState({successFullyEnrolled: true})
    }

    handleCloseModal() {
        this.setState({successFullyEnrolled: false})
        this.props.handleClick();
    }
    

    render() {
        return (
            <Modal className={this.state.successFullyEnrolled ? "success-animation" : ""} show={this.props.isToggleOn} onHide={this.handleCloseModal} animation={true} centered="true">
                <div hidden={this.state.successFullyEnrolled}>
                    <Modal.Header closeLabel=""closeButton>  
                        <Modal.Title>Early Sign Up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomForm hidden={this.state.successFullyEnrolled} handleClick={this.props.handleClick} handleCloseModal={this.handleCloseModal} isSuccess={this.state.successFullyEnrolled} setSuccesfullyEnrolled={this.setSuccesfullyEnrolled}/>
                    </Modal.Body>
                </div>
                <div hidden={!this.state.successFullyEnrolled}>
                    <Modal.Header>
                        <Modal.Title>Welcome to the early access community!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        As an early adopter, we will keep you updated with our mission!
                        <br/>
                        <br/>
                        We will have Phase 1 ready by June, but please reach out if you have any questions or feedback.
                    </Modal.Body>
                </div>
            </Modal>
        )
    }
}