
import { callAPI } from "./utils";

// Import only needed settings, in this case, the bake API setting object only
//envConfig.baker_api = require(`conf/${process.env.REACT_APP_ENVIRONMENT}.json`).baker_api;
const envConfig = require(`conf/${process.env.REACT_APP_ENVIRONMENT}.json`);
// Merge global/common setting to env specific settings into one config object
const CONFIG = { ...require(`conf/global.json`), ...envConfig };
// Set global timeout value for making backend requests
const TIMEOUT = CONFIG.baker_api.request_timeout ? CONFIG.baker_api.request_timeout: 10000;

/**
 * The function `getProjects` is an asynchronous function that makes a GET request to a specified URL
 * with optional query parameters and returns an object containing the success status, response data,
 * and any error that occurred.
 * @param options - An array of objects containing key-value pairs for the query parameters to be
 * included in the API request URL. Each object in the array should have a 'key' property representing
 * the query parameter key and a 'value' property representing the query parameter value.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function getProjects(options, reqTimeout=TIMEOUT) {
    let isSuccess = false;
    let queryString = '';

    // Build the query string of options
    if (options != null && options.length > 0) {
        queryString = '?' + options.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    };

    const { response: res, data: resp, err: error } = 
        await callAPI(`${CONFIG.baker_api.root_url}/projects${queryString}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, error };
}

/**
 * The function `getRoles` is an asynchronous function that makes a GET request to the Baker API to
 * retrieve roles based on the provided options, and returns an object with a success flag, response
 * data, and any error that occurred.
 * @param options - An array of key-value pairs used to build the query string for the API request.
 * Each item in the array should have a "key" and "value" property. For example: [{'key': 'q', 'value':
 * 'Advisor'}]
 * @param reqTimeout - The `reqTimeout` parameter is the timeout duration for the API request to the
 * Baker API. If it is not specified, it will default to the value specified in the
 * `BAKER_API_CONF.request_timeout` constant.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function getRoles(options, reqTimeout=TIMEOUT) {
    let isSuccess = false;
    let queryString = '';

    // Build the query string of options
    if (options != null && options.length > 0) {
        // Expect an array key/value, example: [{'key': 'query', 'value': 'Advisor'}]
        queryString = '?' + options.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    };

    const { response: res, data: resp, err: error } = 
        await callAPI(`${CONFIG.baker_api.root_url}/contributors${queryString}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, error };
}

/**
 * The function `getTagOptions` processes event tags and organizes them into a grouped list of options.
 * @returns An array of objects containing tag options grouped by label, where each object has a
 * `label` property and an `options` property which is an array of objects with `value` and `label`
 * properties. The `label` property is the tag label, and the `options` array contains options with
 * modified date format in the `label` property.
 */
export function getTagOpts() {
    // Initialize an empty array to store tagGroupedOptionsList
    const tagGroupedOptionsList = [];
    // Loop through the events
    for (const eventKey in CONFIG.events) {
        if (CONFIG.events.hasOwnProperty(eventKey)) {
            const eventArray = CONFIG.events[eventKey];
            eventArray.forEach(event => {
                // Iterate over tags of each event
                event.tags.forEach(tag => {
                    // Check if the label already exists in the tagGroupedOptionsList object
                    if (tagGroupedOptionsList[tag.label]) {
                        // If it exists, push the options to the existing label
                        tagGroupedOptionsList[tag.label].options.push(...tag.options.map(option => ({
                            value: option.value,
                            label: option.label.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2.$3.$1")
                        })));
                    } else {
                        // If it doesn't exist, create a new entry
                        tagGroupedOptionsList[tag.label] = {
                            label: tag.label,
                            options: tag.options.map(option => ({
                                value: option.value,
                                label: option.label.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2.$3.$1")
                            }))
                        };
                    }
                });
            });
        }
    }

    // Convert the tagGroupedOptions object to an array of objects
    return(Object.values(tagGroupedOptionsList));
}

/**
 * This function returns the project status options from the CONFIG object.
 * @returns The function `getProjStatusOpts` is returning the project status options from the `CONFIG`
 * object.
 */
export function getProjStatusOpts() { return CONFIG.project_status_opts; }

/**
 * The function `getProjCategoryOpts` sorts an array of project category options alphabetically by
 * their labels.
 * @returns The function `getProjCategoryOpts` is returning the `CONFIG.project_category_opts` array
 * sorted alphabetically by the `label` property in uppercase.
 */
export function getProjCategoryOpts() { 
    return CONFIG.project_category_opts.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) { return -1; }
        if (labelA > labelB) { return 1; }
        return 0;
    });
}

/**
 * The function `getProjContributorRoleOpts` returns a sorted list of project contributor role options
 * based on role names in uppercase.
 * @returns The function `getProjContributorRoleOpts` is returning an array of project contributor role
 * options sorted alphabetically by the `role_name` property in ascending order.
 */
export function getProjContributorRoleOpts() { 
    return CONFIG.def_contributor_role_opts.sort((a, b) => {
        const roleNameA = a.role_name.toUpperCase();
        const roleNameB = b.role_name.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}