import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './app/AuthContext';
import ReactGA4 from 'react-ga4';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Initialize Google Analytics
ReactGA4.initialize('G-7PJ6D65WCQ');

ReactDOM.render(
  <AuthProvider>
    <GoogleOAuthProvider clientId={googleClientId}>
      <App />
    </GoogleOAuthProvider>
  </AuthProvider>,
  document.getElementById('root')
);
