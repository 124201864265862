import React, { Component } from 'react'
import './Module.scss'
import Icon from 'icons/icon'

export default class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: this.props.icon,
            image: this.props.image,
            header : this.props.header,
            content : this.props.content
        };
    }

    render() {
        return (
            <div className={"text-center about-us-box three-modules card-style " + this.props.className} onClick={this.props.onClick}>
                <div className="services">
                    {this.state.icon? (<span className="icon">
                        <Icon color="#508484" size={65} icon={this.state.icon}/>
                    </span>
                    ):(
                        <img src={this.state.image} />
                    )}
                    <div className="desc">
                        <h3>{this.state.header}</h3>
                        <p>{this.state.content}</p>
                    </div>
                </div> 
            </div>
        )
    }
}