import React, {useState, useEffect, useContext, useRef} from 'react';
import { Button, Alert, Form } from 'react-bootstrap';

const Portfolio = () => {

  const [ profile, setProfile ] = useState({});

  const [portfolios, setPortfolios] = useState([]);      
  const [portfolioEditVisible, setPortfolioEditVisible] = useState(false);
  const [portfolioSelection, setPortfolioSelection] = useState([{id:'1', icon:'linkedin'}, {id:'2', icon:'avvo'}, {id:'3', icon:'behance'}, {id:'4', icon:'fiverr'}, {id:'5', icon:'instagram'}, {id:'6', icon:'medium'}, {id:'7', icon:'upwork'}, {id:'8', icon:'vimeo'}, {id:'9', icon:'zocdoc'}, {id:'10', icon:'generic'}, {id:'11', icon:'github'}]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const isSelectedPortfolioInPortfolios = selectedPortfolio && portfolios?.some(portfolio => portfolio.icon === selectedPortfolio.icon);
  const portfolioEditorRef = useRef(null);
  const PREFIX = "https://";
  const initialUrl = selectedPortfolio ? selectedPortfolio.url || PREFIX : PREFIX;
  const urlPattern = new RegExp(
      /^(https:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([/?].*)?$/
  );
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [url, setUrl] = useState(initialUrl);

  const [experience, setExperience] = useState(profile.experience || '');

  const [ errors, setErrors ] = useState({});


  // LinkedIn is always the first portfolio item
  const sortPortfolios = (portfolios) => {
      return portfolios?.sort((a, b) => {
        if (a.icon === 'linkedin') return -1;
        if (b.icon === 'linkedin') return 1;
        return 0;
      });
  };


  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
    setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.experience;
        return newErrors;
    });
  };


    /**
       * The `handlePortfolioClick` function toggles the visibility of the portfolio editor and updates
       * the selected portfolio with full details if a portfolio is provided.
       * @param portfolio - The `portfolio` parameter in the `handlePortfolioClick` function likely
       * represents a specific portfolio item that the user has clicked on or interacted with. This could
       * be an object containing details about the portfolio, such as its icon, name, description, or any
       * other relevant information.
       * @param edit - The `edit` parameter in the `handlePortfolioClick` function is a boolean value
       * that indicates whether the user is currently in edit mode for the portfolio.
       */
  const handlePortfolioClick = (portfolio, edit) => {
    // Toggle the visibility of the portfolio editor
    if(!edit)
        setPortfolioEditVisible(prevState => !prevState);
    // If a portfolio is provided, update the selectedPortfolio with the full details from the main list
    if (portfolio) {
        const fullPortfolio = portfolios?.find(p => p.icon === portfolio.icon) || portfolio;
        setSelectedPortfolio(fullPortfolio);
    } else {
        // Optionally handle the case where no portfolio is provided
        setSelectedPortfolio(null);
    }
  };

    /**
       * The function `handlePortfoliosUpdate` updates a list of portfolios with a selected portfolio,
       * sorting them and toggling the visibility of the portfolio editor if a valid URL is provided.
       */
  const handlePortfoliosUpdate = () => {
    // Validate the URL
    setIsValidUrl(urlPattern.test(url));
    // Update the selected portfolio with the URL and add it to the list of portfolios
    if ("icon" in selectedPortfolio && urlPattern.test(url)){
        setPortfolios(prevPortfolios => {
            // Ensure that prevPortfolios is initialized properly if it's undefined or null
            prevPortfolios = prevPortfolios || [];
    
            if (prevPortfolios.length > 0) {
                const index = prevPortfolios.findIndex(p => p.icon === selectedPortfolio.icon);
                let newPortfolios = [...prevPortfolios];
                if (index > -1) {
                    // Portfolio exists, update it
                    newPortfolios[index] = selectedPortfolio; // already includes the updated URL
                } else {
                    // Portfolio does not exist, add it
                    newPortfolios.push(selectedPortfolio);
                }
                return sortPortfolios(newPortfolios); // Sort the portfolios after update/add
            } else {
                // If there are no previous portfolios, start the list with the selectedPortfolio
                return sortPortfolios([selectedPortfolio]);
            }
        });
         // Toggle the visibility of the portfolio editor
        setPortfolioEditVisible(prevVisible => !prevVisible);
      }
    };

    // Used by the portfolio editor to update the URL of the selected portfolio
  const handleUrlChange = (event) => {
    let newUrl = event.target.value.trim();

    // Remove "http://" and "https://" (especilaly when pasting a URL with "https://")
    newUrl = newUrl.replace(/^https?:\/\//, '');

    if(newUrl === "https:/") return;
    // Ensure the URL starts with "https://"
    if (!newUrl.startsWith(PREFIX)) {
        newUrl = PREFIX + newUrl.replace(/^https?:\/\//, '');
    }

    // Prevent deletion beyond the prefix
    if (newUrl.length < PREFIX.length) {
        newUrl = PREFIX;
    }

    setSelectedPortfolio(currentPortfolio => ({
        ...currentPortfolio,
        url: newUrl
    }));
    setUrl(newUrl);
  };

  const handleUrlSelection = (e) => {
    // Prevent selecting the prefix
    const input = e.target;
    if (input.selectionStart < PREFIX.length) {
        input.setSelectionRange(PREFIX.length, PREFIX.length);
    }
  };
  
  const handleMouseUp = (e) => handleUrlSelection(e);
  
  // Used by the portfolio editor to delete a portfolio
  const handleDeletePortfolioClick = (event, selectedPortfolio) => {
    event.stopPropagation(); // Stop event from propagating to parent
    // Delete portfolio
    const updatedPortfolios = portfolios.filter((portfolio) => portfolio.id !== selectedPortfolio.id);
    setPortfolios(updatedPortfolios);
  };
  


  return (
    <div className='portfolio-container'>
          <h1>Show us your experience</h1>
          <div>Add links to your portfolios, and select one of the experience prompts.</div>
          <div className='portfolio-section'>
                <h3>Portfolios</h3>
                <div className='portfolio-vertical'>
                    <div className='portfolio-links-container'>
                        <div className='portfolio-links-inner-container'>
                        {portfolios?.length > 0 ? (
                            portfolios.map((portfolio, index) => (
                                <div className='portfolio-link' key={index} onClick={() => handlePortfolioClick(portfolio)} style={{backgroundImage: `url(/portfolio-icons/${portfolio?.icon}.png)`}}>
                                    <div className='delete-button' onClick={(e) => handleDeletePortfolioClick(e, portfolio)}/>
                                </div>
                            ))
                        ) : (
                            <div>No portfolios.</div>
                        )}
                        <div className='plus-icon portfolio-link' onClick={handlePortfolioClick}/>
                        </div>
                    </div>
                    <div className={`portfolio-editor ${portfolioEditVisible ? 'visible fadeInDownMenu animated-fast' : ''}`} ref={portfolioEditorRef}>
                        <div className='portfolio-editor-inner'>
                            Choose a portfolio type and enter a link.
                            <div className='portfolio-links-container'>
                                <div className='portfolio-links-inner-container'>
                                {portfolioSelection.map((portfolio, index) => (
                                    <div 
                                    className={`portfolio-link ${selectedPortfolio === portfolio ? 'selected' : ''}`}
                                    key={index}
                                    onClick={() => handlePortfolioClick({ ...portfolio, url: portfolio.url || "" }, true)}
                                    style={{ backgroundImage: `url(/portfolio-icons/${portfolio.icon}.png)` }}
                                    />
                                ))}
                                </div>
                            </div>
                            <div className='edit-portfolio'>
                                <div className='portfolio-link' style={{backgroundImage: `url(${selectedPortfolio ? `/portfolio-icons/${selectedPortfolio.icon}.png` : ""})`}}/>
                                <Form.Control 
                                    type="text" 
                                    value={selectedPortfolio ? selectedPortfolio.url || "" : ""}
                                    onChange={handleUrlChange} 
                                    placeholder="https://"
                                    className='portfolio-url-input'
                                    onMouseUp={handleMouseUp}
                                    onFocus={handleUrlSelection}
                                    disabled={!selectedPortfolio || "icon" in selectedPortfolio === false}
                                />
                                {
                                    isSelectedPortfolioInPortfolios ? 
                                    (
                                        <div className='update-portfolio-item' onClick={handlePortfoliosUpdate}>
                                        Update
                                        </div>
                                    ) : (
                                        <div className='plus-icon portfolio-link' onClick={handlePortfoliosUpdate}/>
                                    )
                                }
                            </div>
                            {!isValidUrl && (
                                <Alert variant="danger" className="url-error-message">
                                    Please enter a valid URL.
                                </Alert>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='profile-skillset profile-description-card'>
              <h3>Experience*</h3>
              <div className="divider"></div>
                  <div className="textAreaWrapper">
                      <textarea
                          name="experience"
                          rows="5"
                          cols="40"
                          maxLength="350"
                          value={experience}
                          placeholder={"a. List the specific skills you possess that make you well-suited to work on other people's ideas (e.g., web development, marketing, project management, etc.).\n" +
                          "b. Share any relevant professional experiences or past projects and/or startups that showcase your skills and capabilities. \n" +
                          "c. Mention any self-taught skills or knowledge you've gained through experimentation."}
                          onChange={handleExperienceChange}
                      ></textarea>
                      <span className='largeCharCounter'>{experience.length}/350</span>
                  </div>
          </div>
        </div>

  );

};

export default Portfolio;