import React from 'react';
import './SectionHeading.scss';

const SectionHeading = ({ sectionName, header, subHeading }) => {
    return (
        <div className="colorlib-intro" id={sectionName}>
            <div className="colorlib-heading">
                <h2>{header}</h2>
                {subHeading && subHeading !== '' && (
                    <h3 className='subheading'>{subHeading}</h3>
                )}
            </div>
        </div>
    );
};

export default SectionHeading;
