import React, { useEffect, useContext, useState } from 'react';
import { PageHeader, ProjectFeed } from 'components';
import { FormModal, CreateAccountForm, SignInForm } from 'sub-components';
import AuthContext from 'app/AuthContext';
import { getLatestEventDetails } from "lib/event";
import { getEnvConfig } from 'lib/utils';

const EventPage = () => {
    const { authToken } = useContext(AuthContext);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (!authToken) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("No auth token found. Please login."); }
            // handleSignUpClick();
            handleSignInClick();
        }
    }, [authToken]); // useEffect will run whenever authToken changes

    // Get the current URL and parse the query string
    const filters = { order_type: "asc" };
    // Arbitrary default unique tag value as a placeholder when no event is found
    const defUnknownTag = "unknown-tag-414d-948e-8ec51a95c38c";
    let defHeader = "";
    let defSubHeader = "";
    let tag = "";
    let defMainPhoto = "";

    const currentURL = new URL(window.location.href);
    const pathParts = currentURL.pathname.split('/');
    const eventIdName = pathParts[pathParts.length - 1];
    // Get the latest event details of the event ID which is based on the last part of the URL
    const latestEvent = getLatestEventDetails(eventIdName);
    
    // If there is no event found, set the tag to a default values
    if (latestEvent) {
        // Get the tag from the latest event
        tag = latestEvent.tags[0].options[0].value;
        if (!tag) { tag = defUnknownTag; }
        // Set the header and subheader to the latest event's title and sub_title
        defHeader = latestEvent.title || "";
        defSubHeader = latestEvent.sub_title;
        defMainPhoto = require('images/' + latestEvent.cover);
    } else {
        tag = defUnknownTag;
    }
    
    // Add 'tag' query parameter to the URL with the value of the tag
    filters.tag = tag;

    // Get the URL in the browser
    const searchParams = currentURL.searchParams;
    // Set 'tag' query parameter to the URL with the value of the tag
    searchParams.set("tag", tag);
    // Update the URL in the browser with the new query parameters
    window.history.replaceState({}, document.title, currentURL.toString());

    const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
    };

    const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
        }
    }

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <div>
            <FormModal
                backdrop='static'
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
			    <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <div className='colored-background'>
                <PageHeader headerType={'simple'} header={defHeader} subHeader={defSubHeader} mainPhoto={defMainPhoto}/>
            </div>
            <div style={{marginTop: "50px"}}>
                {authToken && <ProjectFeed quantity={6} filters={filters}/>}
            </div>
        </div>
    );
}

export default EventPage;
