import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './FormModal.scss';

const FormModal = (props) => {
  const handleModalClick = (event) => {
    // Prevents the click event from bubbling up to the parent elements
    event.stopPropagation();
  };

  const handleCloseModal = () => {
    props.handleClick();
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
      <Modal
        backdrop={props.backdrop || true}
        className={`${props.modalType} ${props.actionCompleted ? 'success-animation' : ''}`}
        show={props.isToggleOn}
        onHide={handleCloseModal}
        animation={true}
        centered={true}
        onClick={handleModalClick}
      >
        <div hidden={props.actionCompleted}>
          <Modal.Header closeLabel=""closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-custom">
            {React.Children.map(props.children, (child) =>
              React.cloneElement(child, {
                handleCloseModal: handleCloseModal,
              })
            )}
          </Modal.Body>
        </div>
        {/* Conditionally render this part only if successfulActionTitle and successfulActionMessage exist */}
        {props.successfulActionTitle && props.successfulActionMessage && (
          <div hidden={!props.actionCompleted}>
            <Modal.Header closeLabel=""closeButton>
            	<Modal.Title>{props.successfulActionTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="message-modal basic-message">
              	{props.successfulActionMessage}
              	<br />
              	<br />
              	<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button style={{ width: '100px' }} onClick={handleCloseModal}>
					OK
					</Button>
              </div>
            </Modal.Body>
          </div>
        )}
      	</Modal>
  );
};

export default FormModal;
