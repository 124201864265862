import React, { Component } from 'react'
import './AbstractFeatures.scss'
import Icon from 'icons/icon'
import {SectionHeading} from '..'

export default class AbstractFeatures extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isToggleOn : false
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
        this.setState({
            isToggleOn: !this.state.isToggleOn
          });
    }

    render() {
        return (
            <div className="colorlib-cta">
				<SectionHeading 
                    header="Stay tuned for what's next" 
                    subHeading="We want to accelerate your businesses by leveraging generative AI"
                />
				<div className="colorlib-services colorlib-bg-white">
					<div className="margined-container">
						<div className="row">
							<div className="text-center module without-border one-of-two-modules card-style">
								<div className="services">
									<span className="icon">
									<Icon color="#508484" size={65} icon="bubbles2"/>
									</span>
									<div className="desc">
										<h3>24/7 Mentorship</h3>
										<p>Rely on a digital mentor for advice as you build your business with your team.</p>
									</div>
								</div>
							</div>
							<div className="two-thirds-parent-module">
								<div className="text-center module separator-icon-module">
									<div className="services">
										<span className="icon">
											<Icon color="#508484" size={65} icon="rocket"/>
										</span>
										<div className="desc">
										<h3>Accelerator Program</h3>
										<p>Join a more accessible accelerator program that leverages generative AI.</p>
									</div>
									</div>
								</div>
								<div className="text-center module one-of-two-modules card-style">
									<div className="services">
										<span className="icon">
											<Icon color="#508484" size={65} icon="books"/>
										</span>
										<div className="desc">
											<h3>Tailored Resources</h3>
											<p>Find education, tools, connections, and eventually... financing.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}