// components/TheIntroduction.js
import React, {useState, useContext} from 'react';
import { useParams} from 'react-router-dom';
import {resizeImage, formatAvatarUrl } from 'lib/utils';
import { updateUserMetadata, changeUserProfileAvatar  } from 'lib/user'
import blankprofileImage from 'images/blank-profile-picture.png'
import AuthContext from 'app/AuthContext';
import { sendEventToAnalytics } from 'lib/reporting';




//Boilerplate code
const TheIntroduction = () => {

  const { userId } = useParams();

  const [profile, setProfile] = useState({});
  const [profileAvatar, setProfileAvatar] = useState(profile.user_avatar ? formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts) : blankprofileImage);
  const [profileIntro, setProfileIntro] = useState(profile.intro_summary || '');

  // This will hold the user's profile image
  const [loading, setLoading] = useState(false);
  const [failedLoading, setFailedLoading] = useState(false);
  const [displayedError, setDisplayedError] = useState('');
  
  const {authToken, userMetadata, logout, forceRefresh} = useContext(AuthContext);

  const [errors, setErrors] = useState({});

  /**
       * The function `uploadFile` is an asynchronous function that handles the uploading of a file,
       * specifically an avatar image, and updates the user's profile with the new avatar image.
       * @param event - The `event` parameter is an object that represents the event that triggered the
       * file upload. It is typically an event object that is passed to an event handler function when a
       * file is selected for upload. The `event.target` property refers to the element that triggered
       * the event, and `event.target.files
       */
  const uploadFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
        if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_change_avatar"); }
        setLoading(true);

        // Resize image, expect File object in return, not an actual file/image
        const resizedFileData = await resizeImage(file);
        
        let avatarId = null;
        // Add avatar ID
        if (typeof profile.user_avatar != 'undefined' 
            && profile.user_avatar
            && profile.user_avatar.id) {
                avatarId = profile.user_avatar.id;
        }

        // Upload image
        const { isSuccess: changeAvatarSuccess, resp: changeAvatarResp, err: changeAvatarErr } = 
            await changeUserProfileAvatar(userId, avatarId, resizedFileData, authToken);

        if (changeAvatarSuccess) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Image Upload || Success", changeAvatarResp); }
            
            setProfile(prevProfile => ({
                ...prevProfile,
                "user_avatar": changeAvatarResp.results.user_avatar
            }));

            // Update the profile image in the nav bar
            updateUserMetadata(changeAvatarResp.results.user_avatar, 'user_avatar');
            forceRefresh();
            setLoading(false);
        } else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error uploading profile image:", changeAvatarErr); }
            if (changeAvatarResp) {
                // If unauthorized, log out the user's session
                if (changeAvatarResp.status_code === "UNAUTHORIZED") { logout(); }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Empty response:", changeAvatarErr); }
            }
            setLoading(false);
            setFailedLoading(true);
        }
    } else {
        setFailedLoading(true);
    }
  };


    const handleProfileIntroChange = (event) => {
      setProfileIntro(event.target.value);
      setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.profileIntro;
          return newErrors;
      });
  };

  return (
    <div className='the-introduction-container'>
          <h1>Differentiate Yourself.</h1>
          <div className='introduction-text'>Upload your profile pic & introduce yourself</div>
          <div className='profile-pic-subsection'>
            <div className="large-profile-circle" style={{backgroundImage: `url(${profileAvatar})`}}/>
                {/* { authToken && userMetadata && loggedInUserId === userId && ( */}
                    <div className='profile-pic-subsection-button'>
                        <div className="profile-picture-wrapper">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={uploadFile}
                                style={{ display: 'none' }}
                                id="fileUploader"
                            />
                            <label htmlFor="fileUploader" className="upload-button">
                                {loading ? 'Uploading...' : (failedLoading ? 'Failed, try again.' : 'Update Avatar')}
                            </label>
                        </div>
                        {/* <div className='action-buttons'>
                            <Button variant="secondary" className='secondary' onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleSubmit}>Save</Button>
                        </div> */}
                        {displayedError}
                    </div>
                {/* )} */}
          </div>
          <div className='profile-intro profile-description-card'>
              <h3>Introduction*</h3>
              <div className="divider"></div>
                <div className="textAreaWrapper">
                    <textarea
                        name="profile-intro"
                        rows="5"
                        cols="40"
                        maxLength="350"
                        value={profileIntro}
                        placeholder= {"a. Briefly describe your background and what led you to become an entrepreneur. \n" + "b. Explain your motivation or personal mission as an entrepreneur and how it drives you to help others with their ideas."}
                        onChange={handleProfileIntroChange}
                    ></textarea>
                  <span className='largeCharCounter'>{profileIntro.length}/350</span>
                </div>
            </div>
        </div>
  );
};

export default TheIntroduction;
