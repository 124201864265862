import React, { Component } from 'react';
import './AboutUsModules.scss';
import Module from 'sub-components/module/Module';

export default class AboutUsModules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: this.props.header,
            subHeader: this.props.subHeader,
            tallestModuleHeight: 0
        };
    }

    componentDidMount() {
        this.adjustModuleHeight();
        window.addEventListener("resize", this.adjustModuleHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.adjustModuleHeight);
    }

    adjustModuleHeight = () => {
        let tallestModuleHeight = 0;
        const modules = document.getElementsByClassName("three-modules");
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = "auto";
            if (modules[i].offsetHeight > tallestModuleHeight) {
                tallestModuleHeight = modules[i].offsetHeight;
            }
        }
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = `${tallestModuleHeight}px`;
        }
        this.setState({ tallestModuleHeight });
    }

    render() {
        return (
            <div className="boxes-wrapper">
                <Module icon="bullhorn" header="Our mission" content="We want to create more entrepreneurs." />
                <Module icon="binoculars" header="The vision" content="An environment that removes obstacles to becoming an entrepreneur by easily finding the right partners, guidance, and resources to launch businesses." />
                <Module icon="target" header="Our target demographic" content="Entrepreneurs who have a new project or businesses and want to grow faster." />
                <Module icon="lifebuoy" header="Why build this" content="Entrepreneurship is growing in popularity for many reasons, but the barrier to entry is still high due to a lack of the right partners, guidance, and investment at the right time." />
                <Module icon="clock" header="Why now" content="The explosion of AI tools are empowering people to pursue larger entrepreneurial endeavors with less resources." />
                <Module icon="spinner" header="What's next" content="We want to provide value to everyone involved in helping new businesses grow. This means better features for entrepreneurs, accelerators/incubators, and investors." />
            </div>
        )
    }
}
