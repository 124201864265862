import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeader, ProjectFeed } from 'components';
import { FormModal, CreateAccountForm, SignInForm } from 'sub-components';
import AuthContext from 'app/AuthContext';
import { getEnvConfig } from 'lib/utils';

const DiscoveryPage = () => {
    const { authToken } = useContext(AuthContext);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (!authToken) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("No auth token found. Please login."); }
            // handleSignUpClick();
            handleSignInClick();
        }
    }, [authToken]); // useEffect will run whenever authToken changes


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }    

    const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
    };

    const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
        }
    }

    let query = useQuery();
    const filters = {};
    // Set filters to an empty object if category is null
    let category = query.getAll("category");
    if (category.length > 0) { filters.category = category; }
    let displayedCategory = category[0] === undefined ? "All" : category[0].charAt(0).toUpperCase() + category[0].slice(1);

    // Add default visibility type filter
    filters.visibility_types = "public";

    // Add tags to filters if they are present
    let tags = query.getAll('tag');
    if (tags.length > 0) { filters.tag = tags; }

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <div>
            <FormModal
                backdrop='static'
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
			    <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <div className='colored-background'>
                <PageHeader headerType={'simple'} header={`Browse ${displayedCategory} Startups`}/>
            </div>
            <div style={{marginTop: "50px"}}>
                {/* {authToken && <ProjectFeed quantity={6} filters={filters} scrollType="horizontal-scrolling"/>} */}
                {authToken && <ProjectFeed quantity={6} filters={filters} scrollType="undefined"/>}
            </div>
        </div>
    );
}

export default DiscoveryPage;
