import React, { useState } from 'react'
import { Button, Form, Alert} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import { verifyCaptcha } from 'lib/user';
import { sendEmail } from 'lib/utils';
import { sendEventToAnalytics } from 'lib/reporting';
import { verifyEmail } from 'lib/utils';

const ContactForm = (props) => {
    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [ errors, setErrors ] = useState({});

    // Form inputs
    const [state, setState] = useState({name: '', email: ''});

    // ReCAPTCHA
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = React.useRef();

    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    /**
     * The function `findFormErrors` checks for errors in a form's input fields and returns an object
     * containing any errors found.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any errors
     * found in the input fields.
     */
    const findFormErrors = () => {
        const name = state.name;
        const message = state.message;
        const email = state.email;
        const newErrors = {};
        // Input errors
        if ( !name || name === '' ) newErrors.name = 'Cannot be blank.';
        else if ( name.length > 40 ) name.name = 'Name is too long.';

        if (!email || email === '') { 
            newErrors.email = 'Please enter your email.';
        } else if (email.length > 320) { 
            newErrors.email = 'Email is too long.';
        } else if (verifyEmail(email) === false) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if ( !message || message === '' ) newErrors.message = 'Cannot be blank.';
        else if ( message.length > 280 ) newErrors.message = 'Message is too long.';
    
        return newErrors;
    }

    /**
     * The function `sendMessage` sends an email with the provided name, email, and message, and
     * handles success and error cases.
     * @param name - The name of the person sending the message.
     * @param email - The `email` parameter is the email address of the person sending the message.
     * @param message - The `message` parameter is the content of the message that the user wants to
     * send. It could be a text message or any other form of communication.
     */
    async function sendMessage(name, email, message) {
        try {
            // Update the project
            const { isSuccess: sendMsgSuccess, resp: sendMsgResp, err: sendMsgErr } = 
                await sendEmail({ "name" : name, "email": email, "message": message });

            if (sendMsgSuccess) {
                props.onActionCompleted();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Send message || Success: ", sendMsgResp); }
                if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "contact_us"); }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Send message || Error: ", sendMsgResp, sendMsgErr); }
                displayError(sendMsgResp.message);
            }
        } catch (error) {
            displayError("Fatal error, please check back shortly.");
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error('Error sending a message:', error); }
        }
    }

    /**
     * The function `onFormSubmit` is an asynchronous function that handles form submission, validates
     * form inputs, executes reCAPTCHA, and sends a message if the reCAPTCHA is successfully verified.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * form submission. It contains information about the event, such as the target element, the type
     * of event, and any additional data associated with the event. In this case, the `event` parameter
     * is used to prevent the default
     */
    async function onFormSubmit(event) {
        event.preventDefault();
        const newErrors = findFormErrors();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            try {
                // Execute reCaptcha
                await refCaptcha.current.callbacks.execute();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Processed site key."); }
                const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
                // Verify captcha
                const { isSuccess: captchaSuccess, resp: captchaResp, err: captchaErr } = await verifyCaptcha(reCaptchatoken);

                if (captchaSuccess) {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Authenticated the user."); }
                    sendMessage(state.name, state.email, state.message);
                } else {
                    displayError("ReCAPTCHA verification failed. Please try again later.");
                    console.error("ReCAPTCHA response came back with error:", captchaResp, captchaErr);
                }
            } catch (error) {
                displayError("A general error occurred, please try again later.");
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Captcha || Error: ", error); }
            }
        }
    }

    return (
        <Form onSubmit={onFormSubmit} className="default-form-format">
            <Form.Group controlId="formName">
                <Form.Control 
                    name="Name"
                    placeholder="Your Name"
                    type="text" 
                    onChange={(e) => {
                        setState({ ...state, name: e.target.value });
                        setErrors({ ...errors, name: null });
                    }}
                    value={state.name}
                    isInvalid={ !!errors.name}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.name }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Control 
                    name="email"  
                    type="email" 
                    placeholder="Email"
                    onChange={(e) => {
                        setState({ ...state, email: e.target.value });
                        setErrors({ ...errors, email: null });
                    }}
                    value={state.email}
                    isInvalid={ !!errors.email}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicMessage">
                <Form.Control 
                    name="Message"
                    placeholder="Message" 
                    type="text" 
                    as="textarea"
                    maxLength={280}
                    rows={3} // Set the number of rows you want to display
                    onChange={(e) => {
                        setState({ ...state, message: e.target.value });
                        setErrors({ ...errors, message: null });
                    }}
                    value={state.message}
                    isInvalid={ !!errors.message}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.message }
                </Form.Control.Feedback>
            </Form.Group>
            <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
            <div className="form-buttons">
                <Button variant="secondary" onClick={() => props.handleCloseModal()} >Cancel</Button>
                <Button variant="primary" type='submit'>Send Message</Button>
            </div>
            <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>
            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    );
};

export default ContactForm;
