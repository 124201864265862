import React, { useState } from 'react'
import { Button, Form, InputGroup} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { getParamValURL } from 'lib/utils';
import { changeUserPassword, verifyCaptcha } from 'lib/user';
import { sendEventToAnalytics } from 'lib/reporting';

const ChangePasswordForm = (props) => {
    
    /* The line `const [pwdState, setState] = useState({old_password: '', new_password: '',
    confirm_password: ''});` is using the `useState` hook in React to create a state variable called
    `pwdState` and a function called `setState` to update the state. The initial value of `pwdState`
    is an object with three properties: `old_password`, `new_password`, and `confirm_password`, all
    initialized to empty strings. This state is used to store the values entered in the password
    fields of the form. */
    const [pwdState, setState] = useState({old_password: '', new_password: '', confirm_password: ''});
    // Alerting
    const [ errors, setErrors ] = useState({});

    /**
     * The function toggles the visibility of a password field.
     */
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /* The code `const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;` is assigning the
    value of the environment variable `REACT_APP_RECAPTCHA_USER_SITE_KEY` to the variable
    `reCaptchaKey`. This variable is used as the site key for the reCAPTCHA component. */
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = React.useRef();

    /**
     * The function `findFormErrors` checks for errors in a password form, such as blank fields,
     * password length, and password confirmation mismatch.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any errors
     * found in the form.
     */
    const findFormErrors = () => {
        const newPassword = pwdState.new_password;
        const confirmPassword = pwdState.confirm_password;
        const newErrors = {};
        // Input errors
        if ( !newPassword || newPassword === '' ) newErrors.new_password = 'Cannot be blank.';
        else if ( newPassword.length > 128 ) newErrors.new_password = 'Password is too long.';

        if ( newPassword !== confirmPassword ) newErrors.confirm_password = 'Passwords do not match.';
        else if ( !confirmPassword || confirmPassword === '' ) newErrors.confirm_password = 'Cannot be blank.';
        else if ( confirmPassword.length > 128 ) newErrors.confirm_password = 'Password is too long.';
       
        return newErrors;
    }

    /**
     * The function `resetUserPassword` is an asynchronous function that changes the user's password,
     * sends an event to analytics, and handles any errors that occur during the process.
     * @param [newPassword=null] - The `newPassword` parameter is used to specify the new password that
     * the user wants to set. It is a string value.
     * @param [tokenId=null] - The `tokenId` parameter is used to identify the user for whom the
     * password is being reset. It is typically generated when the user initiates the password reset
     * process and is sent to their email address or mobile number.
     * @param [token=null] - The `token` parameter is used to verify the user's identity and ensure
     * that they have the authorization to change their password. It is typically generated and sent to
     * the user via email or another secure method when they request a password reset.
     */
    async function resetUserPassword(newPassword=null, tokenId=null, token=null) {
        // Send to analytics
        if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_password_reset"); }

        try {
            // Change the password
            const { isSuccess: changePwdSuccess, resp: changePwdResp, err: changePwdErr } = await changeUserPassword(null, newPassword, tokenId, token);

            if (changePwdSuccess) {
                props.onActionCompleted();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Change password || Success", changePwdResp); }
            } else {
                setErrors({'confirm_password': changePwdResp.message});
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Change password || Error", changePwdResp, changePwdErr); }
            }        
        } catch (error) {
            setErrors({'confirm_password': 'Fatal error, please check back shortly.'});
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error('Error resetting the password:', error); }
        }
    }

    /**
     * An event handler for a form submission that performs form validation,
     * executes reCAPTCHA verification, and sends a request to the backend for suspicion score
     * verification.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * form submission. It contains information about the event, such as the target element, the type
     * of event, and any additional data associated with the event. In this case, the `event` parameter
     * is used to prevent the default
     */
    async function onFormSubmit(event) {
        event.preventDefault();
        const newErrors = findFormErrors();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
        } else {
            try {
                // Execute reCaptcha
                await refCaptcha.current.callbacks.execute();
                const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
                // Verify captcha
                const { isSuccess: captchaSuccess, resp: captchaResp, err: captchaErr } = await verifyCaptcha(reCaptchatoken);

                if (captchaSuccess) {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Authenticated the user."); }
                    // Get token info from the current browser's URL
                    let pwdResetToken = getParamValURL('token', window.location.href);
                    let pwdResetTokenId = getParamValURL('id', window.location.href);

                    if (pwdResetToken !== null && pwdResetToken.length > 0) { pwdResetToken = pwdResetToken[0]; }
                    if (pwdResetTokenId !== null && pwdResetTokenId.length > 0) { pwdResetTokenId = pwdResetTokenId[0]; }

                    if (pwdResetToken && pwdResetTokenId) {
                        // Reset the password
                        resetUserPassword(pwdState.confirm_password, pwdResetTokenId, pwdResetToken);
                    } else {
                        setErrors({'confirm_password': 'Invalid data required for resetting the password.'});
                    }
                } else {
                    setErrors({'confirm_password': 'ReCAPTCHA verification failed. Please try again later.'});
                    console.error('ReCAPTCHA response came back with error:', captchaResp, captchaErr);
                }
            } catch (error) {
                setErrors({'confirm_password': 'A general error occurred, please try again later.'});
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Change password || Error:", error); }
            }
        }
    };

    return (
        <Form onSubmit={onFormSubmit} className="default-form-format">
            <Form.Group controlId="formNewPassword">
                <InputGroup>
                    <Form.Control
                        name="new_password"
                        placeholder="New password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                            setState({ ...pwdState, new_password: e.target.value });
                            setErrors({ ...errors, new_password: null });
                        }}
                        value={pwdState.new_password}
                        isInvalid={!!errors.new_password}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text
                        className="sign-in-password-toggle"
                        onClick={togglePasswordVisibility}>
                            {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                        {errors.new_password}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="formConfPassword">
                <InputGroup>
                    <Form.Control
                        name="confirm_password"
                        placeholder="Confirm password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                            setState({ ...pwdState, confirm_password: e.target.value });
                            setErrors({ ...errors, confirm_password: null });
                        }}
                        value={pwdState.confirm_password}
                        isInvalid={!!errors.confirm_password}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text
                        className="sign-in-password-toggle"
                        onClick={togglePasswordVisibility}>
                            {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
            <div className="form-buttons">
                <Button variant="secondary" onClick={() => props.handleCloseModal()} >Cancel</Button>
                <Button variant="primary" type='submit'>Reset</Button>
            </div>
            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    );
};

export default ChangePasswordForm;
