import React, {useState} from 'react';
import { Layout } from 'layout';
import './OnboardingPage.scss'
import { TheSentence, TheIntroduction } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'react-bootstrap';
import SkillsExpertiseInterests from 'components/onboardingComponents/SkillsExpertiseInterests';
import Portfolio from 'components/onboardingComponents/Portfolio';

const OnboardingPage = () => {
	const [currentStep, setCurrentStep] = useState(0);

	const steps = [
		<TheSentence key="sentence" />,
		<TheIntroduction key="introduction" />,
		<SkillsExpertiseInterests key="skillsexpertiseinterests" />,
		<Portfolio key="portfolio" />
	];

	const handleNext = () => {
		setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
	};

	const handlePrev = () => {
		setCurrentStep((prevStep) => (prevStep - 1 + steps.length) % steps.length);
	};

	return (
		<Layout tabHeader="Onboarding Flow">
		<div className='onboarding-flow-container'>
			<AnimatePresence exitBeforeEnter>
				<motion.div
					key={currentStep}
					initial={{ opacity: 0, x: 100 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: -100 }}
					transition={{ duration: 0.5 }}
					className="onboarding-content"
				>
					{steps[currentStep]}
				</motion.div>
			</AnimatePresence>
			<div className='navigation-buttons'>
				<Button onClick={handlePrev} hidden={currentStep === 0}>
					Previous
				</Button>
				<Button onClick={handleNext} hidden={currentStep === steps.length - 1}>
					Next
				</Button>
				<Button hidden={currentStep !== steps.length - 1}>
					Submit
				</Button>
			</div>
		</div>
		</Layout>
	);
};

export default OnboardingPage;