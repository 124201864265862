import React, { useState, useContext } from 'react';
import "../flowIntroduction/FlowIntroduction.scss";
import {Module, FormModal, ProjectCardForm, CreateAccountForm} from 'sub-components';
import { useNavigate } from 'react-router-dom';
import profileImage from 'images/blank-profile-picture.png'
import AuthContext from 'app/AuthContext';
import projectIcon from 'images/project-icon.png';
import personIcon from 'images/person-icon.png';
import multiProjectsIcon from 'images/multi-projects-icon.png';

const FlowIntroduction = ({ header, subHeader }) => {
    const navigate = useNavigate();
    
    // Manages the profile access in the nav bar when a user is logged in
    const { authToken, userMetadata } = useContext(AuthContext);
	const userId = userMetadata ? userMetadata.id : "";

    // Constants for the project card form
    const [isCreateToggleOn, setIsCreateToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    // Sign up constants
    const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);

    const handleBrowseClick = () => {
		navigate(`/discovery`)
	}

    const handleCreateClick = () => {
        if (authToken && userMetadata) {
            setIsCreateToggleOn(!isCreateToggleOn);
            setActionCompleted(false);
        }
        else {
            handleSignUpClick();
        }
	}

    const handleProfileClick = () => {
        if (authToken && userMetadata) {
            navigate('/profile/' + userId);
        }
        else {
            handleSignUpClick();
        }
	};

    const handleSignUpClick = () => {
		setIsSignUpToggleOn(!isSignUpToggleOn);
		setActionCompleted(false);
	};

    const handleCreateCompleted = () => {
		setActionCompleted(true);
		const id = setTimeout(() => {
			setIsCreateToggleOn(false);
			window.location.reload();
		}, 1000);
		setTimeoutId(id);
		const currentURL = window.location.pathname;
		if (currentURL.includes("venture") || currentURL.includes("psl") ) { 
			navigate(currentURL);
		} else {
			navigate('/profile/' + userId);
		}
	}

    const handleActionCompleted = () => {
		setActionCompleted(true);
		const id = setTimeout(() => {
		  setIsSignUpToggleOn(false);
		}, 1000);
		setTimeoutId(id);
	};

    return (
        <div className="boxes-wrapper">
            <FormModal
                isToggleOn={isCreateToggleOn} 
                handleClick={handleCreateClick}
                actionCompleted={actionCompleted}
                onClose={() => clearTimeout(timeoutId)}
                title="Post Your Startup"
                successfulActionTitle="Congratulations!"
                successfulActionMessage="You can access your post on your profile."
                modalType="project-card-modal">
                <ProjectCardForm
                    handleActionCompleted={handleCreateCompleted}
                    userMetadata={userMetadata}
                    profileImage={profileImage}
                    editPostMode={false}
                />
		    </FormModal>
            <FormModal
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title="Sign Up"
                successfulActionTitle="Account Created"
                successfulActionMessage="Log in and let's see what you're capable of!">
                <CreateAccountForm onActionCompleted={handleActionCompleted} />
            </FormModal>
            <Module onClick={handleCreateClick} image={projectIcon} imageDesc="sidejawn project icon" header="Share Your Initiative" content="Post what you're working on, why you're working on it, and how you're doing it to attract the right partners." className="semi-clickable" />
            <Module onClick={handleProfileClick} image={personIcon} imageDesc="sidejawn green silhouette person icon" header="Showcase Your Specialties" content="Complete your profile and highlight why you are the right founder/partner to work on an initiative." className="semi-clickable" />
            <Module onClick={handleBrowseClick} image={multiProjectsIcon} imageDesc="sidejawn projects icon" header="Find Interesting Ventures" content="Browse a wide variety of initiatives and collaborate with other like-minded entrepreneurs." className="semi-clickable" />
        </div>
    );
}

export default FlowIntroduction;
