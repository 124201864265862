import React, {useState, useEffect, useContext, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout } from 'layout';
import { Button, Alert, Form } from 'react-bootstrap';
import CreatableSelect, { components } from 'react-select';
import { FormModal, ProjectCardForm, CreateAccountForm, SignInForm, CreateAccountModal } from 'sub-components';
import AuthContext from 'app/AuthContext';
import Select from 'react-select';
import { updateUserMetadata, changeUserProfileAvatar, getUserProfile, updateUserProfile, getDefaultUserGoals, getDefaultUserTitles, getDefaultUserSkills, getDefaultUserExpertises, getDefaultUserStartUpPast, getDefaultUserInterests, processUserProfileData, getTitleAndGoal, updateProfileTags, getDefaultUserTimeCommit } from 'lib/user';
import { useLoadScript } from "@react-google-maps/api";
import { convertToMarkdown, removeMarkdown, resizeImage, formatAvatarUrl } from 'lib/utils';
import { sendEventToAnalytics } from 'lib/reporting';
import DiscoveryPage from 'pages/discoveryPage/DiscoveryPage';

const libraries = ["places"];

const TheSentence = () => {

    const { userId } = useParams();
    const navigate = useNavigate();

    const [ profile, setProfile ] = useState({});
    // const [profileAvatar, setProfileAvatar] = useState(profile.user_avatar ? formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts) : blankprofileImage);

    // This will hold the user's profile image
    const [loading, setLoading] = useState(false);
    const [failedLoading, setFailedLoading] = useState(false);


    const [firstName, setFirstName] = useState(profile.f_name || '');
    const [lastName, setLastName] = useState(profile.l_name || '');
    const [title, setTitle] = useState(profile.title || '');
    const defaultTitles = getDefaultUserTitles();
    const [titleFilter, setTitleFilter] = useState('All');
    const [availableTitles, setAvailableTitles] = useState(defaultTitles);
    const [location, setLocation] = useState(profile.location || '');
    const [goal, setGoal] = useState(profile.goal || '');
    const [startuppast, setStartUpPast] = useState(profile.startuppast || '')
    const [timecommit, setTimeCommit] = useState(profile.timecommit || '')
    const [experience, setExperience] = useState(profile.experience || '');
    const { authToken, userMetadata, logout, forceRefresh} = useContext(AuthContext);
    const loggedInUserId = userMetadata.id;
    const [ displayedError, setDisplayedError ] = useState('');
    const [profileIntro, setProfileIntro] = useState(profile.intro_summary || '');

    const [ errors, setErrors ] = useState({});


    const defaultGoals = getDefaultUserGoals();
    const defaultStartUpPast = getDefaultUserStartUpPast();
    const defaultTimeCommit = getDefaultUserTimeCommit();
    // const defaultSkills = getDefaultUserSkills();
    // const defaultExpertises = getDefaultUserExpertises();
    // const defaultInterests = getDefaultUserInterests();

      // LinkedIn is always the first portfolio item
      const sortPortfolios = (portfolios) => {
        return portfolios?.sort((a, b) => {
          if (a.icon === 'linkedin') return -1;
          if (b.icon === 'linkedin') return 1;
          return 0;
        });
    };

    const [portfolios, setPortfolios] = useState([]);      
    const [portfolioEditVisible, setPortfolioEditVisible] = useState(false);
    const [portfolioSelection, setPortfolioSelection] = useState([{id:'1', icon:'linkedin'}, {id:'2', icon:'avvo'}, {id:'3', icon:'behance'}, {id:'4', icon:'fiverr'}, {id:'5', icon:'instagram'}, {id:'6', icon:'medium'}, {id:'7', icon:'upwork'}, {id:'8', icon:'vimeo'}, {id:'9', icon:'zocdoc'}, {id:'10', icon:'generic'}, {id:'11', icon:'github'}]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const isSelectedPortfolioInPortfolios = selectedPortfolio && portfolios?.some(portfolio => portfolio.icon === selectedPortfolio.icon);
    const portfolioEditorRef = useRef(null);
    const PREFIX = "https://";
    const initialUrl = selectedPortfolio ? selectedPortfolio.url || PREFIX : PREFIX;
    const urlPattern = new RegExp(
        /^(https:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([/?].*)?$/
    );
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [url, setUrl] = useState(initialUrl);
    
    //Google Places API Key
    const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const inputRef = useRef(null);
 
    //Loads the Google Places API
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsKey,
        libraries: libraries,
    });
 
    /* Initialize and configure a Google Maps Autocomplete feature for a given input field. */
    useEffect(() => {
        if (isLoaded && inputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
            { types: ["(cities)"] }
        );
       
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
       
            let city, state, country;
            for (const component of place.address_components) {
                if (component.types.includes("locality")) {
                    city = component.long_name;
                } else if (
                    component.types.includes("administrative_area_level_1")
                ) {
                    state = component.long_name;
                } else if (component.types.includes("country")) {
                    country = component.long_name;
                }
            }
       
            const locationValue = `${city}, ${state}, ${country}`;
            handleLocationChange({ target: { value: locationValue } });
          });
        }
    }, [isLoaded]);


    const filteredTitles = () => {
      if (titleFilter === 'All') {
          return defaultTitles;
      }
      return defaultTitles.filter(group => group.label === titleFilter);
  };

    const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
      setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.firstName;
          return newErrors;
      });
  };

    const handleLastNameChange = (event) => {
     setLastName(event.target.value);
     setErrors((prevErrors) => {
         const newErrors = { ...prevErrors };
         delete newErrors.lastName;
         return newErrors;
     });
  };

    const handleLocationChange = (e) => {
      setLocation(e.target.value);
      setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.location;
          return newErrors;
      });
  };

  const handleTitleChange = (titleInput) => {
    if (titleInput && !availableTitles.some(option => option.value === titleInput.value)) {
        // Add new title if it doesn't exist
        setTitle({ value: titleInput, label: titleInput, category: "Contribution" });
    }
    setTitle(titleInput);
    setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.title;
        return newErrors;
    });
  }; 

    const handleGoalChange = (goalInput) => {
      setGoal(goalInput);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.goal;
        return newErrors;
    });
  }

  const handleStartUpPastChange = (startuppastInput) => {
    setStartUpPast(startuppastInput);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.startuppast;
      return newErrors;
  });
  }

  const handleTimeCommitChange = (timecommitInput) => {
    setTimeCommit(timecommitInput);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.timecommit;
      return newErrors;
  });
  } 

  const handleProfileIntroChange = (event) => {
    setProfileIntro(event.target.value);
    setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.profileIntro;
        return newErrors;
    });
};

  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
    setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.experience;
        return newErrors;
    });
};

  /**
     * The function `handleTitleInputChange` limits the input value to 36 characters and then sets
     * available titles to default titles.
     * @param input - The `input` parameter in the `handleTitleInputChange` function represents the
     * value entered into a title input field. The function limits the input value to 36 characters and
     * sets available titles to a default list if the input exceeds this limit.
     * @returns If the input length is greater than 36 characters, nothing is being returned
     * explicitly. The function will exit early without setting any new available titles.
     */
  const handleTitleInputChange = (input) => {
    // Limit the input value to 36 characters
    if (input && input.length > 36) return;
    setAvailableTitles(defaultTitles);
}

     // Handles the display for adding a role or not being able to add a role
     const NoOptionsMessage = (props) => {
      const {
          selectProps: { inputValue }
      } = props;
      
      const handleClick = () => {
          let formattedInput;
          // Format tag input values
          if (props.selectProps.onCreateOption.name === "handleTitleChange") {
              // Remove all special characters except dashes '-' and spaces ' '
              formattedInput = inputValue.replace(/\b\w/g, char => char.toUpperCase());
          }
          props.selectProps.onChange({ value: formattedInput, label: formattedInput });
          props.selectProps.onInputChange('');  // Clear the input value
          props.selectProps.onMenuClose();      // Close the dropdown
      }        
    
      return (
          <components.NoOptionsMessage {...props}>
              <div className="select-no-options" onClick={handleClick}>
                  {inputValue ? `Add: ${inputValue}` : "No options"}
              </div>
          </components.NoOptionsMessage>
      );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateFields();
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
        const [city, province, country] = location.split(", ").map((part) => part.trim());
        changeUserProfile(userId, {first_name: firstName, last_name: lastName, title: title.value, goal: goal.value, city, province, country, startuppast, timecommit, tags: profile.tags });
    }
  }


  /**
     * The function `validateFields` checks if certain fields are empty or not defined and returns an
     * object with any errors found.
     * @returns The function `validateFields` returns an object `newErrors` which contains error
     * messages for each field that is empty or not defined.
     */
  const validateFields = () => {
    const fields = [
        { name: "firstName", value: firstName },
        { name: "lastName", value: lastName },
        { name: "title", value: title.value },
        { name: "goal", value: goal.value },
        { name: "location", value: location },
        { name: "startuppast", value: startuppast.value },
        { name: "timecommit", value: timecommit.value },
    ];
  
    const newErrors = {};
   
  
    fields.forEach((field) => {
        if (!field.value || field.value.trim() === "") {

            newErrors[field.name] = `Field ${field.name} is empty or not defined`;
            setDisplayedError(`Field ${field.name} is empty or not defined`)
        }
    });
  
    return newErrors;
}; 


/**
     * The function `changeUserProfile` updates a user's profile information and redirects them to
     * their profile page if the update is successful.
     * @param userId - The userId parameter is the unique identifier of the user whose profile is being
     * updated. It is used to identify the user in the updateUserProfile function.
     * @param data - The `data` parameter is an object that contains the user's profile data. It can
     * include properties such as `profileIntro`, `experience`, and `education`. These properties are
     * expected to be in a specific format, so the code is using the `convertToMarkdown` function to
     * convert them to
     */
async function changeUserProfile(userId, data) {
  // Formatting covers lists and links
  // if (data.intro_summary) { data.intro_summary = convertToMarkdown(profileIntro); }
  // if (data.experience) { data.experience = convertToMarkdown(experience); }
  // if (data.education) { data.education = convertToMarkdown(education); }

  try {
      const { isSuccess: updateUserSuccess, resp: updateUserResp, err: updateUserErr } = await updateUserProfile(userId, data, authToken);

      if (updateUserSuccess) {
          if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Update || Success:", updateUserResp); }
          // TODO: This initiates a refresh of the profile page. 
          // There should be a better way to use the data that's already in memory.
          navigate(`/profile/${userId}?is_profile_view=true`);
      } else {
          if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Update || Error:", updateUserResp, updateUserErr); }
          if (updateUserResp) {
              // Log out user if the token session is invalid (most likely expired).
              if (updateUserResp.status_code === "UNAUTHORIZED") { 
                  logout(); 
              } else {
                  setDisplayedError("There was an error updating your profile. Please try again later.");
              }
          } else {
              setDisplayedError("There was a fatal error updating your profile. Please try again later.");
          }
      }
  } catch (error) {
      if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error updating user's profile:", error); }
      setDisplayedError("There was a fatal error updating your profile. Please try again later.");
  }
}

/**
     * The function `uploadFile` is an asynchronous function that handles the uploading of a file,
     * specifically an avatar image, and updates the user's profile with the new avatar image.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * file upload. It is typically an event object that is passed to an event handler function when a
     * file is selected for upload. The `event.target` property refers to the element that triggered
     * the event, and `event.target.files
     */
const uploadFile = async (event) => {
  const file = event.target.files[0];
  if (file) {
      if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_change_avatar"); }
      setLoading(true);

      // Resize image, expect File object in return, not an actual file/image
      const resizedFileData = await resizeImage(file);
      
      let avatarId = null;
      // Add avatar ID
      if (typeof profile.user_avatar != 'undefined' 
          && profile.user_avatar
          && profile.user_avatar.id) {
              avatarId = profile.user_avatar.id;
      }

      // Upload image
      const { isSuccess: changeAvatarSuccess, resp: changeAvatarResp, err: changeAvatarErr } = 
          await changeUserProfileAvatar(userId, avatarId, resizedFileData, authToken);

      if (changeAvatarSuccess) {
          if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Image Upload || Success", changeAvatarResp); }
          
          setProfile(prevProfile => ({
              ...prevProfile,
              "user_avatar": changeAvatarResp.results.user_avatar
          }));

          // Update the profile image in the nav bar
          updateUserMetadata(changeAvatarResp.results.user_avatar, 'user_avatar');
          forceRefresh();
          setLoading(false);
      } else {
          if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error uploading profile image:", changeAvatarErr); }
          if (changeAvatarResp) {
              // If unauthorized, log out the user's session
              if (changeAvatarResp.status_code === "UNAUTHORIZED") { logout(); }
          } else {
              if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Empty response:", changeAvatarErr); }
          }
          setLoading(false);
          setFailedLoading(true);
      }
  } else {
      setFailedLoading(true);
  }
};

// TODO: This causes a backend API call, maybe use userMetata in local storage or state of data?
const handleCancel = () => {
  navigate(`/profile/${userId}?is_profile_view=true`);
};

 /**
     * The `handlePortfolioClick` function toggles the visibility of the portfolio editor and updates
     * the selected portfolio with full details if a portfolio is provided.
     * @param portfolio - The `portfolio` parameter in the `handlePortfolioClick` function likely
     * represents a specific portfolio item that the user has clicked on or interacted with. This could
     * be an object containing details about the portfolio, such as its icon, name, description, or any
     * other relevant information.
     * @param edit - The `edit` parameter in the `handlePortfolioClick` function is a boolean value
     * that indicates whether the user is currently in edit mode for the portfolio.
     */
 const handlePortfolioClick = (portfolio, edit) => {
  // Toggle the visibility of the portfolio editor
  if(!edit)
      setPortfolioEditVisible(prevState => !prevState);
  // If a portfolio is provided, update the selectedPortfolio with the full details from the main list
  if (portfolio) {
      const fullPortfolio = portfolios?.find(p => p.icon === portfolio.icon) || portfolio;
      setSelectedPortfolio(fullPortfolio);
  } else {
      // Optionally handle the case where no portfolio is provided
      setSelectedPortfolio(null);
  }
};

/**
     * The `handleTagAdd` function updates profile tags with new tag data based on the selected option.
     * @param selectedOption - The `selectedOption` parameter is an object that represents the option
     * that has been selected by the user. It typically contains properties such as `label` for the
     * display name of the option, `value` for the unique identifier of the option, and `category` for
     * the category to which the option
     * @param type - The `type` parameter in the `handleTagAdd` function is used to specify the type of
     * action being performed when adding a tag. It could be used to differentiate between different
     * types of tag additions or to determine how the tag should be processed within the function.
     */
const handleTagAdd = (selectedOption, type) => {
  if (selectedOption) {
      const newData = {
          display_name: selectedOption.label,
          id: selectedOption.value,
          category: selectedOption.category
      };
      
      // Update the profile tags with the new tag data
      const updatedTags = updateProfileTags(profile, newData, selectedOption.category, type);
      setProfile({ ...profile, tags: updatedTags });
  }
};

/**
     * The function `handlePortfoliosUpdate` updates a list of portfolios with a selected portfolio,
     * sorting them and toggling the visibility of the portfolio editor if a valid URL is provided.
     */
const handlePortfoliosUpdate = () => {
  // Validate the URL
  setIsValidUrl(urlPattern.test(url));
  // Update the selected portfolio with the URL and add it to the list of portfolios
  if ("icon" in selectedPortfolio && urlPattern.test(url)){
      setPortfolios(prevPortfolios => {
          // Ensure that prevPortfolios is initialized properly if it's undefined or null
          prevPortfolios = prevPortfolios || [];
  
          if (prevPortfolios.length > 0) {
              const index = prevPortfolios.findIndex(p => p.icon === selectedPortfolio.icon);
              let newPortfolios = [...prevPortfolios];
              if (index > -1) {
                  // Portfolio exists, update it
                  newPortfolios[index] = selectedPortfolio; // already includes the updated URL
              } else {
                  // Portfolio does not exist, add it
                  newPortfolios.push(selectedPortfolio);
              }
              return sortPortfolios(newPortfolios); // Sort the portfolios after update/add
          } else {
              // If there are no previous portfolios, start the list with the selectedPortfolio
              return sortPortfolios([selectedPortfolio]);
          }
      });
  
      // Toggle the visibility of the portfolio editor
      setPortfolioEditVisible(prevVisible => !prevVisible);
  }
};

// Used by the portfolio editor to update the URL of the selected portfolio
const handleUrlChange = (event) => {
  let newUrl = event.target.value.trim();

  // Remove "http://" and "https://" (especilaly when pasting a URL with "https://")
  newUrl = newUrl.replace(/^https?:\/\//, '');

  if(newUrl === "https:/") return;
  // Ensure the URL starts with "https://"
  if (!newUrl.startsWith(PREFIX)) {
      newUrl = PREFIX + newUrl.replace(/^https?:\/\//, '');
  }

  // Prevent deletion beyond the prefix
  if (newUrl.length < PREFIX.length) {
      newUrl = PREFIX;
  }

  setSelectedPortfolio(currentPortfolio => ({
      ...currentPortfolio,
      url: newUrl
  }));
  setUrl(newUrl);
};

const handleUrlSelection = (e) => {
  // Prevent selecting the prefix
  const input = e.target;
  if (input.selectionStart < PREFIX.length) {
      input.setSelectionRange(PREFIX.length, PREFIX.length);
  }
};

const handleMouseUp = (e) => handleUrlSelection(e);

// Used by the portfolio editor to delete a portfolio
const handleDeletePortfolioClick = (event, selectedPortfolio) => {
  event.stopPropagation(); // Stop event from propagating to parent
  // Delete portfolio
  const updatedPortfolios = portfolios.filter((portfolio) => portfolio.id !== selectedPortfolio.id);
  setPortfolios(updatedPortfolios);
};


/**
     * The function `handleDeleteTagClick` is used to remove a specific tag from a nested data
     * structure based on its id and type.
     * @param event - The `event` parameter is the event object that is passed to the event handler
     * function. It contains information about the event that occurred, such as the type of event,
     * target element, and any additional data related to the event. In this case, it is used to
     * prevent the event from propagating
     * @param id - The `id` parameter in the `handleDeleteTagClick` function represents the unique
     * identifier of the tag that needs to be deleted.
     * @param type - The `type` parameter in the `handleDeleteTagClick` function represents the type of
     * tag that is being deleted. It is used to identify the specific tag within the `profile.tags`
     * array that needs to be updated or removed.
     */
const handleDeleteTagClick = (event, id, type) => {
  event.stopPropagation(); // Stop event from propagating to parent
  const updatedTags = profile.tags.map(tag => {
      if (tag.type === type) {
          return {
              ...tag,
              categories: tag.categories.map(category => ({
                  ...category,
                  tags: category.tags.filter(i => i.id !== id)
              }))
          };
      }
      return tag;
  });

  setProfile({ ...profile, tags: updatedTags });
};

return (
//   <Layout tabHeader="Onboarding Flow">
//     <div className='the-sentence-container'>
//         <h1>Tell Us About Yourself.</h1>
//         <div>Remember Mad Libs? If not, the following should help.</div>
//         <div className='row'>
//         <div>My name is </div>
//         <div className='profile-name'>
//             <Form.Control
//             type="text"
//             id="f_name"
//             value={firstName}
//             placeholder="First Name*"
//             onChange={handleFirstNameChange}
//             />
//         </div>
//         <div className='profile-name'>
//             <Form.Control
//             type="text"
//             id="l_name"
//             value={lastName}
//             placeholder="Last Name*"
//             onChange={handleLastNameChange}
//             />
//         </div>
//         <div> and I'm based in </div>
//         <div className='search-input'>
//             <Form.Control
//             type="text"
//             id="location"
//             value={location}
//             placeholder="Enter a city*"
//             onChange={handleLocationChange}
//             ref={inputRef}
//             />
//         </div>
//         </div>
//         <div className='row'>
//         <div>I am a </div>
//         <div className='text-and-search-input'>
//         <CreatableSelect
//             id="title"
//             value={title}
//             onInputChange={handleTitleInputChange}
//             onChange={handleTitleChange}
//             onCreateOption={handleTitleChange}
//             placeholder="Pick or add occupational title*"
//             options={filteredTitles()}
//             isSearchable
//             formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
//             components={{ NoOptionsMessage }}
//             className="basic-multi-select"
//             classNamePrefix="select"
//             menuPortalTarget={document.body}
//             menuPosition={'fixed'} // Required for portal to work correctly
//             menuPlacement={'auto'} // Top or bottom based on the available space
//             styles={{
//                 menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index if necessary
//                 option: (provided, state) => ({
//                     ...provided
//                 }),
//             }}
//         />
//         </div>
//         <div> and I would like to </div>
//         <div className='text-and-search-input'>
//             <Select
//                 id="goal"
//                 value={goal}
//                 onChange={handleGoalChange}
//                 placeholder="goal for being here*"
//                 options={defaultGoals}
//               />
//         </div>
//         </div>
//         <div className='row'>
//         <div>I have </div>
//         <div className='selection-input'>
//             <Select
//                 id="startup-past"
//                 value={startuppast}
//                 onChange={handleStartUpPastChange}
//                 placeholder="Select startup experience"
//                 options={defaultStartUpPast}
//              />
//         </div>
//         <div> and am available </div>
//         <div className='selection-input'>
//            <Select
//                 id="timecommit"
//                 value={timecommit}
//                 onChange={handleTimeCommitChange}
//                 placeholder="Select weekly time commitment"
//                 options={defaultTimeCommit}
//             />
//         </div>
//         </div>
//     </div>
//   </Layout>
        <div className='the-sentence-container'>
          <h1>Tell Us About Yourself.</h1>
          <div className='sentence-text'>Remember Mad Libs? If not, the following should help.</div>
          <div className='sentence-row'>
            <div className='sentence-text'>My name is </div>
            <div className='input-box'>
            <Form.Control
                type="text"
                id="f_name"
                value={firstName}
                placeholder="First Name*"
                onChange={handleFirstNameChange}
            />
            </div>
            <div className='input-box'>
            <Form.Control
                type="text"
                id="l_name"
                value={lastName}
                placeholder="Last Name*"
                onChange={handleLastNameChange}
            />
            </div>
            <div className='sentence-text'> and I'm based in </div>
            <div className='input-box'>
              <Form.Control
                type="text"
                id="location"
                value={location}
                placeholder="Enter a city*"
                onChange={handleLocationChange}
                ref={inputRef}
              />
            </div>
          </div>
          <div className='sentence-row'>
            <div className='sentence-text'>I am a </div>
            <div className='selection-box'>
              <CreatableSelect
                id="title"
                value={title}
                onInputChange={handleTitleInputChange}
                onChange={handleTitleChange}
                onCreateOption={handleTitleChange}
                placeholder="Pick or add occupational title*"
                options={filteredTitles()}
                isSearchable
                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                components={{ NoOptionsMessage }}
                className="basic-multi-select"
                classNamePrefix="select"
                // menuPortalTarget={document.body}
                // menuPosition={'fixed'} // Required for portal to work correctly
                menuPlacement={'auto'} // Top or bottom based on the available space
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index if necessary
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#edecec',  // Set border color to gray
                        borderRadius: '0.75em',  // Set border radius to 0.75em
                        margin: '0px 5px 5px 5px'
                    }),
                    option: (provided, state) => ({
                        ...provided
                    }),
                    groupHeading: (provided, state) => ({
                        ...provided,
                        padding: '10px',
                    })
                }}
              />
            </div>
            <div className='sentence-text'> and I would like to </div>
            <div className='selection-box'>
              <Select
                id="goal"
                value={goal}
                onChange={handleGoalChange}
                placeholder="goal for being here*"
                options={defaultGoals}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#edecec',  // Set border color to gray
                        borderRadius: '0.75em',  // Set border radius to 0.75em
                        margin: '0px 5px 5px 5px'
                    })
                }}
              />
            </div>
          </div>
          <div className='sentence-row'>
            <div className='sentence-text'>I have </div>
            <div className='selection-box'>
              <Select
                id="startup-past"
                value={startuppast}
                onChange={handleStartUpPastChange}
                placeholder="Select startup experience"
                options={defaultStartUpPast}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#edecec',  // Set border color to gray
                        borderRadius: '0.75em',  // Set border radius to 0.75em
                        margin: '0px 5px 5px 5px'
                    })
                }}
              />
            </div>
            <div className='sentence-text'> and am available </div>
            <div className='selection-box'>
              <Select
                id="timecommit"
                value={timecommit}
                onChange={handleTimeCommitChange}
                placeholder="Select weekly time commitment"
                options={defaultTimeCommit}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#edecec',  // Set border color to gray
                        borderRadius: '0.75em',  // Set border radius to 0.75em
                        margin: '0px 5px 5px 5px'
                    })
                }}
              />
            </div>
          </div>
          <div className='sentence-button-and-error'>
            {/* <div className='submit-button'>
              <Button onClick={handleSubmit}>Save and Continue</Button>
            </div> */}
            <Alert variant="danger" className="animated-fast fadeInDownMenu"><Alert.Heading style={{fontSize: "13px", color: "red"}}>{displayedError}</Alert.Heading></Alert>
          </div>
        </div>
);


};

export default TheSentence;