import React, {Fragment} from 'react';
import {EarlyLandingPage, LandingPage, About, NotFoundPage, UserProfile, UserProfileEdit, DiscoveryPage, Resources, EventPage, ResetPasswordPage, AcctActivationPage, OnboardingPage} from 'pages';
import {NavBar, Footer} from 'components';
import ScrollToTop from './ScrollToTop';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import {
    BrowserRouter as Router,
    Routes,
    Route, 
  } from "react-router-dom";

export default function App() {
    if (process.env.REACT_APP_EARLY_PRODUCTION === 'true') {
        return (
                <Router>
                    <Fragment>
                        <ScrollToTop />
                        <NavBar/>
                        <Routes>
                            <Route exact path="/" element={<EarlyLandingPage/>} index /> 
                            <Route exact path="/about" element={<About/>} />
                            <Route path="*" element={<NotFoundPage/>} />
                            <Route path="/profile/:userId" element={<UserProfile/>} />
                            <Route path="/profile/:userId/edit" element={<UserProfileEdit/>} />
                            <Route path="/discovery" element={<DiscoveryPage/>} />
                            <Route path="/resources" element={<Resources/>} />
                            <Route path="/venture-cafe" element={<EventPage/>} />
                            <Route path="/founder-institute" element={<EventPage/>} />
                            {/* <Route path="/password-reset" element={<Navigate to={{ pathname: "/", search: window.location.search }} />}  /> */}
                            <Route path="/password-reset" element={<ResetPasswordPage/>} />
                            <Route path="/acct-activation" element={<AcctActivationPage/>} />
                            <Route path="/linkedin-auth/signup" element={<LinkedInCallback/>} />
                            <Route path="/linkedin-auth/login" element={<LinkedInCallback/>} />
                            <Route path="/onboarding" element={<OnboardingPage/>} />
                        </Routes>
                        <Footer/>
                    </Fragment>
                </Router>
        );
    }
    else {
        return (
            <Router>
                <NavBar/>
                <Routes>
                    <Route exact path="/">
                        <LandingPage/> 
                    </Route>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                </Routes>
                <Footer/>
            </Router>
        );
    }
}
