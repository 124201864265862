import { find } from 'linkifyjs';
import { v4 as uuidv4 } from 'uuid';

// Import only needed settings, in this case, the bake API setting object only
//envConfig.baker_api = require(`conf/${process.env.REACT_APP_ENVIRONMENT}.json`).baker_api;
const envConfig = require(`conf/${process.env.REACT_APP_ENVIRONMENT}.json`);
// Merge global/common setting to env specific settings into one config object
const CONFIG = { ...require(`conf/global.json`), ...envConfig };
// Set global timeout value for making backend requests
const TIMEOUT = CONFIG.baker_api.request_timeout ? CONFIG.baker_api.request_timeout: 10000;

/**
 * The function `getArticle` determines whether to use "a" or "an" before a given word based on its
 * initial sound.
 * @param word - The provided code is a function that determines whether to use "a" or "an"
 * before a given word based on English grammar rules. The function takes a word as input and returns
 * "an" if the word starts with a vowel sound or a special case, otherwise it returns "a".
 * @returns either 'an' or 'a' based on whether the input word should be preceded by "an" or "a" in
 * English grammar.
 */
export function getArticle(word) {
    let article = 'a';
    // Check if the input is a string
    if (word || typeof word === 'string') {
        // Convert the word to lower case to simplify checks
        const lowerCaseWord = word.toLowerCase();

        // List of vowels
        const vowels = ['a', 'e', 'i', 'o', 'u'];

        // Special cases for words that start with a vowel sound but not a vowel letter
        const specialCases = ['honest', 'hour', 'honor', 'heir', 'herb'];
        const specialCaseRegex = new RegExp(`^(${specialCases.join('|')})`);

        if (vowels.includes(lowerCaseWord[0]) || specialCaseRegex.test(lowerCaseWord)) {
            article = 'an';
        }
    }

    return article;
}

/**
 * The function `sanitizeTags` removes special characters and spaces from a tag and optionally converts
 * it to lowercase.
 * @param tag - The `tag` parameter is the input string that you want to sanitize. It will remove
 * special characters and spaces from the input string.
 * @param [lowerCase=true] - The `lowerCase` parameter in the `sanitizeTags` function is a boolean
 * parameter that determines whether the tag should be converted to lowercase or not. If `lowerCase` is
 * set to `true`, the tag will be converted to lowercase; if set to `false`, the tag will remain in
 * @returns The `sanitizeTags` function returns the sanitized version of the input `tag`. The function
 * removes special characters and spaces (non-alphanumeric characters) from the tag and replaces them
 * with an empty string. If the `lowerCase` parameter is set to `true`, the function also converts the
 * tag to lowercase before returning the sanitized tag.
 */
export function sanitizeTags(tag, lowerCase = true) {
    // Remove special characters and spaces (non-alphanumeric), replace them with empty string
    let sanTag = tag.replace(/[^\w]+/g, '');
    // Convert the tag to lowercase if enabled
    if (lowerCase) sanTag = sanTag.toLowerCase();
    
    return sanTag;
}

/**
 * The function `formatAvatarUrl` appends the `updatedTs` parameter to the `avatarUrl` if it contains
 * "sj-profile-pics".
 * @param avatarUrl - The `formatAvatarUrl` function takes two parameters: `avatarUrl` and `updatedTs`.
 * The `avatarUrl` parameter is a string that represents the URL of an avatar image. The `updatedTs`
 * parameter is a timestamp that indicates when the avatar image was last updated.
 * @param updatedTs - The `updatedTs` parameter is a timestamp that represents the last time the avatar
 * URL was updated. This timestamp is used to ensure that the avatar image is always up to date and not
 * cached by the browser or any other caching mechanism.
 * @returns The function `formatAvatarUrl` returns the `finalUrl` which is the original `avatarUrl` if
 * it does not include "sj-profile-pics", or the `avatarUrl` appended with "?updated_ts=" and the
 * `updatedTs` value if the `avatarUrl` includes "sj-profile-pics".
 */
export function formatAvatarUrl(avatarUrl, updatedTs) {
    let finalUrl = avatarUrl;
    if (avatarUrl) {
		if (avatarUrl.includes("sj-profile-pics")) {
			finalUrl = avatarUrl + "?updated_ts=" + updatedTs;
		}
	}
    return finalUrl;
}

/**
 * This function returns the configuration object for the environment.
 * @returns The function `getEnvConfig()` is returning the value of the variable `CONFIG`.
 */
export function getEnvConfig() { return CONFIG; }

/**
 * The function `verifyEmail` uses a regular expression to validate email addresses.
 * @param email - The `verifyEmail` function takes an email address as a parameter and uses a regular
 * expression to validate whether the email address is in a correct format. The regular expression
 * `^[^\s@]+@[^\s@]+\.[^\s@]+$` checks if the email address has the following format:
 * @returns The `verifyEmail` function returns a boolean value indicating whether the provided email
 * address matches the regular expression pattern for a valid email address.
 */
export function verifyEmail(email) {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the regular expression
    return emailRegex.test(email);
}

/**
 * The function `reformatToUsDate` takes a date string in the format "YYYY-MM-DD" and returns it in the
 * format "MM-DD-YYYY".
 * @param dateString - It looks like the `reformatToUsDate` function you provided is meant to convert a
 * date string from the format "YYYY-MM-DD" to "MM-DD-YYYY". If you have a specific `dateString` that
 * you would like to convert, please provide it so I can demonstrate how
 * @returns The function `reformatToUsDate` returns a reformatted date string in the format
 * "MM-DD-YYYY".
 */
export function reformatToUsDate(dateString) {
    // Parse the input date string
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    // Construct the new date string with the desired format
    const reformattedDate = `${month}-${day}-${year}`;

    return reformattedDate;
}

/**
 * The function `convertDateToEpoch` converts various date formats to epoch time in JavaScript.
 * @param dateString - The `convertDateToEpoch` function you provided is designed to convert various
 * date formats to epoch time. The function checks the input `dateString` against different date
 * patterns and converts it to epoch time if it matches any of the specified formats.
 * @returns The function `convertDateToEpoch` returns the epoch time (number of milliseconds since
 * January 1, 1970) corresponding to the input `dateString` if it matches one of the specified date
 * formats. If the `dateString` does not match any of the formats, the function returns `null`.
 */
export function convertDateToEpoch(dateString) {
    // Check if dateString matches the yyyy-MM-dd format
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (isoDatePattern.test(dateString)) {
        return Date.parse(dateString);
    }

    // Check if dateString matches the yyyy-MM-ddTHH:mm:ss.SSSZ format
    const isoDateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (isoDateTimePattern.test(dateString)) {
        return Date.parse(dateString);
    }

    // Check if dateString matches the Month name Day name, Year HH:mm:ss format
    const longDateFormat = /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4} \d{2}:\d{2}:\d{2}$/;
    if (longDateFormat.test(dateString)) {
        const dateObj = new Date(dateString);
        return dateObj.getTime();
    }

    return null;
}

/**
 * The function `convertEpochToDate` converts an epoch time to a specified date format.
 * @param epochTime - Epoch time is the number of seconds that have elapsed since January 1, 1970. It
 * is commonly used in computing to represent dates and times.
 * @param formatType - The `formatType` parameter in the `convertEpochToDate` function determines how
 * the epoch time should be converted to a date string. The function supports different format types
 * for the output date string. The available format types are:
 * @returns The function `convertEpochToDate` takes an epoch time and a format type as input parameters
 * and returns a formatted date string based on the specified format type. The return value depends on
 * the format type provided:
 */
export function convertEpochToDate(epochTime, formatType) {
    const date = new Date(epochTime);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let monthName = null;
    // let dayName = null;

    switch (formatType) {
        case 'basic_date_month_first':
            // Format: MM-dd-yyyy
            return `${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}-${date.getFullYear()}`;
        case 'iso_date':
            // Format: yyyy-MM-ddTHH:mm:ss.SSSZ
            return date.toISOString();
        case 'short_verbose_date':
            // Format: Month name Day name, Year (e.g. August 1, 2022)
            monthName = months[date.getMonth()];
            //dayName = days[date.getDay()];
            return `${monthName} ${date.getDate()}, ${date.getFullYear()}`;
        case 'verbose_date':
            // Format: Month name Day name, Year HH:mm:ss (e.g. August 1, 2022 12:00:00)
            monthName = months[date.getMonth()];
            // dayName = days[date.getDay()];
            return `${monthName} ${date.getDate()}, ${date.getFullYear()} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
        default:
            // Format: yyyy-MM-dd
            return date.toISOString().split('T')[0];
    }
}

/**
 * The function `padZero` adds a leading zero to a number if it is less than 10.
 * @param num - The `num` parameter in the `padZero` function is the number that we want to pad with a
 * zero if it is less than 10.
 * @returns The function `padZero` returns a string with a leading zero if the input number is less
 * than 10, otherwise it returns the input number as is.
 */
function padZero(num) {
    return num < 10 ? '0' + num : num;
}

/**
 * The function `epochToDateTime` converts a Unix epoch time to a readable date and time format in
 * JavaScript.
 * @param epochTime - The `epochTime` parameter in the `epochToDateTime` function represents the number
 * of seconds that have elapsed since January 1, 1970 (Unix epoch time). This function converts the
 * epoch time to a human-readable date and time format.
 * @param [isIncludeTime=false] - The `isIncludeTime` parameter is a boolean flag that determines
 * whether the time should be included in the output date and time format. By default, if this
 * parameter is not provided or set to `false`, the time will not be included in the output. If set to
 * `true`, the time
 * @returns The function `epochToDateTime` returns a readable date and time format in the form of
 * "Month Day, Year Hour:Minutes:Seconds" if the `isIncludeTime` parameter is set to `true`, and in the
 * form of "Month Day, Year" if `isIncludeTime` is `false`.
 */
export function epochToDateTime(epochTime, isIncludeTime=false) {
    // Initialize the time string
    let timeStr = '';

    // Create a new Date object with the epoch time in milliseconds
    const date = new Date(epochTime * 1000);
    // Get the month name
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    const month = monthNames[date.getMonth()];
    // Get the day of the month
    const day = date.getDate();
    // Get the year
    const year = date.getFullYear();

    if (isIncludeTime) {
        // Get the hour
        const hour = date.getHours();
        // Get the minutes
        const minutes = date.getMinutes();
        // Get the seconds
        const seconds = date.getSeconds();
        // Construct the time string
        timeStr = ` ${hour}:${minutes}:${seconds}`;
    }

    // Construct the readable date and time format
    const readableDateTime = `${month} ${day}, ${year}${timeStr}`;

    return readableDateTime;
}

/**
 * The function `callAPI` is an asynchronous function that makes a request to a Baker API endpoint
 * with a specified URI, request options, and request timeout, and returns the response, data, and
 * error.
 * @param uri - The `url` parameter is the endpoint or path of the API that you want to call. It
 * specifies the specific resource or action you want to perform on the API.
 * @param requestOptions - The `requestOptions` parameter is an object that contains options for the
 * HTTP request. It can include properties such as `method` (the HTTP method to use), `headers` (the
 * headers to include in the request), `body` (the request body), etc. These options are used when
 * @param reqTimeout - The `reqTimeout` parameter is the timeout duration in milliseconds for the
 * request to the Baker API. If this parameter is not specified, it will default to the value of
 * `TIMEOUT` from the configuration.
 * @returns an object with three properties: "response", "data", and "error".
 */
export async function callAPI(url, requestOptions, reqTimeout=15000) {
    let error = null;
    let response = null;
    let data = null;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), reqTimeout);

    // Generate UUID only if X-Trace-ID header is not present
    if (!requestOptions.headers || !('X-Trace-Id' in requestOptions.headers)) {
        const traceID = uuidv4();
        
        // Add X-Trace-ID header
        if (!requestOptions.headers) {
            requestOptions.headers = {};
        }
        requestOptions.headers['X-Trace-Id'] = traceID;
    }

    // Add timeout controller
    requestOptions.signal = controller.signal;

    try {
        response = await fetch(url, requestOptions);   
        data = await response.json();
    } catch (err) {
        error = err;
    } finally { clearTimeout(timeoutId); }

    return { response, data, error };
}

/**
 * The function `getParamValURL` is a JavaScript function that retrieves the value(s) of a specified
 * query parameter from a given URL.
 * @param pName - The `pName` parameter is a string that represents the name of the query parameter you
 * want to retrieve from the URL.
 * @param url - The `url` parameter is a string that represents the URL from which you want to extract
 * the parameter value.
 * @returns The function `getParamValURL` returns the value(s) of the specified parameter (`pName`) in
 * the given URL. If the parameter exists in the URL, it returns an array of all its values. If the
 * parameter does not exist, it returns `null`.
 */
export function getParamValURL(pName, url) {
    let pVals = null;
    if (url) {
        // Get the current URL and parse the 'pName' query string
        const u = new URL(url);
        const searchParams = u.searchParams;
        if (pName !== null) {
            if (searchParams.has(pName)) {
                pVals = searchParams.getAll(pName);
            }
        } else {
            // Get all params
            pVals = [];
            for (const [key, value] of searchParams) {
                pVals.push({ key, value });
            }
        }
    }
    return pVals;
}

/**
 * The function sets the value of a parameter in a URL and returns the updated URL.
 * @param pName - The `pName` parameter represents the name of the parameter you want to set or update
 * in the URL.
 * @param val - The `val` parameter represents the value that you want to set for the specified
 * parameter in the URL.
 * @param url - The `url` parameter is a string that represents a URL. It is the URL where you want to
 * set the parameter value.
 * @returns the updated URL with the specified parameter name and value.
 */
export function setParamValURL(pName, val, url) {
    let newUrl = null;
    if (url) {
        const u = new URL(url);
        const searchParams = u.searchParams;
        searchParams.set(pName, val);
        newUrl = u.href;
    }
    return newUrl;
}

/**
 * The `convertToMarkdown` function takes in a text and converts it to Markdown format by replacing
 * URLs with Markdown links and converting lines starting with '- ' to Markdown list items.
 * @param text - The `text` parameter is a string that represents the input text that you want to
 * convert to Markdown format.
 * @returns The function `convertToMarkdown` returns the input `text` converted to Markdown format.
 */
export function convertToMarkdown(text) {
    const regex = /\[(https?|http):\/\/[^\)]+\]\((https?|http):\/\/[^\)]+\)/s;
    // Exclude matches of the specified regular expression
    const excludedMarkDownLinks = text.replace(regex, '');

    // Use Linkify to find URLs
    const links = find(excludedMarkDownLinks);
    // Replace each URL with Markdown format
    let markdownText = text;
    
    links.forEach(link => {
        markdownText = markdownText.replace(link.value, `[${link.value}](${link.value})`);
    });
  
    // Convert lines that start with '- ' to Markdown list items
    markdownText = markdownText.replace(/^- /gm, '* ');
    // ... add more rules like for bold, italics, etc.
    return markdownText;
}

/**
 * The `removeMarkdown` function is a JavaScript function that removes Markdown formatting from a given
 * string, with support for removing URLs specifically.
 * @param mdStr - The `mdStr` parameter is a string that represents the markdown text that you want to
 * remove. It can contain various markdown elements such as headers, lists, links, etc.
 * @param [mdType] - The `mdType` parameter is used to specify the type of markdown to remove. By
 * default, it is set to `String`, which means it will remove all markdown formatting from the input
 * string.
 * @returns the modified `mdStr` string after removing the markdown.
 */
export function removeMarkdown(mdStr, mdType=String) {
   
    // TODO: Support multiple types by accepting a list of mdType
    if (mdType.toLowerCase() === 'url') {
        // Regular expression to match the URL mark down pattern
        // Example: [https://temple.edu](https://temple.edu)
        const urlRegex = /\[([^\]]+)\]\((http(s)?:\/\/[^\)]+)\)/g;

        // Function to replace URLs in the string
        function replaceUrls(match, text, url) { return url; }
        
        // Replace URLs using the replace function with a callback
        mdStr = mdStr.replace(urlRegex, replaceUrls);
    }

    return mdStr;
}

/**
 * The `markdownToPlainText` function takes a markdown string as input and returns a plain text version
 * of it by removing markdown syntax such as bold, italics, images, and links.
 * @param md - The `md` parameter is a string that represents markdown text.
 * @returns The function `markdownToPlainText` returns the plain text version of the given markdown
 * string.
 */
export function markdownToPlainText(md) {
    // Use regex to replace markdown syntax with plain text
    return md.replace(/(?:\r\n|\r|\n)/g, ' ')  // Replace line breaks with spaces
            .replace(/\*\*(.*?)\*\*/g, '$1')  // Remove bold (**text**)
            .replace(/\*(.*?)\*/g, '$1')      // Remove italics (*text*)
            .replace(/!\[.*?\]\((.*?)\)/g, '$1') // Replace images with their URL
            .replace(/\[.*?\]\((.*?)\)/g, '$1')  // Replace links with their URL
}

/**
 * The function calculates the effective length of a text by counting the number of characters and
 * adjusting for the number of newline characters.
 * @param text - The `text` parameter is the input string for which you want to calculate the effective
 * length. It can be any string of characters, including letters, numbers, symbols, and whitespace.
 * @param [newlineCharCount=30] - The `newlineCharCount` parameter represents the number of characters
 * used to represent a newline in the text. By default, it is set to 30.
 * @returns the effective length of the text.
 */
export function calculateEffectiveLength (text, newlineCharCount=30) {
    // Ignores any markdown and counts the number of characters in the text
    const newLineCount = (text.match(/\n/g) || []).length;
    return text.length + newLineCount * (newlineCharCount - 1);
};

/**
 * The `limitContent` function limits the length of a string by the maximum number of characters and
 * the maximum number of lines.
 * @param content - The `content` parameter is the text that needs to be limited. It can be a string
 * containing multiple lines of text.
 * @param maxChars - The `maxChars` parameter is the maximum number of characters allowed in the
 * content.
 * @param maxLines - The `maxLines` parameter specifies the maximum number of lines that the content
 * should be limited to.
 * @returns the truncated content, which is a string with a maximum number of characters and lines.
 */
export function limitContent (content, maxChars, maxLines) {
    // A hard limiter for the user-typed content in each field
    if (typeof content !== 'string') return content;

    const lines = content.split('\n');
    let truncatedLines = lines.slice(0, maxLines);
    
    // Count each newline as a character to enforce maxChars
    let totalLengthWithNewLines = content.length + (truncatedLines.length - 1) * 30;

    if (totalLengthWithNewLines > maxChars) {
        let charsToRemove = totalLengthWithNewLines - maxChars;
        // Remove characters from the last line until we meet maxChars
        const lastLineIndex = truncatedLines.length - 1;
        truncatedLines[lastLineIndex] = truncatedLines[lastLineIndex].slice(0, -charsToRemove);
    }

    return truncatedLines.join('\n');
};

/* The `export function getCurrentDateFormatted` is a JavaScript function that returns the current date
in a formatted string. It uses the `Date` object to get the current date, and then formats it as
'Month Day, Year' using an array of month names. The function uses an arrow function syntax to
define the function. The `export` keyword indicates that this function can be imported and used in
other modules. */
export function getCurrentDateFormatted() {
    const currentDate = new Date();
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    const month = monthNames[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    return `${month} ${day}, ${year}`;
};

/**
 * The `sanitizeData` function removes any undefined values from an object.
 * @param data - The `data` parameter is an object that contains key-value pairs.
 * @returns the sanitized data, which is an object containing only the key-value pairs from the input
 * data object that have a defined value.
 */
export function sanitizeData(data) {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) {
            sanitizedData[key] = data[key];
        }
    });
    return sanitizedData;
}

/**
 * The function `sendEmail` is an asynchronous function that sends an email using a POST request to a
 * specified URL and returns an object with information about the success of the request, the response,
 * and any errors encountered.
 * @param data - The `data` parameter is an object that contains the necessary information for sending
 * an email. It could include properties such as the recipient's email address, the subject of the
 * email, the body content, and any other relevant details needed for the email.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function sendEmail(data, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ 'data': data })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/send-email`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The `resizeImage` function takes an image file as input, resizes it to a specified maximum width and
 * height, and returns a Promise that resolves to the resized image file.
 * @param file - The `file` parameter represents the image file that needs to be resized. It is passed
 * to the `resizeImage` function as an argument.
 * @returns a Promise that resolves to a resized File object.
 */
export async function resizeImage(file) {
    return new Promise((resolve) => {
        const maxWidth = CONFIG.image.max_width ? CONFIG.image.max_width : 500;
        const maxHeight = CONFIG.image.max_height ? CONFIG.image.max_height : 500;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
    
            image.onload = () => {
                let newWidth, newHeight;
        
                if (image.width > image.height) {
                    newWidth = maxWidth;
                    newHeight = (image.height / image.width) * maxWidth;
                } else {
                    newHeight = maxHeight;
                    newWidth = (image.width / image.height) * maxHeight;
                }
        
                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, newWidth, newHeight);
        
                 // Convert the canvas content to a Blob
                 canvas.toBlob((blob) => {
                    // Create a File object from the Blob
                    const resizedFileData = new File([blob], file.name, { type: file.type });
                    resolve(resizedFileData);
                }, file.type);
            };
        };
    
        if (file) {
            reader.readAsDataURL(file);
        }
    });
};