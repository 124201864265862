import React, { Component } from 'react'
import hai from 'images/hai-profile.jpg'
import christian from 'images/christian-iosif-profile.jpg'
import brandon from 'images/brandon-tran-profile.jpeg'
import './SimpleCards.scss'
import 'layout/index.scss'
import Icon from 'icons/icon';

export default class SimpleCards extends Component {
	constructor(props) {
        super(props);
        this.state = {
            header: this.props.header,
            subHeader: this.props.subHeader,
            tallestModuleHeight: 0,
			christian: {
				christianId: "b1f55fa4-8158-4fbd-935c-e43b63ecf2d6",
				christianLinkedIn: "https://www.linkedin.com/in/christian-iosif-217015152/",
			},
			hai: {
				haiId: "987f997a-eb6d-4f0a-b5b9-090c91f9021e",
				haiLinkedIn: "https://www.linkedin.com/in/haithai/"
			},
			brandon: {
				brandonId: "d4f0f5d0-c570-4289-ab8e-c398bf4e5eb8",
				brandonLinkedIn: "https://www.linkedin.com/in/brandontran1/"
			}
		};
    }

    componentDidMount() {
        this.adjustModuleHeight();
        window.addEventListener("resize", this.adjustModuleHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.adjustModuleHeight);
    }

    adjustModuleHeight = () => {
        let tallestModuleHeight = 0;
        const modules = document.getElementsByClassName("staff-entry");
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = "auto";
            if (modules[i].offsetHeight > tallestModuleHeight) {
                tallestModuleHeight = modules[i].offsetHeight;
            }
        }
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = `${tallestModuleHeight}px`;
        }
        this.setState({ tallestModuleHeight });
    }

	handleProfileClick = (profileId) => {
		window.open('/profile/' + profileId);
	}
	
	handleSocialsClick	= (social) => {
		window.open(social);
	}

    render() {
        return (
            <div className="row">
				<div className="container">
					<div className="simple-card col-md-3 text-center animate-box">
						<div className="staff-entry semi-clickable" onClick={() => this.handleProfileClick(this.state.christian.christianId)}>
							<div className='staff-content-wrapper'>
								<a className="staff-img" style={{backgroundImage: `url(${christian})`}}></a>
								<div className="desc">
									<h3>Christian Iosif</h3>
									<span>Founder & CEO</span>
									<Icon color="#5D9898"  size={30} icon={"linkedin"} onClick={(e) => {e.stopPropagation(); this.handleSocialsClick(this.state.christian.christianLinkedIn)}}/>
									<div>Growing up in an immigrant household, I experienced firsthand the struggles of navigating new systems and building connections from scratch. It was my college mentor who paved my path to becoming a software engineer. Now, equipped with these skills, I'm on a mission to empower early-stage entrepreneurs to solve the problems they care about.</div>
								</div>
							</div>
						</div>
					</div>
					<div className="simple-card col-md-3 text-center animate-box">
						<div className="staff-entry semi-clickable" onClick={() => this.handleProfileClick(this.state.hai.haiId)}>
							<div className='staff-content-wrapper'>
								<a className="staff-img" style={{backgroundImage: `url(${hai})`}}></a>
								<div className="desc">
									<h3>Hai Thai</h3>
									<span>CTO</span>
									<Icon color="#5D9898"  size={30} icon={"linkedin"} onClick={(e) => {e.stopPropagation(); this.handleSocialsClick(this.state.hai.haiLinkedIn)}}/>
									<div>A passionate technology/people leader and mentor with over 20 years of tech industry experience, also the founder of <a href="https://projecttechcycle.org">Project TechCycle (projecttechcycle.org)</a>, an organization that focuses on reducing electronic waste and bridging the digital divide which has economic and social impact on underserved communities. I am dedicated to empowering the next generation of innovators through technology, social impact, and environmental sustainability.</div>
								</div>
							</div>
						</div>
					</div>
					<div className="simple-card col-md-3 text-center animate-box">
						<div className="staff-entry semi-clickable" onClick={() => this.handleProfileClick(this.state.brandon.brandonId)}>
							<div className='staff-content-wrapper'>
								<a className="staff-img" style={{backgroundImage: `url(${brandon})`}}></a> 
								<div className="desc">
									<h3>Brandon Tran</h3>
									<span>COO</span>
									<Icon color="#5D9898"  size={30} icon={"linkedin"} onClick={(e) => {e.stopPropagation(); this.handleSocialsClick(this.state.brandon.brandonLinkedIn)}}/>
									<div>Brandon has over a decade of experience across CPG/FMCG Imports/Exports, Telecommunications and Life Sciences; delivering value within start ups and Fortune 50 companies. He is passionate about leveraging technology to connect, nurture and expand human connections.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}