import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Form, Alert, InputGroup} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import './SignInForm.scss';
import { GoogleLogin } from '@react-oauth/google';
// Library for LinkedIn OAuth2: https://github.com/nvh95/react-linkedin-login-oauth2
import { LinkedIn } from 'react-linkedin-login-oauth2';
import AuthContext from 'app/AuthContext';
import { verifyCaptcha, verifyGoogleUserCred, verifyNativeUserCred, verifyLinkedInUserCred } from 'lib/user';
import { sendEventToAnalytics } from 'lib/reporting';
import { verifyEmail, getEnvConfig } from 'lib/utils';

const SignInForm = (props) => {

    // Load the global configuration
    const globalConfig = getEnvConfig();

    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [ errors, setErrors ] = useState({});

    // Form inputs
    const [state, setState] = useState({email: '', password: ''});
    // Toggles the viewing of the password that the user typed in
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // State to manage the visibility of the signup form
    const [showSignInForm, setShowSignInForm] = useState(false);

    // Function to toggle the visibility of the signup form
    const toggleSigninForm = () => {
        setShowSignInForm(!showSignInForm);
    };

    // Using React Context to manage JWT for user sessions
    const { login } = useContext(AuthContext);

    // ReCAPTCHA
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = useRef();

    //Resizing the Google Login Button
    const orDividerRef = useRef(null);
    const [authButtonWidth, setGoogleButtonWidth] = useState(0);
    
    const promptSignUpForm = () => {
        if (props.handleSignUpClick) { 
            if (props.handleCloseModal) { props.handleCloseModal(); }
            props.handleSignUpClick(); 
        }
    };

    useEffect(() => {
        const updateWidth = () => {
            if (orDividerRef.current) {
                setGoogleButtonWidth(orDividerRef.current.getBoundingClientRect().width);
            }
        };
        // Initial width calculation
        updateWidth();
        // Update width on window resize
        window.addEventListener("resize", updateWidth);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    const promptResetPwdForm = () => {
        props.handleCloseModal();
        props.resetPwdClick();
	};

    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    /**
     * The function `findFormErrors` checks for errors in the email and password inputs and returns an
     * object containing any errors found.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any errors
     * found in the email and password inputs.
     */
    const findFormErrors = () => {
        const email = state.email;
        const password = state.password;
        const newErrors = {};
       
        if (!email || email === '') { 
            newErrors.email = 'Please enter your email.';
        } else if (email.length > 320) { 
            newErrors.email = 'Email is too long.';
        } else if (verifyEmail(email) === false) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if ( !password || password === '' ) newErrors.password = 'Cannot be blank.';
        else if ( password.length > 128 ) newErrors.password = 'Password is too long.';
    
        return newErrors;
    }

    /**
     * The function `signInNativeUser` is an asynchronous function that handles the Log-in process for
     * a native user, including verifying the user's credentials, logging in the user, and displaying
     * any errors that occur.
     * @param email - The email parameter is the email address of the user trying to Log in.
     * @param password - The `password` parameter is the password entered by the user for
     * authentication.
     */
    async function signInNativeUser(email, password) {

        if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "native_user_sign_in"); }
            
        try {
            const { isSuccess: signInSuccess, resp: signInResponse, err: signInErr } = await verifyNativeUserCred(email, password);

            if (signInSuccess) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Log In || Success: ", signInResponse); }
                props.onActionCompleted();
                // Log in user
                login(signInResponse.results.token, signInResponse.results.user_metadata);
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Log In || Error: ", signInResponse, signInErr); }
                if (signInResponse) {
                    if (signInResponse.message !== null) { 
                        if (signInResponse.status_code === "UNAUTHORIZED") {
                            displayError("Username/password is incorrect.");
                        } else if (signInResponse.status_code === "NOT_FOUND") {
                            displayError("This account has not signed up with us.");
                        } else if (signInResponse.status_code === "FORBIDDEN") {
                            displayError("This account may have not been verified and activated yet. Please review your email for the activation message from us, containing instructions on how to verify and activate this account.");
                        } else {
                            displayError(signInResponse.message); 
                        }
                    } else {
                        displayError("An unexpected error occurred, please try again later."); 
                    }
                } else {
                    displayError("An unexpected error occurred, please try again later."); 
                }
            }
        } catch (error) {
            displayError("Fatal error, please check back shortly.");
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error resetting the password:", error); }
        }
    }
    
    /**
     * The function `signInGoogleUser` is an asynchronous function that handles the sign-in process for
     * a user using Google credentials, verifying the credentials, logging in the user, and sending an
     * event to analytics if the sign-in is successful.
     * @param response - The `response` parameter is an object that contains information about the
     * Google user's authentication response. It likely includes the user's credentials and other
     * relevant data.
     */
    async function signInGoogleUser(response) {
        try {
            // Verify google credentials first
            const { isSuccess: signInSuccess, resp: signInResponse, err: signInErr } = await verifyGoogleUserCred(response.credential);

            if (signInSuccess) {
                props.onActionCompleted();
                // Log in user
                login(signInResponse.results.token, signInResponse.results.user_metadata);

                if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
                    console.log("User Log In || Success:", signInResponse); 
                } else if (process.env.REACT_APP_ENVIRONMENT === "prod") { 
                    await sendEventToAnalytics("User", "google_user_sign_in"); 
                }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Log In || Error:", signInResponse, signInErr); }

                if (signInResponse) { 
                    if (signInResponse.status_code === "UNAUTHORIZED") {
                        displayError("The account is not authorized.");
                    } else if (signInResponse.status_code === "NOT_FOUND") {
                        displayError("The account has not signed up with us.");
                    } else {
                        displayError(signInResponse.message); 
                    }
                } else {
                    displayError("An unexpected error occurred, please try again later."); 
                }
            }
        } catch (error) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Log In || Error:", error); }
            displayError("An unexpected error occurred, please try again later.");
        }
    }

    async function signInLinkedInUser(code) {
        try {
            // Verify google credentials first
            const { isSuccess: signInSuccess, resp: signInResponse, err: signInErr } = await verifyLinkedInUserCred(code);

            if (signInSuccess) {
                props.onActionCompleted();
                // Log in user
                login(signInResponse.results.token, signInResponse.results.user_metadata);

                if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
                    console.log("LinkedIn User Log In || Success:", signInResponse); 
                } else if (process.env.REACT_APP_ENVIRONMENT === "prod") { 
                    await sendEventToAnalytics("User", "linked_user_sign_in"); 
                }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("LinkedIn User Log In || Error:", signInResponse, signInErr); }

                if (signInResponse) { 
                    if (signInResponse.status_code === "UNAUTHORIZED") {
                        displayError("The account is not authorized.");
                    } else if (signInResponse.status_code === "NOT_FOUND") {
                        displayError("The account has not signed up with us.");
                    } else {
                        displayError(signInResponse.message); 
                    }
                } else {
                    displayError("An unexpected error occurred, please try again later."); 
                }
            }
        } catch (error) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Log In || Error:", error); }
            displayError("An unexpected error occurred, please try again later.");
        }
    }

    /**
     * The function `onFormSubmit` is an asynchronous function that handles form submission, validates
     * form inputs, executes reCAPTCHA, verifies the reCAPTCHA response, and signs in the user if all
     * validations pass.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * form submission. It contains information about the event, such as the target element, the type
     * of event, and any additional data associated with the event. In this case, the `event` parameter
     * is used to prevent the default
     */
    async function onFormSubmit(event) {
        // Prevent the default form submission
        event.preventDefault();
        const newErrors = findFormErrors();

        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
        } else {
            try {
                // Execute reCaptcha
                await refCaptcha.current.callbacks.execute();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Processed site key."); }
                const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
                // Verify captcha
                const { isSuccess: captchaSuccess, resp: captchaResponse, err: captchaErr } = await verifyCaptcha(reCaptchatoken);

                if (captchaSuccess) {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Authenticated the user."); }
                    signInNativeUser(state.email, state.password);
                } else {
                    displayError("ReCAPTCHA verification failed. Please try again later.");
                    console.error("ReCAPTCHA response came back with error:", captchaResponse, captchaErr);
                }
            } catch (error) {
                displayError("A fatal error has occurred, please try again later.");
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Login || Error:", error); }
            }
        }
    };

  return (
    <Form onSubmit={onFormSubmit} className="default-form-format">

        <GoogleLogin
            onSuccess={credentialResponse => {
                signInGoogleUser(credentialResponse);
            }}
            onError={() => {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log('Login Failed'); }
            }}
            size="large"
            width={authButtonWidth}
        />

        <LinkedIn
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            redirectUri={`${window.location.origin}${globalConfig.linkedin.api.redirect_path_signin}`}
            scope={globalConfig.linkedin.api.scope}
            onSuccess={(code) => {
                signInLinkedInUser(code);
            }}
            onError={(error) => {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                    if (error) {
                        if (error.error !== "user_closed_popup" || error.error !== "user_cancelled_login") {
                            console.log('LinkedIn message info', error);
                        }
                    }
                }
            }}
            >
            {({ linkedInLogin }) => (
                <div className='form-like-button' 
                    onClick={linkedInLogin}
                    style={{width: authButtonWidth}}
                >
                    <div className='icon linkedin'/>
                    <span >Log in with LinkedIn</span>
                </div>
            )}
        </LinkedIn>
        <div className="or-divider" ref={orDividerRef}>OR</div>
        {!showSignInForm && (<div className='form-like-button' onClick={toggleSigninForm}>
                <div className='icon generic-email'/>
                <span>Use your email to log in</span>
            </div>)}
        {showSignInForm && (
            <div className='custom-signin-form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control 
                        name="email"  
                        type="email" 
                        placeholder="Email"
                        onChange={(e) => {
                            setState({ ...state, email: e.target.value });
                            setErrors({ ...errors, email: null });
                        }}
                        value={state.email}
                        isInvalid={ !!errors.email}/>
                    <Form.Control.Feedback type='invalid'>
                        { errors.email }
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <InputGroup className='contains-password-toggle'>
                        <Form.Control
                            name="password"
                            placeholder="Password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setState({ ...state, password: e.target.value });
                                setErrors({ ...errors, password: null });
                            }}
                            value={state.password}
                            isInvalid={!!errors.password}
                        />
                        <InputGroup.Append className='contains-password-toggle'>
                            <InputGroup.Text
                            className="password-toggle"
                            onClick={togglePasswordVisibility}>
                                {showPassword ? <BsEyeSlash/> : <BsEye/>}
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className='reset-password'><a style={{cursor:'pointer'}} onClick={promptResetPwdForm}>Forgot your password?</a></div>
                <Button variant="primary" type='submit'>Log In</Button>
            </div> 
        )}
        <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
        <div className="divider"/>
        <div className='signup-message'>
            Don't have an account?
            <a className='signup-link' onClick={promptSignUpForm}>Sign up</a>
        </div>

        <p></p>
        <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>

        <Recaptcha 
            sitekey={reCaptchaKey}
            ref={refCaptcha}
            onResolved={() => {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
            }}
            onError={() => {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
            }}
        />
    </Form>
  );
};

export default SignInForm;
