import { React, useEffect, useState } from 'react';
import { PageHeader } from 'components';
import { FormModal, ChangePasswordForm } from 'sub-components';
import { Layout } from 'layout';
import { getParamValURL } from 'lib/utils';
import { isPwdTokenValid } from 'lib/user';

const ResetPasswordPage = () => {

    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isChangePwdToggleOn, setChangePwdToggleOn] = useState(false);
    const [isErrorMsgToggleOn, setErrorPwdToggleOn] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ title: '', msg: ''});
    const [userFName, setUserFName] = useState('');

    const changePwdCompleted = () => {
		setActionCompleted(true);
	}

    const changePwdClick = () => {
		setChangePwdToggleOn(!isChangePwdToggleOn);
		setActionCompleted(false);
	};

    const errorClick = () => {
		setErrorPwdToggleOn(!isErrorMsgToggleOn);
		setActionCompleted(false);
	};

    /**
     * The function `verifyToken` checks if a password reset token is valid and opens a form modal if
     * it is, otherwise it displays an error message.
     * @param pwdResetTokenId - The pwdResetTokenId is the unique identifier for the password reset
     * token. It is used to retrieve the token from the database and verify its validity.
     * @param pwdResetToken - The `pwdResetToken` parameter is a token that is generated when a user
     * requests to reset their password. This token is sent to the user's email address and is used to
     * verify the authenticity of the password reset request.
     */
    async function verifyToken(pwdResetTokenId, pwdResetToken, fName) {
        const { isValid: isTokenValid, resp: isTokenResp, err: isTokenErr } = await isPwdTokenValid(pwdResetTokenId, pwdResetToken);
        // Verify token validity
        if (isTokenValid) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Valid token || Success:", isTokenResp); }
            // Close the error modal
            setErrorPwdToggleOn(false);
            // Pop up the form modal
            setChangePwdToggleOn(true);
        } else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Token error:", isTokenResp, isTokenErr); }
            // Close the change password form modal
            setChangePwdToggleOn(false);
            errorMsg.title = "Invalid or Expired Request";
            errorMsg.msg = `Hello ${fName}! Your password reset request is no longer valid or has expired. Please request another password reset.`;
            // Pop up the error modal
            setErrorPwdToggleOn(true);
            setActionCompleted(true);
        }
    }

    /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
    `useEffect` hook is used to check if the URL or href contains the string "password-reset". If it
    does, it retrieves the password reset token, token ID, and user's first name from the URL
    parameters using the `getParamValURL` function. If the token and token ID exist, call the backend service 
    to verify the token first. If valid, pop up the actual change password form. */
    useEffect(() => {
		// Check if the URL or href contains "password-reset"
		if (window.location.href.includes("token")) {
			let pwdResetToken = getParamValURL("token", window.location.href);
            let pwdResetTokenId = getParamValURL("id", window.location.href);
            let fName = getParamValURL("name", window.location.href);

            if (fName !== null && fName.length > 0) { fName = fName[0]; setUserFName(fName); }
			if (pwdResetToken !== null && pwdResetToken.length > 0) { pwdResetToken = pwdResetToken[0]; }
			if (pwdResetTokenId !== null && pwdResetTokenId.length > 0) { pwdResetTokenId = pwdResetTokenId[0]; }

            if (pwdResetToken && pwdResetTokenId) {
                verifyToken(pwdResetTokenId, pwdResetToken, fName);
            } else {
                setChangePwdToggleOn(false);
                errorMsg.title = "Missing Data";
                errorMsg.msg = `Hello ${fName}! Some required data is missing in the reset password link.`;
                setActionCompleted(true);
                setErrorPwdToggleOn(true);
            }
		}
	}, []); // Empty dependency array means this effect runs once after the initial render

    return (
        <Layout tabHeader="PasswordReset">
            <div className='colored-background'>
                <PageHeader header={"Password Reset"} subHeader={"Please reset your password."} />
            </div>
            <FormModal
                isToggleOn={isErrorMsgToggleOn}
                handleClick={errorClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={errorMsg.title}
                successfulActionTitle={errorMsg.title}
                successfulActionMessage={
                    <div>
                        {errorMsg.msg}
                    </div>
                }
                >
            </FormModal>
            <FormModal
                isToggleOn={isChangePwdToggleOn}
                handleClick={changePwdClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={"Hello " + userFName + "! Please enter your new password"}
                successfulActionTitle="Success!"
                successfulActionMessage={
                    <div>
                        Success! Please log in with your new password.
                    </div>
                }
                >
                <ChangePasswordForm changePwdClick={changePwdClick} onActionCompleted={changePwdCompleted} />
            </FormModal>
        </Layout>
    );
};

export default ResetPasswordPage;
