import { callAPI, getEnvConfig, sanitizeTags } from "./utils";

const CONFIG = getEnvConfig();
// Set global timeout value for making backend requests
const TIMEOUT = CONFIG.baker_api.request_timeout ? CONFIG.baker_api.request_timeout: 10000;


/**
 * The function `calculateProfileCompletion` calculates the completion percentage of a user's profile
 * based on specific criteria.
 * @param profile - The `calculateProfileCompletion` function calculates the completion percentage of a
 * user's profile based on the provided `profile` object. The `profile` object should contain
 * information about the user's profile properties such as `display_name`, `education`, `experience`,
 * `f_name`, `l_name`, `
 * @returns The function `calculateProfileCompletion` returns the completion percentage of a user's
 * profile based on the provided `profile` object. The completion percentage is calculated by checking
 * the presence of required properties and specific types of tags in the `profile` object and then
 * dividing the number of completed criteria by the total number of required criteria. The function
 * ensures that the completion percentage does not exceed 100% and returns the
 */
export function calculateProfileCompletion(profile) {
    const requiredProperties = ['display_name', 'education', 'experience', 'f_name', 'l_name', 'intro_summary', 'portfolios'];
    const requiredTagTypes = ['skill', 'interest', 'objective', 'contrib', 'expertise'];
    const totalCriteria = requiredProperties.length + requiredTagTypes.length; // Total number of required properties
    let completedCriteria = 0;

    // Check required properties
    requiredProperties.forEach(prop => {
        if (profile[prop] && profile[prop] !== null && profile[prop] !== '') {
            if (Array.isArray(profile[prop])) {
                if (profile[prop].length > 0) {
                    completedCriteria++;
                }
            } else {
                completedCriteria++;
            }
        }
    });

    // Check 'tags' property for specific types
    if (profile.tags && profile.tags.length > 0) {
        requiredTagTypes.forEach(type => {
            const tagType = profile.tags.find(tag => tag.type === type);
            if (tagType && tagType.categories && tagType.categories.length > 0) {
                completedCriteria++;
            }
        });
    }

    // Calculate completion percentage
    const completionPercentage = Math.floor((completedCriteria / totalCriteria) * 100);
    return Math.min(completionPercentage, 100); // Ensure max 100%
}

/**
 * The function `updateProfileTags` updates a list of tags by adding or removing new data based on
 * specified tag type and category.
 * @param tags - The `tags` parameter in the `updateProfileTags` function is an array of objects
 * representing different tag types and categories associated with each tag type. Each tag object has a
 * `type` property representing the tag type, and a `categories` property which is an array of objects
 * representing different categories associated
 * @param newData - `newData` is an object containing information about a new tag to be added or
 * updated. It includes properties like `category`, `id`, and `display_name`.
 * @param tagType - The `tagType` parameter in the `updateProfileTags` function is used to specify the
 * type of tag that you want to update or add. It is a string that represents the type of tag, such as
 * "location", "interest", "skill", etc. The function will look for existing
 * @param [isRemoveExistingTags=false] - The `isRemoveExistingTags` parameter in the
 * `updateProfileTags` function is a boolean parameter that determines whether existing tags should be
 * removed before adding new tags. If `isRemoveExistingTags` is set to `true`, all existing tags will
 * be removed and only the new tag specified in the
 * @returns The function `updateProfileTags` returns an updated array of tags based on the input
 * parameters and conditions provided in the function.
 */
export function updateProfileTags(tags, newData, tagType, isRemoveExistingTags=false) {
    let tagTypeExists = false;
    let newCategories = [];

    const updatedTags = tags.map(tag => {
        if (tag.type?.toLowerCase() === tagType?.toLowerCase()) {
            tagTypeExists = true;
            let categoryExists = false;
            newCategories = tag.categories.map(category => {
                if (category.category?.toLowerCase() === newData.category?.toLowerCase()) {
                    categoryExists = true;

                    if (isRemoveExistingTags) {
                        // Remove all tags and add the new one if specified
                        return {
                            ...category,
                            tags: newData ? [newData] : []
                        };
                    } else {
                        const typeExists = category.tags.some(t => t.id?.toLowerCase() === newData.id?.toLowerCase());
                        if (!typeExists) {
                            return {
                                ...category,
                                tags: [...category.tags, newData].sort((a, b) => a?.display_name?.localeCompare(b?.display_name))
                            };
                        }
                    }
                }
                return category;
            });

            if (!categoryExists) {
                newCategories.push({
                    category: newData.category,
                    tags: [newData]
                });
            }

            return {
                ...tag,
                categories: newCategories
            };
        }
        return tag;
    });

    if (!tagTypeExists) {
        // Create a new tag with the specified type and category
        updatedTags.push({
            type: tagType,
            categories: [{
                category: newData.category,
                tags: [newData]
            }]
        });
    }

    return updatedTags;
}

/**
 * The function `prepareTagsPayload` reformats a given data structure into a new structure organized by
 * type, category, and tags.
 * @param data - The `prepareTagsPayload` function takes an array of `data` as input. Each element in
 * the `data` array represents a tag object with properties `type` and `categories`. The `type`
 * property specifies the type of the tag, and the `categories` property is an array of
 * @returns The function `prepareTagsPayload` returns a reformatted object where each key represents a
 * tag type and the value is an array of objects containing categories and their corresponding tags
 * with their id and display name.
 */
export function prepareTagsPayload(data) {
    const reformattedTags = {};

    data.forEach(tag => {
        if (tag && tag.type && tag.categories) {
            const type = tag.type;
            const categories = tag.categories;

            // Ensure the reformattedTags object has an array for each type
            if (!reformattedTags[type]) {
                reformattedTags[type] = [];
            }

            // Add each category and its tags to the corresponding type array
            categories.forEach(category => {
                reformattedTags[type].push({
                    category: category.category,
                    tags: category.tags.map(tag => ({
                        id: tag.id,
                        display_name: tag.display_name
                    }))
                });
            });
        }
    });

    return reformattedTags;
}

/**
 * The function `getTitleAndGoal` extracts the title and goal from a given array of tags based on
 * specific tag types.
 * @param tags - The `getTitleAndGoal` function takes an array of `tags` as input. Each tag in the
 * array has a `type` property which can be either 'contrib' or 'objective'. If the tag type is
 * 'contrib', it contains a `display_name` property that represents the title.
 * @returns The `getTitleAndGoal` function returns an object with two properties: `title` and `goal`.
 * The `title` property contains the display name of the first tag of type 'contrib' in the provided
 * `tags` array. The `goal` property contains the display name of the first tag of type 'objective' in
 * the provided `tags` array, converted to lowercase.
 */
export function getTitleAndGoal(tags) {
    let title = '';
    let goal = '';

    if (tags) {
        tags.forEach(tag => {
            if (tag.type === 'contrib') {
                // For future use
                // const contribTag = tag.categories[0].tags[0];
                // title = `<a href="/tags/v=${contribTag.id}">${contribTag.display_name}</a>`;
                title = tag.categories[0].tags[0].display_name;
            }
            if (tag.type === 'objective') {
                goal = tag.categories[0].tags[0].display_name.toLowerCase();
            }
        });
    }

    return { title, goal };
}

export function getTitleAndGoalObj(tags) {
    let title = {};
    let goal = {};

    if (tags) {
        tags.forEach(tag => {
            if (tag.type === 'contrib') {
                title = tag.categories[0].tags[0];  // Assign the whole object
            }
            if (tag.type === 'objective') {
                goal = tag.categories[0].tags[0];  // Assign the whole object
            }
        });
    }

    return { title, goal };
}

/**
 * The function `processUserProfileData` converts the portfolio string in the input data to JSON
 * format, at least for now.
 * @param data - The `processUserProfileData` function takes in a `data` object as a parameter. This
 * `data` object likely contains user profile information, including a field called `portfolios` that
 * is expected to be a string representation of a JSON object. The function attempts to parse the
 * `portfolios`
 * @returns The function `processUserProfileData` is returning the `data` object after processing it.
 * The function checks if the `data` object has a property called `portfolios`, then it tries to parse
 * the value of `data.portfolios` from a string to JSON format. If parsing is successful, the parsed
 * JSON object is assigned back to `data.portfolios`. If parsing fails (due to invalid
 */
export function processUserProfileData(data) {
    // Convert portfolio string to json
    if (data.portfolios) {
        try {
            data.portfolios = JSON.parse(data.portfolios);
        } catch (err) {
            data.portfolios = [];
        }
    }

    return data;
}

/**
 * The function `updateUserMetadata` updates the user metadata stored in local storage by adding or
 * updating a specific property with the provided data.
 * @param data - The `data` parameter is the value that you want to update the `objToUpdate` property
 * with in the `userMetadata` object.
 * @param objToUpdate - The `objToUpdate` parameter is a string that represents the property name in
 * the `userMetadata` object that you want to update. For example, if you want to update the
 * `avatar_url` property, you would pass `'avatar_url'` as the value for `objToUpdate`.
 */
export function updateUserMetadata (data, objToUpdate) {
    // Fetch the current userMetadata from local storage
    let userMetadata = localStorage.getItem('userMetadata');

    // Parse it into a JavaScript object
    if (userMetadata) {
        userMetadata = JSON.parse(userMetadata);
    } else {
        userMetadata = {};  // or some default structure if it doesn't exist yet
    }

    // Add the url to the avatar_url property
    userMetadata[objToUpdate] =  data;
    // userMetadata.user_avatar = data;

    // Stringify the updated object and save it back to local storage
    localStorage.setItem('userMetadata', JSON.stringify(userMetadata));
}

/**
 * The function `isTokenValid` checks if a given token is valid by making a GET request to a
 * specified URL and returns a boolean value indicating the validity of the token.
 * @param tokenId - The `tokenId` parameter is the ID of the token that you want to check for
 * validity. It is used in the URL to identify the specific token.
 * @param token - The `token` parameter is a string that represents a token used for password
 * reset.
 * @returns the value of the variable `isValid`, which is a boolean indicating whether the token is
 * valid or not.
 */
export async function isPwdTokenValid(tokenId, token, reqTimeout=TIMEOUT) {
    let isValid = false;
   
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/password-reset?token=${token}&id=${tokenId}`, requestOptions, reqTimeout);
    if (res && res.ok) { isValid = true; }

    return { isValid, resp, err };
};

/**
 * The function `changeUserPassword` is an asynchronous function that sends a PATCH request to the
 * Baker API to change a user's password, using the provided old password, new password, token ID, and
 * token.
 * @param [oldPassword=null] - The old password of the user that needs to be changed.
 * @param [newPassword=null] - The `newPassword` parameter is the new password that the user wants to
 * set.
 * @param [tokenId=null] - The `tokenId` parameter is the unique identifier for the user whose password
 * is being changed. It is used to identify the user in the password reset process.
 * @param [token=null] - The `token` parameter is a token that is used for authentication or
 * authorization purposes. It is typically generated by the server and sent to the client as a response
 * to a previous request. In this case, it is used as a query parameter in the URL for the password
 * reset API endpoint.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function changeUserPassword(oldPassword=null, newPassword=null, tokenId=null, token=null, reqTimeout=TIMEOUT) {
    let isSuccess = false;
  
    // Create a FormData object to send the file
    let formData = new FormData();
    formData.append('old_password', oldPassword);
    formData.append('new_password', newPassword);
    
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/password-reset?token=${token}&id=${tokenId}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The `activateUser` function is used to activate a user account by making a PATCH request to the
 * Baker API with a token and token ID.
 * @param [tokenId=null] - The tokenId parameter is used to specify the user's token ID for activation.
 * @param [token=null] - The `token` parameter is a string that represents the activation token for the
 * user. It is used to verify the user's identity during the activation process.
 * @param reqTimeout - The `reqTimeout` parameter is the timeout duration for the API request to the
 * Baker API. If it is not specified, it will default to the value specified in the
 * `BAKER_API_CONF.request_timeout` constant.
 * @returns an object with three properties: isSuccess, resp, and err.
 */
export async function activateUser(tokenId=null, token=null, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json'
        }
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/acct-activation?token=${token}&id=${tokenId}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
* The function `sendResetPwdLink` sends a password reset link to the specified email address using
* a POST request with form data.
* @param email - The email parameter is the email address of the user who wants to reset their
* password.
*/
export async function sendResetPwdLink(email, reqTimeout=TIMEOUT) {
    let isSuccess = false;
 
    // Create a FormData object to send the file
    let formData = new FormData();
    formData.append('email', email);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/password-reset-init`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `verifyCaptcha` is an asynchronous function that sends a reCaptcha token to a backend
 * server for verification and returns whether the verification was successful, the response from the
 * server, and any error that occurred during the process.
 * @param reCaptchatoken - The reCaptchatoken parameter is a token generated by the reCAPTCHA service.
 * It is used to verify that the user is not a robot.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function verifyCaptcha(reCaptchatoken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
 
    // Get the reCaptcha token and create a request to our backend to request for the suspicion score
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ response: reCaptchatoken })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/captcha-verification`, requestOptions, reqTimeout);
    if (res && res.ok) { 
        isSuccess = true; 
    } else {
        if (resp.success === true && resp.score >= 0.5) { isSuccess = true; }
    }

    return { isSuccess, resp, err };
};

/**
 * The function `verifyGoogleLogin` is an asynchronous function that sends the ID token to a backend
 * server for verification and further processing, and returns an object with the properties
 * `isSuccess`, `resp`, and `error`.
 * @param response - The `response` parameter is the response object returned by the Google Sign-In API
 * after a successful login. It contains information about the user and their authentication
 * credentials, including the ID token.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function verifyGoogleUserCred(idToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
  
    // Send the ID token to your backend for verification and further processing
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id_token: idToken })
    };
  
    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/google-auth/login`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `signUpGoogleUser` sends an ID token and a tag to the backend for verification and
 * further processing, and returns an object with a success flag, response data, and an error if any.
 * @param idToken - The `idToken` parameter is a token that is generated by Google after a user
 * successfully signs in with their Google account. This token is used to authenticate the user on the
 * server-side and verify their identity.
 * @param tag - The 'tag' parameter is a value that you can pass to the backend along with the ID
 * token. It can be used to provide additional information or context about the user signing up with
 * Google. The backend can then use this tag for further processing or to differentiate between
 * different types of sign-ups.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function createGoogleUser(idToken, tag, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    // Send the ID token to your backend for verification and further processing
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            'data': {
                'id_token': idToken, 
                'tag':  tag
            }
        })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/google-auth/signup?is_login_user=true`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `verifyLinkedInUserCred` sends an ID token to a backend for verification and
 * processing, returning a success status along with response data or an error.
 * @param idToken - The `idToken` parameter in the `verifyLinkedInUserCred` function is the token that
 * represents the identity of the user trying to authenticate via LinkedIn. This token is sent to the
 * backend for verification and further processing to authenticate the user.
 * @param [reqTimeout] - The `reqTimeout` parameter in the `verifyLinkedInUserCred` function is used to
 * specify the timeout duration for the API request. It determines how long the function will wait for
 * a response from the API before timing out. If a response is not received within the specified
 * `reqTimeout` period
 * @returns The function `verifyLinkedInUserCred` returns an object with three properties:
 * 1. `isSuccess`: A boolean value indicating whether the verification and processing were successful.
 * 2. `resp`: The response data received from the API call.
 * 3. `err`: Any error that occurred during the API call or processing.
 */
export async function verifyLinkedInUserCred(idToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
  
    // Send the ID token to your backend for verification and further processing
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id_token: idToken })
    };
  
    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/linkedin-auth/login`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `createLinkedInUser` sends an ID token to a backend for verification and processing,
 * returning a success status along with response data or error.
 * @param idToken - The `idToken` parameter in the `createLinkedInUser` function is typically a token
 * obtained from the LinkedIn authentication process. This token serves as a proof of authentication
 * and is sent to the backend for verification and further processing.
 * @param tag - The `tag` parameter in the `createLinkedInUser` function is a value that you provide to
 * identify or categorize the user being created or processed. It is included in the data sent to the
 * backend along with the ID token for further processing.
 * @param [reqTimeout] - The `reqTimeout` parameter in the `createLinkedInUser` function is used to
 * specify the timeout duration for the API request. If the request takes longer than the specified
 * `reqTimeout` value (in milliseconds) to complete, it will be considered as timed out. This allows
 * you to control how
 * @returns The function `createLinkedInUser` returns an object with three properties:
 * 1. `isSuccess`: A boolean value indicating whether the operation was successful or not.
 * 2. `resp`: The response data from the API call.
 * 3. `err`: Any error that occurred during the API call.
 */
export async function createLinkedInUser(idToken, tag, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    // Send the ID token to your backend for verification and further processing
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            'data': {
                'id_token': idToken, 
                'tag':  tag
            }
        })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/linkedin-auth/signup?is_login_user=true`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `verifyNativeUserCred` is an asynchronous function that sends a POST request to a
 * specified URL with the provided email and password, and returns an object containing a boolean
 * indicating success, the response data, and any error that occurred.
 * @param email - The email parameter is a string that represents the user's email address. It is used
 * to identify the user during the login process.
 * @param password - The password parameter is the user's password that they are trying to verify.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function verifyNativeUserCred(email, password, reqTimeout=TIMEOUT) {
    let isSuccess = false;
  
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'data': {
                'email': email,
                'password': password
            }
        })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/native-auth/login`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `createNativeUser` is an asynchronous function that sends a PUT request to a specified
 * URL with user information and returns a response indicating whether the request was successful, the
 * response data, and any errors encountered.
 * @param firstName - The first name of the user you want to create.
 * @param lastName - The `lastName` parameter is a string that represents the last name of the user.
 * @param email - The email parameter is the email address of the user that you want to create.
 * @param password - The `password` parameter is the password for the user account being created.
 * @param tag - The 'tag' parameter is a string that represents a tag or label associated with the
 * user. It can be used to categorize or group users based on certain criteria. Currently used for MailChimp tagging
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function createNativeUser(firstName, lastName, email, password, tag, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'data': {
                'first_name' : firstName,
                'last_name' : lastName,
                // Combine first and last name if both are not empty. Or Use either one that is not emptuy
                //'display_name': (firstName && lastName) ? `${firstName} ${lastName}` : (firstName || lastName || null),
                'email' : email,
                'password' : password,
                'tag' : tag
            }
         })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/native-auth/signup`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `changeUserProfileAvatar` is an asynchronous function that takes in parameters such as
 * `userId`, `avatarId`, `file`, and `authToken` to update a user's profile avatar by sending a POST
 * request to a specified URL with the provided data.
 * @param userId - The userId parameter is the unique identifier of the user whose profile avatar is
 * being changed.
 * @param avatarId - The `avatarId` parameter is the ID of the avatar that the user wants to change to.
 * It is an optional parameter, so it can be undefined or null if the user does not want to change the
 * avatar.
 * @param file - The `file` parameter is the file object that contains the avatar image to be uploaded.
 * It should be a valid image file.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to change the user's profile avatar.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function changeUserProfileAvatar(userId, avatarId, file, authToken, updateProgress, reqTimeout = TIMEOUT) {
    let isSuccess = false;

    // Create a FormData object to send the file
    let formData = new FormData();
    formData.append('file', file);
    // Add avatar ID if exists
    if (typeof avatarId !== 'undefined' && avatarId) {
        formData.append('avatar_id', avatarId);
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: formData
    };

    // Simulate progress
    if (updateProgress) {
        let progress = 0;
        const progressInterval = setInterval(() => {
            progress += 10;
            updateProgress({ lengthComputable: true, loaded: progress, total: 100 });
            if (progress >= 90) {
                clearInterval(progressInterval);
            }
        }, 100);
    }

    const { response: res, data: resp, error: err } =
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/profile/avatar`, requestOptions, reqTimeout);
    
    if (res && res.ok) {
        isSuccess = true;
    }

    return { isSuccess, resp, err };
}

// export async function changeUserProfileAvatar(userId, avatarId, file, authToken, reqTimeout=TIMEOUT) {
//     let isSuccess = false;
    
//     // Create a FormData object to send the file
//     let formData = new FormData();
//     formData.append('file', file);
//     // Add avatar ID if exists
//     if (typeof avatarId != 'undefined' && avatarId) { formData.append('avatar_id', avatarId); }

//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${authToken}`
//         },
//         body: formData
//     };

//     const { response: res, data: resp, error: err } = 
//         await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/profile/avatar`, requestOptions, reqTimeout);
//     if (res && res.ok) { isSuccess = true; }

//     return { isSuccess, resp, err };
// };

/**
 * The function `updateUserProfile` is an asynchronous function that updates a user's profile by
 * sending a PATCH request to a specified API endpoint with the provided user ID, data, and
 * authentication token.
 * @param userId - The userId parameter is the unique identifier of the user whose profile is being
 * updated.
 * @param data - The `data` parameter is an object that contains the updated user profile information.
 * It could include properties such as name, email, address, phone number, etc.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to update the user's profile.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function updateUserProfile(userId, data, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;

    // Prepare payload for tags
    data.tags = prepareTagsPayload(data.tags);
    // Temporay fix for removing tags has null value
    // TODO: Fix the and fronend and backend to handle null values
    delete data.tags['null'];

    if (data.portfolios.length > 0) {
        // Convert portfolio object to string
        data.portfolios = JSON.stringify(data.portfolios);
    } else {
        // Remove portfolio if empty
        delete data.portfolios;
    }

    // Format and update user's display name
    if (data.first_name && data.last_name) {
        // Combine first and last name if both are not empty. Or Use either one that is not empty
        data.display_name = `${data.first_name} ${data.last_name}`;
    }
    
    // Update user's objective and contribution tags
    if (data.title && data.goal) {
        data.tags.contrib = [
            {
                "category": "Contribution",
                "tags": [
                    {
                        "id": sanitizeTags(data.title),
                        "display_name": data.title
                    }
                ]
            }
        ]

        data.tags.objective = [
            {
                "category": "Objective",
                "tags": [
                    {
                        "id": sanitizeTags(data.goal),
                        "display_name": data.goal
                    }
                ]
            }
        ]
    }

    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 'data': data })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/profile`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
}

/**
 * The function `getUserProfile` is an asynchronous function that retrieves a user's profile using
 * their user ID, options, and authentication token.
 * @param userId - The `userId` parameter is the unique identifier of the user whose profile you want
 * to retrieve.
 * @param options - The `options` parameter is an object that contains additional query parameters for
 * the API request. These parameters can be used to customize the response or filter the data in some
 * way. The keys of the `options` object represent the query parameter names, and the values represent
 * the corresponding values for those parameters.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to access the user's profile information.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function getUserProfile(userId, options, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
    let queryString = '';

    // Build the query string of options
    if (options != null && Object.keys(options).length > 0) {
        // Expect an array key/value, example: [{'details': 'true', {'tokens': 'true'}]
        // queryString = Object.keys(options).map(key => key + '=' + options[key]).join('&');

        // Expect an array key/value, example: [{'key': 'details', 'value': 'true'}, {'key': 'tokens', 'value': 'true}]
        queryString = '?' + options.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/profile${queryString}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `getUserProjects` is an asynchronous function that retrieves a user's projects based on
 * their user ID, options, and authentication token.
 * @param userId - The userId parameter is the unique identifier of the user whose projects you want to
 * retrieve.
 * @param options - The `options` parameter is an object that contains additional query parameters for
 * the API request. It can include any key-value pairs that are supported by the API endpoint you are
 * calling. The keys represent the query parameter names, and the values represent the corresponding
 * values for those parameters.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to access the user's projects.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function getUserProjects(userId, options, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
    let queryString = '';
    
    // Build the query string of options
    if (options != null && Object.keys(options).length > 0) {
        // Expect an array key/value, example: [{'key': 'details', 'value': 'true'}, {'key': 'tokens', 'value': 'true'}]
        queryString = '?' + options.map(item => `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`).join('&');
    }
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/projects${queryString}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }
    
    return { isSuccess, resp, err };
};

/**
 * The function `createUserProject` is an asynchronous function that sends a PUT request to create a
 * user project with the provided user ID, data, and authentication token, and returns an object with
 * the success status, response data, and error (if any).
 * @param userId - The userId parameter is the unique identifier of the user for whom the project is
 * being created.
 * @param data - The `data` parameter is an object that contains the information needed to create a
 * user project. It could include properties such as project name, description, start date, end date,
 * etc. The specific properties and their values would depend on the requirements of your application.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to create a user project.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function createUserProject(userId, data, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
 
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 'data': data })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/projects`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `updateUserProject` is an asynchronous function that updates a user's project with the
 * provided data using a PATCH request.
 * @param userId - The userId parameter is the unique identifier of the user whose project is being
 * updated.
 * @param projectId - The `projectId` parameter is the unique identifier of the project that you want
 * to update for a specific user.
 * @param data - The `data` parameter is an object that contains the updated information for the user
 * project. It should be in the following format:
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the user's request to update their project.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function updateUserProject(userId, projectId, data, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 'data': data })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/projects/${projectId}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `deleteUserProject` is an asynchronous function that sends a DELETE request to a
 * specific user's project endpoint with the provided user ID, project ID, and authentication
 * token, and returns an object containing information about the success of the request, the response,
 * and any errors encountered.
 * @param userId - The userId parameter is the unique identifier of the user whose project needs to be
 * deleted.
 * @param projectId - The `projectId` parameter is the unique identifier of the project that you want
 * to delete.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * for the user. It is used to authorize the request to delete a user project.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function deleteUserProject(userId, projectId, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
   
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/users/${userId}/projects/${projectId}`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `applyUserProject` sends a POST request to apply a user project with the provided
 * project ID, data, and authentication token, and returns an object with the success status, response
 * data, and error (if any).
 * @param projectId - The projectId parameter is the unique identifier of the project to which the user
 * wants to apply.
 * @param data - The `data` parameter is an object that contains the data to be sent in the request
 * body. It will be converted to JSON format using `JSON.stringify()` before sending the request.
 * @param authToken - The `authToken` parameter is a string that represents the authentication token
 * required to access the API. It is used to authorize the user making the request and ensure that they
 * have the necessary permissions to apply the project.
 * @returns an object with three properties: isSuccess, resp, and error.
 */
export async function applyUserProject(projectId, data, authToken, reqTimeout=TIMEOUT) {
    let isSuccess = false;
  
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ 'data': data })
    };

    const { response: res, data: resp, error: err } = 
        await callAPI(`${CONFIG.baker_api.root_url}/projects/${projectId}/apply`, requestOptions, reqTimeout);
    if (res && res.ok) { isSuccess = true; }

    return { isSuccess, resp, err };
};

/**
 * The function `getDefaultUserGoals` returns the user goals sorted alphabetically by label.
 * @returns The `getDefaultUserGoals` function returns the `CONFIG.user_goals` array sorted
 * alphabetically by the `label` property in ascending order.
 */
export function getDefaultUserGoals() {
    return CONFIG.user_goals.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}

/**
 * The function `getDefaultUserStartUpPast` returns the user start up past sorted alphabetically by label.
 * @returns The `getDefaultUserStartUpPast` function returns the `CONFIG.user_startuppast` array sorted
 * alphabetically by the `label` property in ascending order.
 */
export function getDefaultUserStartUpPast() {
    return CONFIG.user_startuppast.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}

/**
 * The function `getDefaultUserTimeCommit` returns the user time commits sorted alphabetically by label.
 * @returns The `getDefaultUserTimeCommit` function returns the `CONFIG.user_timecommit` array sorted
 * alphabetically by the `label` property in ascending order.
 */
export function getDefaultUserTimeCommit() {
    return CONFIG.user_timecommit.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}

/**
 * The function `getDefaultUserTitles` returns an array of user titles sorted alphabetically by label.
 * @returns The function `getDefaultUserTitles` returns the `CONFIG.user_titles` array sorted
 * alphabetically by the `label` property in uppercase.
 */
export function getDefaultUserTitles() {
    return CONFIG.user_titles.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();

        if (roleNameA === "UNIVERSITY STUDENT") return -1;
        if (roleNameB === "UNIVERSITY STUDENT") return 1;

        if (roleNameA < roleNameB) return -1;
        if (roleNameA > roleNameB) return 1;
        return 0;
    });
}

/**
 * The function `getDefaultUserSkills` returns the user skills sorted alphabetically by label.
 * @returns The `getDefaultUserSkills` function returns the `CONFIG.user_skills` array sorted
 * alphabetically by the `label` property in uppercase.
 */
export function getDefaultUserSkills() {
    return CONFIG.user_skills.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}

/**
 * The function `getDefaultUserExpertises` returns the user expertises sorted alphabetically by label.
 * @returns The function `getDefaultUserExpertises` returns the `CONFIG.user_expertises` array sorted
 * alphabetically by the `label` property in uppercase.
 */
export function getDefaultUserExpertises() {
    return CONFIG.user_expertises.sort((a, b) => {
        const roleNameA = a.label.toUpperCase();
        const roleNameB = b.label.toUpperCase();
        if (roleNameA < roleNameB) { return -1; }
        if (roleNameA > roleNameB) { return 1; }
        return 0;
    });
}

/**
 * The function `getDefaultUserInterests` sorts the `user_interests` array by label and returns the
 * sorted array.
 * @returns The function `getDefaultUserInterests()` returns the `user_interests` array sorted by the
 * `label` property in ascending order.
 */
export function getDefaultUserInterests() {
    // Sort the main user_interests array by label
    return CONFIG.user_interests.sort((a, b) => a.label.localeCompare(b.label));
}