import React, { useState, useEffect, useContext, useRef} from 'react'
import { Button, Form, Alert, InputGroup} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import './CreateAccountForm.scss';
import { GoogleLogin } from '@react-oauth/google';
// Library for LinkedIn OAuth2: https://github.com/nvh95/react-linkedin-login-oauth2
import { LinkedIn } from 'react-linkedin-login-oauth2';
import AuthContext from 'app/AuthContext';
import { sendEventToAnalytics } from 'lib/reporting';
import { createGoogleUser, verifyCaptcha, createNativeUser, createLinkedInUser } from 'lib/user';
import { verifyEmail, getEnvConfig } from 'lib/utils';

const CreateAccountForm = (props) => {
    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [errors, setErrors] = useState({});
    // Form inputs
    const [state, setState] = useState({firstName: '', lastName: '', email: '', password: ''});
    // Toggles the viewing of the password that the user typed in
    const [showPassword, setShowPassword] = useState(false);
    // State to manage the visibility of the signup form
    const [showSignupForm, setShowSignupForm] = useState(false);

    // Function to toggle the visibility of the password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Function to toggle the signup form visibility
    const toggleSignupForm = () => {
        setShowSignupForm(true); // Show the form and hide the button
    };

    // Load the global configuration
    const globalConfig = getEnvConfig();

    const promptSignInForm = () => {
        if (props.handleSignInClick) { 
            if (props.handleCloseModal) { props.handleCloseModal(); }
            props.handleSignInClick(); 
        }
    };

    // Using React Context to manage JWT for user sessions
    const { login } = useContext(AuthContext);

    // ReCAPTCHA
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = React.useRef();

    // Resizing the Google Login Button
    const orDividerRef = useRef(null);
    const [authButtonWidth, setAuthButtonWidth] = useState(0); 

    useEffect(() => {
        const updateWidth = () => {
            if (orDividerRef.current) {
                setAuthButtonWidth(orDividerRef.current.getBoundingClientRect().width);
            }
        };
        // Initial width calculation
        updateWidth();
        // Update width on window resize
        window.addEventListener("resize", updateWidth);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
        }, []);

    /**
     * The function "displayError" sets the alert text with a given message and shows the alert.
     * @param message - The error message that you want to display.
     */
    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    /**
     * The function `findFormErrors` checks for errors in a form's input fields and returns an object
     * containing any errors found.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any errors
     * found in the form inputs.
     */
    const findFormErrors = () => {
        const firstName = state.firstName;
        const lastName = state.lastName;
        const password = state.password;
        const confirmPassword = state.confirm_password;
        const email = state.email;
        const newErrors = {}
        // Input errors
        if ( !firstName || firstName === '' ) newErrors.firstName = 'Cannot be blank.'
        else if ( firstName.length > 40 ) firstName.firstName = 'Name is too long.'
        if ( !lastName || lastName === '' ) newErrors.lastName = 'Cannot be blank.'
        else if ( lastName.length > 40 ) lastName.lastName = 'Name is too long.'

        if (!email || email === '') { 
            newErrors.email = 'Please enter your email.';
        } else if (email.length > 320) { 
            newErrors.email = 'Email is too long.';
        } else if (verifyEmail(email) === false) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if ( !password || password === '' ) newErrors.password = 'Provide a password.'
        else if ( password.length > 128 ) newErrors.password = 'Password is too long.'
        if ( password !== confirmPassword ) newErrors.confirm_password = 'Passwords do not match.';
        else if ( !confirmPassword || confirmPassword === '' ) newErrors.confirm_password = 'Cannot be blank.';
        else if ( confirmPassword.length > 128 ) newErrors.confirm_password = 'Password is too long.';
    
        return newErrors
    }

    /**
     * The function `signUpNativeUser` creates a native user account with the provided information,
     * checks if the user has opted in for the newsletter, and logs in the user if the account creation
     * is successful.
     * @param firstName - The first name of the user signing up.
     * @param lastName - The `lastName` parameter is the last name of the user signing up for the
     * native account.
     * @param email - The email parameter is the email address of the user who is signing up.
     * @param password - The `password` parameter is the password that the user enters when signing up
     * for a native account.
     */
    async function signUpNativeUser(firstName, lastName, email, password) {
        var tag = "Occasional";
        // Check if the user is signing up for the newsletter
        if (document.getElementById('newletter-opt-in').checked) { tag = 'Regular'; }

        // Create the account with google account info
        const { isSuccess: createUserSuccess, resp: createUserResp, err: createUserErr } = await createNativeUser(firstName, lastName, email, password, tag);

        // If user creation succeeds, prompt a message to check an email for activating the account
        if (createUserSuccess) {
            props.onActionCompleted("signup_native_user");
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
                console.log("Native User Creation || Success: ", createUserResp); 
            } else if (process.env.REACT_APP_ENVIRONMENT === "prod") { 
                await sendEventToAnalytics("User", "native_user_sign_up"); 
            }
        } else {
            if (createUserResp) {
                 // User already signed up
                if (createUserResp.status_code === "FORBIDDEN") {
                    displayError("This account has already signed up with us.");
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Native User Sign Up || Error: " + createUserResp, createUserErr); }
                } else {
                    displayError(createUserResp.message);
                }
            } else {
                displayError("An unexpected error occurred, please try again later.");
            }
        }
    }

    /**
     * The function `signUpGoogleUser` creates a user account using Google account information, logs in
     * the user, and handles any errors that occur during the process.
     * @param response - The `response` parameter is an object that contains the user's Google account
     * information, such as the credential used to authenticate the user.
     */
    async function signUpGoogleUser(response) {
        var tag = "Occasional";
        
        // Create the account with google account info
        const { isSuccess: createUserSuccess, resp: createUserResp, err: createUserErr } = await createGoogleUser(response.credential, tag);

        // If user creation succeeds, log in the user
        if (createUserSuccess) {
            // Log in the user
            login(createUserResp.results.token, createUserResp.results.user_metadata);
            props.onActionCompleted("signup_user");
            
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
                console.log("Google Account Creation || Success: ", createUserResp); 
            } else if (process.env.REACT_APP_ENVIRONMENT === "prod") { 
                await sendEventToAnalytics("User", "google_user_sign_up"); 
            }
        } else {
            if (createUserResp) {
                // Google user already signed up
                if (createUserResp.status_code === "FORBIDDEN") {
                    displayError("This account has already signed up with us.");
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log("Google Sign Up || Error:", createUserResp, createUserErr);
                    }
                } else {
                    displayError(createUserResp.message);
                }
            } else {
                displayError("Unexpected error has occurred, please try again later.");
            }
        }
    }

    /**
     * The function `signUpLinkedInUser` handles the sign-up process for a user using LinkedIn
     * credentials, with optional newsletter subscription.
     * @param code - The `code` parameter in the `signUpLinkedInUser` function is typically the
     * authorization code obtained from LinkedIn after the user has authenticated and authorized your
     * application to access their LinkedIn profile information.
     */
    async function signUpLinkedInUser(code) {
        var tag = "Occasional";
       
        // Create the account with google account info
        const { isSuccess: createUserSuccess, resp: createUserResp, err: createUserErr } = await createLinkedInUser(code, tag);

        // If user creation succeeds, log in the user
        if (createUserSuccess) {
            login(createUserResp.results.token, createUserResp.results.user_metadata);
            props.onActionCompleted("signup_user");
            // Log in user
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
                console.log("LinkedIn User Sign Up and In || Success: ", createUserResp); 
            } else if (process.env.REACT_APP_ENVIRONMENT === "prod") { 
                await sendEventToAnalytics("User", "linkedin_user_sign_in"); 
            }
        } else {
            if (createUserResp) {
                // Google user already signed up
                if (createUserResp.status_code === "FORBIDDEN") {
                    displayError("This account has already signed up with us.");
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        console.log("LinkedIn Sign Up || Error:", createUserResp, createUserErr);
                    }
                } else {
                    displayError(createUserResp.message);
                }
            } else {
                displayError("Unexpected error has occurred, please try again later.");
            }
        }
    }

    /**
     * The above function is an event handler for a form submission that checks for form errors,
     * executes reCAPTCHA, and signs up a user if the reCAPTCHA is successful.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * form submission. It contains information about the event, such as the target element, the type
     * of event, and any additional data associated with the event. In this case, the `event` parameter
     * is used to prevent the default
     */
    async function onFormSubmit(event) {
        event.preventDefault();
        const newErrors = findFormErrors();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
        } else {
            try {
                // Execute reCaptcha
                await refCaptcha.current.callbacks.execute();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Processed site key."); }
                const reCaptchatoken = refCaptcha.current.callbacks.getResponse();
                // Verify captcha
                const { isSuccess: captchaSuccess, resp: captchaResponse, err: captchaErr } = await verifyCaptcha(reCaptchatoken);

                if (captchaSuccess) {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Authenticated the user."); }
                    signUpNativeUser(state.firstName, state.lastName, state.email, state.password);
                } else {
                    displayError("ReCAPTCHA verification failed. Please try again later.");
                    console.error("ReCAPTCHA response came back with error:", captchaResponse, captchaErr);
                }
            } catch (error) {
                displayError("Unexpected error occurred, please try again later.");
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Login || Error:", error); }
            }
        }
    }

    return (
        
        <Form onSubmit={onFormSubmit} className="default-form-format">
            <GoogleLogin
                onSuccess={credentialResponse => {
                    signUpGoogleUser(credentialResponse);
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log('Login Failed'); }
                }}
                size="large"
                width={authButtonWidth}
                text="signup_with"
            />
            <LinkedIn
                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                redirectUri={`${window.location.origin}${globalConfig.linkedin.api.redirect_path_signup}`}
                scope={globalConfig.linkedin.api.scope}
                onSuccess={(code) => {
                    signUpLinkedInUser(code);
                }}
                onError={(error) => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
                        if (error) {
                            if (error.error !== "user_closed_popup" || error.error !== "user_cancelled_login") {
                                console.log('LinkedIn message info', error);
                            }
                        }
                    }
                }}
                >
                {({ linkedInLogin }) => (
                    <div className='form-like-button' 
                        onClick={linkedInLogin}
                        style={{width: authButtonWidth}}
                    >
                        <div className='icon linkedin'/>
                        <span>Sign up with LinkedIn</span>
                    </div>
                )}
            </LinkedIn>
            <div className="or-divider" ref={orDividerRef}>OR</div>
            {!showSignupForm && (<div className='form-like-button' onClick={toggleSignupForm}>
                <div className='icon generic-email'/>
                <span>Use your email to sign up</span>
            </div>)}
            {showSignupForm && (
                <div className='custom-signup-form'>
                    <Form.Group controlId="formFirstName">
                        <Form.Control 
                            name="FirstName"
                            placeholder="First Name"
                            type="text" 
                            onChange={(e) => {
                                setState({ ...state, firstName: e.target.value });
                                setErrors({ ...errors, firstName: null });
                            }}
                            value={state.firstName}
                            isInvalid={ !!errors.firstName}/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.firstName }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                        <Form.Control 
                            name="LastName"
                            placeholder="Last Name"
                            type="text" 
                            onChange={(e) => {
                                setState({ ...state, lastName: e.target.value });
                                setErrors({ ...errors, lastName: null });
                            }}
                            value={state.lastName}
                            isInvalid={ !!errors.lastName}/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.lastName }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control 
                            name="email"  
                            type="email" 
                            placeholder="Email"
                            onChange={(e) => {
                                setState({ ...state, email: e.target.value });
                                setErrors({ ...errors, email: null });
                            }}
                            value={state.email}
                            isInvalid={ !!errors.email}/>
                        <Form.Control.Feedback type='invalid'>
                            { errors.email }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <InputGroup className='contains-password-toggle'>
                            <Form.Control
                            name="password"
                            placeholder="Password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setState({ ...state, password: e.target.value });
                                setErrors({ ...errors, password: null });
                            }}
                            value={state.password}
                            isInvalid={!!errors.password}
                            />
                            <InputGroup.Append className='contains-password-toggle'>
                                <InputGroup.Text
                                className="password-toggle"
                                onClick={togglePasswordVisibility}>
                                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                        <InputGroup className='contains-password-toggle'>
                            <Form.Control
                            name="confirm_password"
                            placeholder="Confirm Password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setState({ ...state, confirm_password: e.target.value });
                                setErrors({ ...errors, confirm_password: null });
                            }}
                            value={state.confirm_password}
                            isInvalid={!!errors.confirm_password}
                            />
                            <InputGroup.Append className='contains-password-toggle'>
                                <InputGroup.Text
                                className="password-toggle"
                                onClick={togglePasswordVisibility}>
                                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {errors.confirm_password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Check 
                        type='checkbox'
                        id="newletter-opt-in"
                        label="Send me email updates"
                    />
                    <Button variant="primary" style={{height: '50px'}} type='submit'>Create My Account</Button>
                </div>
            )}
            <div className='recaptcha-branding'>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
            </div>
            <div className="divider"/>
            <div className='signup-message'>
                Already have an account?
                <a className="signup-link" onClick={promptSignInForm}>Log in</a>
            </div>
            <p></p>
            <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>

            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    );
};

export default CreateAccountForm;
