// Create a new functional component called Events that will display a scrollable carrousel of images that will be passed to it as props, and are clickable to redirect to the specific event page.

import React from 'react';
import { Link } from 'react-router-dom';
import './Communities.scss';
import {SectionHeading} from 'components';
import pslIcon from 'images/psl-icon.png';
import pteIcon from 'images/pte-icon.png';
import fiIcon from 'images/fi-icon.png';
import vcIcon from 'images/vc-icon.png';

const Communities = (props) => {
    const pslUrl = "https://www.phillystartupleaders.org";
    const vcUrl = "https://venturecafephiladelphia.org";
    const pteUrl = "https://phillytechentrepreneurs.com";
    const fiUrl = "https://fi.co";
    
    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="gray-container">
            <SectionHeading color="2E4C4D" header="Find us in the Philly community."/>
            <div className='community-cards'>
                <div className='community-card'>
                    <img src={pslIcon} onClick={() => openNewTab(pslUrl)}/>
                </div>
                <div className='community-card'>
                    <img src={vcIcon} onClick={() => openNewTab(vcUrl)}/>
                </div>
                <div className='community-card'>
                    <img src={pteIcon} onClick={() => openNewTab(pteUrl)}/>
                </div>
                <div className='community-card'>
                    <img src={fiIcon} onClick={() => openNewTab(fiUrl)}/>
                </div>
            </div>
        </div>
    );
    };

export default Communities;