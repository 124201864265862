import React, { useState } from 'react';
import { Button } from 'react-bootstrap'
import {ContactForm} from 'sub-components'
import { Link } from 'react-router-dom';
import {FormModal} from 'sub-components'
import '../footer/Footer.scss'

const Footer = () => {
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setTimeout(() => {
            setIsToggleOn(false);
        }, 1000);
    }

    const handleClick = () => {
        setIsToggleOn(!isToggleOn);
        setActionCompleted(false);
    }

    return (
        <footer id="colorlib-footer">
            <FormModal 
                isToggleOn={isToggleOn} 
                handleClick={handleClick}
                actionCompleted={actionCompleted}
                title="Contact Us"
                successfulActionTitle="Message Sent"
                successfulActionMessage="Thanks for contacting us. We will respond via email.">
                <ContactForm onActionCompleted={handleActionCompleted} />
            </FormModal>
            <div className="container">
                <div className="row row-pb-md footer-columns">
                    <div className='column-1'>
                        <div className="colorlib-widget">
                            <h4>About sidejawn</h4>
                            <p className="about-paragraph">All businesses start with a side jawn. We are passionate in growing yours into a successful business by helping you find the right co-founders and partners</p>
                        </div>
                    </div>
                    <div className="column-2">
                        <div className="colorlib-widget">
                            <h4>Navigation</h4>
                            <ul className="colorlib-footer-links">
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/resources">Resources</Link></li>
                                <li><Link to="/discovery">Startups</Link></li>
                                <li><Link to="/venture-cafe">Communities</Link></li>
                            </ul>
                        </div>
                        <div className="colorlib-widget" id="footer-contact">
                            <h4>Contact Info</h4>
                            <ul className="colorlib-footer-links">
                                <li>Connect with the founder on <Link to="https://www.linkedin.com/in/christian-iosif-217015152/">LinkedIn</Link></li>
                                <li>Find us on <Link to="https://www.linkedin.com/company/sidejawn/">LinkedIn</Link></li>
                                <li>Follow us on <Link to="https://www.instagram.com/sidejawn.io/">Instagram</Link></li>
                            </ul>
                            <Button variant="primary" onClick={handleClick} centered="true">
                                Contact Us
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
