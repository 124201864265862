import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout } from 'layout';
import { Button, Alert, Form } from 'react-bootstrap';
import CreatableSelect, { components } from 'react-select';
import Select from 'react-select';
import blankprofileImage from 'images/blank-profile-picture.png'
import '../userProfile/UserProfile.scss'
import cameraIcon from 'images/icon-camera.png';
import AuthContext from 'app/AuthContext';
import { useLoadScript } from "@react-google-maps/api";
import { updateUserMetadata, changeUserProfileAvatar, getUserProfile, updateUserProfile, getDefaultUserGoals, getDefaultUserTitles, getDefaultUserSkills, getDefaultUserExpertises, getDefaultUserInterests, processUserProfileData, getTitleAndGoal, updateProfileTags } from 'lib/user';
import { sendEventToAnalytics } from 'lib/reporting';
import { convertToMarkdown, removeMarkdown, resizeImage, formatAvatarUrl } from 'lib/utils';
import { getUserContents, updateUserContents, getContentsByCategory, updateMetadataOpById } from 'lib/content';
import { v4 as uuidv4 } from 'uuid';
import { set } from 'lodash';

const libraries = ["places"];

const UserProfileEdit = () => {

    // Default items for the static options for dropdowns, etc.
    const defaultGoals = getDefaultUserGoals();
    const defaultSkills = getDefaultUserSkills();
    const defaultExpertises = getDefaultUserExpertises();
    const defaultInterests = getDefaultUserInterests();
    const defaultTitles = getDefaultUserTitles();

    // This is from the param in the URL
    const {userId} = useParams();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [profileAvatar, setProfileAvatar] = useState(profile.user_avatar ? formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts) : blankprofileImage);
    const {authToken, userMetadata, logout, forceRefresh} = useContext(AuthContext);
    const loggedInUserId = userMetadata.id;
    
    // This will hold the user's profile image
    const [loading, setLoading] = useState(false);
    const [failedLoading, setFailedLoading] = useState(false);

    // Editable inputs for the user profile
    const [firstName, setFirstName] = useState(profile.f_name || '');
    const [lastName, setLastName] = useState(profile.l_name || '');
    const [title, setTitle] = useState({});
    const [titleFilter, setTitleFilter] = useState('All');
    const [availableTitles, setAvailableTitles] = useState(defaultTitles);
    const [goal, setGoal] = useState({});
    const [location, setLocation] = useState(profile.location || '');
    const [dob, setDob] = useState(profile.dob || '');
    const [profileIntro, setProfileIntro] = useState(profile.intro_summary || '');
    const [experience, setExperience] = useState(profile.experience || '');
    const [education, setEducation] = useState(profile.education || '');
    const [errors, setErrors] = useState({});
    const [displayedError, setDisplayedError] = useState('');
    const [tags, setTags] = useState(profile.tags || []);

    // LinkedIn is always the first portfolio item
    const sortPortfolios = (portfolios) => {
        return portfolios?.sort((a, b) => {
          if (a.icon === 'linkedin') return -1;
          if (b.icon === 'linkedin') return 1;
          return 0;
        });
    };

    const [accomplishments, setAccomplishments] = useState([]);
    const [portfolios, setPortfolios] = useState([]);      
    const [portfolioEditVisible, setPortfolioEditVisible] = useState(false);
    const [portfolioSelection, setPortfolioSelection] = useState([{id:'1', icon:'linkedin'}, {id:'2', icon:'avvo'}, {id:'3', icon:'behance'}, {id:'4', icon:'fiverr'}, {id:'5', icon:'instagram'}, {id:'6', icon:'medium'}, {id:'7', icon:'upwork'}, {id:'8', icon:'vimeo'}, {id:'9', icon:'zocdoc'}, {id:'10', icon:'generic'}, {id:'11', icon:'github'}]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const isSelectedPortfolioInPortfolios = selectedPortfolio && portfolios?.some(portfolio => portfolio.icon === selectedPortfolio.icon);
    const portfolioEditorRef = useRef(null);
    const PREFIX = "https://";
    const initialUrl = selectedPortfolio ? selectedPortfolio.url || PREFIX : PREFIX;
    const urlPattern = new RegExp(
        /^(https:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([/?].*)?$/
    );
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [url, setUrl] = useState(initialUrl);

    // Tabs to switch between background & impressive accomplishments
    const [activeTab, setActiveTab] = useState('background');
    
    // Close the portfolio editor when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (portfolioEditorRef.current && !portfolioEditorRef.current.contains(event.target)) {
            setPortfolioEditVisible(false); // Close the editor if click is outside
          }
        };
      
        if (portfolioEditVisible) {
          document.addEventListener('mousedown', handleClickOutside);
        }
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [portfolioEditVisible]); // Re-run when portfolioEditVisible changes
      
    // Displays an error if fields were missed
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const concatenatedErrors = Object.values(errors).join("\n");
            setDisplayedError(`Your profile is not complete. \n${concatenatedErrors}`)
        } else {
            setDisplayedError("");
        }
    }, [errors]);

    useEffect(() => {
        // Check if profile url is populated
        if (profile.user_avatar && profile.user_avatar.url) {
            setProfileAvatar(formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts));
        }
    }, [profile.user_avatar]);

    // Google Places API Key
    const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const inputRef = useRef(null);

    // Loads the Google Places API
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsKey,
        libraries: libraries,
    });

    // Initialize and configure a Google Maps Autocomplete feature for a given input field.
    useEffect(() => {
        if (isLoaded && inputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
            { types: ["(cities)"] }
        );
      
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
      
            let city, state, country = "Unknown";
            for (const component of place.address_components) {
                if (component.types.includes("locality") || component.types.includes("administrative_area_level_3")) {
                    city = component.long_name;
                } else if (
                    component.types.includes("administrative_area_level_1")
                ) {
                    state = component.long_name;
                } else if (component.types.includes("country")) {
                    country = component.long_name;
                }
            }
      
            const locationValue = `${city}, ${state}, ${country}`;
            handleLocationChange({ target: { value: locationValue } });
          });
        }
    }, [isLoaded]);

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Toggle tab state
    };

    // Handles the display for adding a role or not being able to add a role
    const NoOptionsMessage = (props) => {
        const {
            selectProps: { inputValue }
        } = props;
        
        const handleClick = () => {
            let formattedInput;
            // Format tag input values
            if (props.selectProps.onCreateOption.name === "handleTitleChange") {
                // Remove all special characters except dashes '-' and spaces ' '
                formattedInput = inputValue.replace(/\b\w/g, char => char.toUpperCase());
                props.selectProps.onChange({ 
                    label: formattedInput,
                    options: [{ role_name: formattedInput, label: formattedInput, category: "Contribution" }]
                });
            // } else {
            //     props.selectProps.onChange({ role_name: formattedInput, label: formattedInput });
            }
            
            props.selectProps.onInputChange('');  // Clear the input value
            props.selectProps.onMenuClose();      // Close the dropdown
        }        
      
        return (
            <components.NoOptionsMessage {...props}>
                <div className="select-no-options" onClick={handleClick}>
                    {inputValue ? `Add: ${inputValue}` : "No options"}
                </div>
            </components.NoOptionsMessage>
        );
    };
    
    /**
     * The `validateFields` function checks for empty values in specified fields and verifies
     * accomplishments if present.
     * @returns The `validateFields` function is returning an object `newErrors` which contains error
     * messages for each field that is empty or only contains whitespace. If there are accomplishments
     * present, it also checks for errors related to accomplishments using the `verifyAccomplishments`
     * function and adds any errors to the `newErrors` object under the key "accomplishments".
     */
    const validateFields = () => {
        const fields = [
            { name: "\"First Name\"", value: firstName },
            { name: "\"Last Name\"", value: lastName },
            { name: "\"I'm am a/an\"", value: title.role_name },
            { name: "\"and I want to\"", value: goal.value },
            { name: "\"Location\"", value: location },
            { name: "\"Introduction\"", value: profileIntro },
            { name: "\"Experience\"", value: experience },
            { name: "\"Education\"", value: education },
        ];
      
        const newErrors = {};
      
        fields.forEach((field) => {
            if (!field.value || field.value.trim() === "") {
                newErrors[field.name] = `${field.name} is empty.`;
            }
        });

        if(accomplishments.length > 0) {
            const accomplishmentError = verifyAccomplishments();
            if (accomplishmentError) {
                newErrors["accomplishments"] = accomplishmentError;
            }
        }
        
        return newErrors;
    };
    
    /**
     * The function `verifyAccomplishments` checks if any accomplishments have either an empty title or
     * description, but not both.
     * @param errors - It looks like the `verifyAccomplishments` function is checking for empty titles
     * or descriptions in a list of accomplishments. The `errors` parameter is not being used in the
     * provided code snippet. If you have any specific questions or need further assistance with this
     * code, feel free to ask!
     * @returns The function `verifyAccomplishments` will return the string "Accomplishments must have
     * both a title and description" if it finds any accomplishment with either an empty title or
     * description, but not both.
     */
    const verifyAccomplishments = () => {
        let hasEmptyTitleOrDescription = false;

        accomplishments.forEach((accomplishment) => {
            if (accomplishment.metadata?.op !== "delete") {
                const titleTrimmed = accomplishment.title.trim();
                const bodyTrimmed = accomplishment.body.trim();
                // Check if either title or description is empty but not both
                if (titleTrimmed === "" || bodyTrimmed === "") {
                    hasEmptyTitleOrDescription = true;
                    return; // break out of the forEach loop
                }
            }
        });


        if (hasEmptyTitleOrDescription) { return "The accomplishment content must have both a title and a description"; }

    };

    // Trigger loadUserProfile when the component mounts
    useEffect(() => {
        loadUserProfile(userId);
    }, [userId]);

    /**
     * The function `findInitialTitle` searches for a matching title value within a list of default
     * titles and returns the matching option.
     * @param titleValue - intial/selected title value
     * @returns If a match is found in the `defaultTitles` array based on the `titleValue` provided,
     * the matching option object is returned. If no match is found, `null` is returned.
     */
    const findInitialTitle = (titleValue) => {
        if (!titleValue) return null;
        for (const group of defaultTitles) {
            const match = group.options.find(option => option.role_name?.toLowerCase() === titleValue.toLowerCase());
            if (match) {
                return match;
            }
        }
        return null;
    };

    // Update input fields when the profile is loaded
    useEffect(() => {
        if (profile) {
            // Set the title and goal based on the tags
            const { title: getTitle, goal: getGoal } = getTitleAndGoal(profile.tags);
            setFirstName(profile.f_name || '');
            setLastName(profile.l_name || '');
            setTitle(findInitialTitle(getTitle) || {});
            setGoal(defaultGoals.find(option => option.value === getGoal) || '');
            setLocation(profile.location || '');
            setDob(profile.dob || null);
            profile.intro_summary = profile.intro_summary ? removeMarkdown(profile.intro_summary, 'url') : '';
            setProfileIntro(profile.intro_summary || '');
            profile.experience = profile.experience ? removeMarkdown(profile.experience, 'url') : '';
            setExperience(profile.experience || '');
            profile.education = profile.education ? removeMarkdown(profile.education, 'url') : '';
            setEducation(profile.education || '');
            setPortfolios(sortPortfolios(profile.portfolios) || []);
            setTags(profile.tags || []);
        }
    }, [profile]);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.firstName;
            return newErrors;
        });

        setProfile((prevProfile) => ({ 
            ...prevProfile, 
            f_name: event.target.value 
        }));
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.lastName;
            return newErrors;
        });

        setProfile((prevProfile) => ({ 
            ...prevProfile, 
            l_name: event.target.value 
        }));
    };

    /**
     * The `handleTitleChange` function updates the title based on the selected option and clears any
     * errors related to the title.
     * @param selectedOption - The `selectedOption` parameter in the `handleTitleChange` function is an
     * object that represents the option selected by the user. It likely contains properties such as
     * `role_name`, `label`, and `category`. The function checks if the selected option is not already
     * in the `availableTitles` array
     */
    const handleTitleChange = (selectedOption) => {
        // Limit the input value to 36 characters
        if (selectedOption.role_name?.length > 36) return;
        // Flatten the availableTitles options to check if selectedOption exists
        const allOptions = availableTitles.flatMap(title => title.options);

        if (selectedOption && !allOptions.some(option => option.role_name?.toLowerCase() === selectedOption.role_name?.toLowerCase())) {
            selectedOption = { 
                role_name: selectedOption.role_name, 
                label: selectedOption.label, 
                category: "Contribution" 
            };
            //console.log("selectedOption", selectedOption);
        }

        // Set the title value to the selected option
        setTitle(selectedOption);
        
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.title;
            return newErrors;
        });

        // Reformat the selected option to match the tags format
        selectedOption = { display_name: selectedOption.label, id: selectedOption.role_name, category: selectedOption.category };

        // Update the state with the combined tags, and remove the previous title tag
        const updatedTags = updateProfileTags(tags, selectedOption, "contrib", true);
        setTags(updatedTags);
 
        setProfile((prevProfile) => ({
             ...prevProfile,
             tags: updatedTags
        }));
    };

    /**
     * The function `handleTitleInputChange` limits the input value to 36 characters and then sets
     * available titles to default titles.
     * @param input - The `input` parameter in the `handleTitleInputChange` function represents the
     * value entered into a title input field. The function limits the input value to 36 characters and
     * sets available titles to a default list if the input exceeds this limit.
     * @returns If the input length is greater than 36 characters, nothing is being returned
     * explicitly. The function will exit early without setting any new available titles.
     */
    // const handleTitleInputChange = (input) => {
    //     // Limit the input value to 36 characters
    //     if (input && input.length > 36) return;
    //     setAvailableTitles(defaultTitles);
    // }

    const filteredTitles = () => {
        if (titleFilter === 'All') {
            return defaultTitles;
        }
        
        return defaultTitles.filter(group => group.label?.toLowerCase() === titleFilter.toLowerCase());
    };
    // This lists only the label, no grouping in the UI. Keep this later use?
    // const filteredTitles = () => {
    //     if (titleFilter === 'All') {
    //         return defaultTitles.flatMap(group => group.options);
    //     }
    //     const filteredGroup = defaultTitles.find(group => group.label === titleFilter);
    //     return filteredGroup ? filteredGroup.options : [];
    // };
    
    const handleGoalChange = (selectedOption) => {
        setGoal(selectedOption.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.goal;
            return newErrors;
        });

        // Reformat the selected option to match the tags format
        selectedOption = { display_name: selectedOption.label, id: selectedOption.value, category: selectedOption.category };

        // Update the state with the combined tags, and remove the previous title tag
        const updatedTags = updateProfileTags(tags, selectedOption, "objective", true);
        setTags(updatedTags);
 
        setProfile((prevProfile) => ({
             ...prevProfile,
             tags: updatedTags
        }));
    };

    /**
     * The `handleTagAdd` function updates profile tags with new tag data based on the selected option.
     * @param selectedOption - The `selectedOption` parameter is an object that represents the option
     * that has been selected by the user. It typically contains properties such as `label` for the
     * display name of the option, `value` for the unique identifier of the option, and `category` for
     * the category to which the option
     * @param type - The `type` parameter in the `handleTagAdd` function is used to specify the type of
     * action being performed when adding a tag. It could be used to differentiate between different
     * types of tag additions or to determine how the tag should be processed within the function.
     */
    const handleTagAdd = (selectedOption, type) => {
        if (selectedOption) {
            const newData = {
                display_name: selectedOption.label,
                id: selectedOption.value,
                category: selectedOption.category
            };
            
            // Update the state with the combined tags
            const updatedTags = updateProfileTags(tags, newData, type);
            setTags(updatedTags);

            setProfile((prevProfile) => ({
                ...prevProfile,
                tags: updatedTags
            }));
        }
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.location;
            return newErrors;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            location: e.target.value
        }));
    };

    const handleDeleteLocation = (e) => {
        e.preventDefault();
        setLocation(''); // Clear the location value
        setProfile((prevProfile) => ({
            ...prevProfile,
            location: ''
        }));
    }
    
    const handleDobChange = (e) => {
        setDob(e.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.dob;
            return newErrors;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            dob: e.target.value
        }));
    };

    const handleDeleteDob = (e) => {
        e.preventDefault();
        setDob(null); // Clear the dob value
        setProfile((prevProfile) => ({
            ...prevProfile,
            dob: ''
        }));
    };
    
    const handleProfileIntroChange = (event) => {
        setProfileIntro(event.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.profileIntro;
            return newErrors;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            intro_summary: event.target.value
        }));
    };
    
    const handleExperienceChange = (event) => {
        setExperience(event.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.experience;
            return newErrors;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            experience: event.target.value
        }));
    };
      
    const handleEducationChange = (event) => {
        setEducation(event.target.value);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.education;
            return newErrors;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            education: event.target.value
        }));
    };      

    // TODO: This causes a backend API call, maybe use userMetata in local storage or state of data or don't do anythign at all besides closing the edit mode?
    const handleCancel = () => {
        navigate(`/profile/${userId}?is_profile_view=true`);
    }

    /**
     * The function `uploadFile` is an asynchronous function that handles the uploading of a file,
     * specifically an avatar image, and updates the user's profile with the new avatar image.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * file upload. It is typically an event object that is passed to an event handler function when a
     * file is selected for upload. The `event.target` property refers to the element that triggered
     * the event, and `event.target.files
     */
    const uploadFile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_change_avatar"); }
            setLoading(true);

            // Resize image, expect File object in return, not an actual file/image
            const resizedFileData = await resizeImage(file);
            
            let avatarId = null;
            // Add avatar ID
            if (typeof profile.user_avatar != 'undefined' 
                && profile.user_avatar
                && profile.user_avatar.id) {
                    avatarId = profile.user_avatar.id;
            }

            // Upload image
            const { isSuccess: changeAvatarSuccess, resp: changeAvatarResp, err: changeAvatarErr } = 
                await changeUserProfileAvatar(userId, avatarId, resizedFileData, authToken);

            if (changeAvatarSuccess) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Image Upload || Success", changeAvatarResp); }
                
                setProfile(prevProfile => ({
                    ...prevProfile,
                    "user_avatar": changeAvatarResp.results.user_avatar
                }));

                // Update the profile image in the nav bar
                updateUserMetadata(changeAvatarResp.results.user_avatar, 'user_avatar');
                forceRefresh();
                setLoading(false);
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error uploading profile image:", changeAvatarErr); }
                if (changeAvatarResp) {
                    // If unauthorized, log out the user's session
                    if (changeAvatarResp.status_code === "UNAUTHORIZED") { logout(); }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Empty response:", changeAvatarErr); }
                }
                setLoading(false);
                setFailedLoading(true);
            }
        } else {
            setFailedLoading(true);
        }
    };

    /**
     * The `handleSubmit` function processes form data, validates fields, updates user profile and
     * contents, and navigates to the profile page if successful.
     * @param event - The `event` parameter in the `handleSubmit` function is an event object that
     * represents the event being handled, in this case, a form submission event. The
     * `event.preventDefault()` method is called to prevent the default behavior of form submission,
     * allowing you to handle the form submission manually using the rest of
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = validateFields();
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const [city, province, country] = location.split(", ").map((part) => part.trim());
            const profileData = {
                first_name: firstName,
                last_name: lastName,
                goal: goal.value,
                city,
                province,
                country,
                dob,
                intro_summary: profileIntro,
                experience,
                education,
                portfolios,
                tags
            };
    
            try {
                await Promise.all([
                    changeUserProfile(userId, profileData),
                    changeUserContents(userId, accomplishments)
                ]);

                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Both functions executed successfully"); }
                // TODO: This initiates a refresh of the profile page. 
                // There should be a better way to use the data that's already in memory.
                navigate(`/profile/${userId}?is_profile_view=true`);
            } catch (error) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("An error occurred:", error); }
            }
        }
    };
    
    /**
     * The function `changeUserContents` updates a user's accomplishments and handles errors
     * accordingly.
     * @param userId - The `userId` parameter in the `changeUserContents` function represents the
     * unique identifier of the user whose contents are being updated. This identifier is typically
     * used to locate and modify the specific user's data in the system.
     * @param data - The `data` parameter in the `changeUserContents` function likely contains
     * information about the user's accomplishments that need to be updated. This data is passed to the
     * `updateUserContents` function along with the `userId` and `authToken` parameters to update the
     * user's contents.
     */
    async function changeUserContents(userId, data) {
        const defErrorMsg = "There was a fatal error updating your accomplishments. Please try again later.";
        try {
            const { results: updateUserResp } = await updateUserContents(userId, data, authToken);

            if (updateUserResp) {
                let hasError = false;
                updateUserResp.forEach(resp => {
                    if (resp.isSuccess) {
                        if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Accomplishment Update || Success:", resp.resp); }
                    } else {
                        if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Accomplishment Update || Failure:", resp.resp, resp.err); }
                        hasError = true;
                        // Log out user if the token session is invalid (most likely expired).
                        if (resp?.status_code === "UNAUTHORIZED") { logout(); }
                    }
                });

                if (hasError) {
                    setDisplayedError(defErrorMsg);
                    throw new Error();
                } else {
                    // Remove the metadata info from the accomplishment to reset the state
                    accomplishments.forEach(accomplishment => {
                        if (accomplishment.hasOwnProperty('metadata')) {
                            delete accomplishment.metadata;
                        }
                    });
                }
                
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Accomplishment Update || Failure:", updateUserResp); }
                setDisplayedError(defErrorMsg);
                throw new Error();
            }
        } catch (error) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error updating user's accomplishments:", error); }
            throw error; // Rethrow the error to be caught by the calling function
        }
    }

    /**
     * The function `changeUserProfile` updates a user's profile information and redirects them to
     * their profile page if the update is successful.
     * @param userId - The userId parameter is the unique identifier of the user whose profile is being
     * updated. It is used to identify the user in the updateUserProfile function.
     * @param data - The `data` parameter is an object that contains the user's profile data. It can
     * include properties such as `profileIntro`, `experience`, and `education`. These properties are
     * expected to be in a specific format, so the code is using the `convertToMarkdown` function to
     * convert them to
     */
    async function changeUserProfile(userId, data) {
        // Formatting covers lists and links
        if (data.intro_summary) { data.intro_summary = convertToMarkdown(profileIntro); }
        if (data.experience) { data.experience = convertToMarkdown(experience); }
        if (data.education) { data.education = convertToMarkdown(education); }
        const defErrorMsg = "There was a fatal error updating your profile. Please try again later.";

        try {
            const { isSuccess: updateUserSuccess, resp: updateUserResp, err: updateUserErr } = await updateUserProfile(userId, data, authToken);

            if (updateUserSuccess) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Update || Success:", updateUserResp); }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Update || Error:", updateUserResp, updateUserErr); }
                if (updateUserResp) {
                    // Log out user if the token session is invalid (most likely expired).
                    if (updateUserResp.status_code === "UNAUTHORIZED") { 
                        logout();
                    } else {
                        setDisplayedError(defErrorMsg);
                        throw new Error();
                    }
                } else {
                    setDisplayedError(defErrorMsg);
                    throw new Error();
                }
            }
        } catch (error) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error updating user's profile:", error); }
            throw error;
        }
    }

    /* The above code is a JavaScript function called `loadUserContents` that is using the `useCallback`
	hook to asynchronously load a user's contents based on their `userId`. */
	const loadUserContents = useCallback(async (userId) => {
		// Get the user's projects
		const { isSuccess: getContentsSuccess, resp: getContentsResp, err: getContentsErr } = await getUserContents(userId, [{ "key": "category", "value": "accompl" }], authToken);

		if (getContentsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Content Search || Success", getContentsResp); }
			if (getContentsResp.results != null && getContentsResp.results) {
				// Update the state with the user's projects, don't include the content marked with the delete operation
                const contents = getContentsByCategory(getContentsResp.results, "accompl");
				setAccomplishments(contents);
			} else {
				setAccomplishments([]);
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("User Content Search || Error", getContentsResp, getContentsErr); }
			if (getContentsResp) {
				// If unauthorized, log out the user's session
				if (getContentsResp.status_code === "UNAUTHORIZED") { logout(); }
			}
			// TODO: Handle error cases
		}
	}, [authToken, logout]);

    useEffect(() => {
		setAccomplishments([]);
		loadUserContents(userId);
	}, [userId]);

    /**
	 * The function `loadUserProfile` is an asynchronous function that loads a user's profile and updates
	 * the state with the profile data.
	 * @param userId - The `userId` parameter is the unique identifier of the user whose profile we want
	 * to load.
	 */
	async function loadUserProfile(userId) {
		setLoading(true);
		const { isSuccess: getProfileSuccess, resp: getProfileResp, err: getProfileErr } = 
            await getUserProfile(userId, [{ "key": "details", "value": "true" }, { "key": "tags", "value": "true" }], authToken);

		if (getProfileSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Search || Success", getProfileResp); }
            // Process/format the user profile data
            getProfileResp.results = processUserProfileData(getProfileResp.results);
			setProfile(getProfileResp.results);
			updateUserMetadata(getProfileResp.results.user_avatar, "user_avatar");
			forceRefresh();
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Profile Search || Error", getProfileResp, getProfileErr); }
            if (getProfileResp) {
                // Log out user if the token session is invalid (most likely expired).
                if (getProfileResp.status_code === "UNAUTHORIZED") { 
                    logout(); 
                } else {
                    setDisplayedError("There was an error retrieving your profile. Please try again later.");
                }
            } else {
                setDisplayedError("There was a fatal error retrieving your profile. Please try again later.");
            }
		}
    	setLoading(false);
  	}

    /**
     * The `handlePortfolioClick` function toggles the visibility of the portfolio editor and updates
     * the selected portfolio with full details if a portfolio is provided.
     * @param portfolio - The `portfolio` parameter in the `handlePortfolioClick` function likely
     * represents a specific portfolio item that the user has clicked on or interacted with. This could
     * be an object containing details about the portfolio, such as its icon, name, description, or any
     * other relevant information.
     * @param edit - The `edit` parameter in the `handlePortfolioClick` function is a boolean value
     * that indicates whether the user is currently in edit mode for the portfolio.
     */
    const handlePortfolioClick = (portfolio, edit) => {
        let fullPortfolio = null;
        // Toggle the visibility of the portfolio editor
        if(!edit)
            setPortfolioEditVisible(prevState => !prevState);
        // If a portfolio is provided, update the selectedPortfolio with the full details from the main list
        if (portfolio) {
            fullPortfolio = portfolios?.find(p => p.icon === portfolio.icon) || portfolio;
            setSelectedPortfolio(fullPortfolio);
        } else {
            // Optionally handle the case where no portfolio is provided
            setSelectedPortfolio(fullPortfolio);
        }
    };

    // Add a new initial accomplishment to the list
    const handleAddAccomplishment = () => {
        const newAccomplishment = {
            id: uuidv4(), // placeholder for now, though must exist for the UI to support adding multiple accomplishments at once
            type: "text",
            status: "A",
            title: "",
            body: "",
            category: "accompl",
            metadata: { "op": "create" } // Add operation
        };
        const updatedAccomplishments = [newAccomplishment, ...accomplishments];
        setAccomplishments(updatedAccomplishments);
    };
    
    /**
     * The function `handlePortfoliosUpdate` updates a list of portfolios with a selected portfolio,
     * sorting them and toggling the visibility of the portfolio editor if a valid URL is provided.
     */
    const handlePortfoliosUpdate = () => {
        // Validate the URL
        setIsValidUrl(urlPattern.test(url));
        // Update the selected portfolio with the URL and add it to the list of portfolios
        if ("icon" in selectedPortfolio && urlPattern.test(url)){
            setPortfolios(prevPortfolios => {
                // Ensure that prevPortfolios is initialized properly if it's undefined or null
                prevPortfolios = prevPortfolios || [];
        
                if (prevPortfolios.length > 0) {
                    const index = prevPortfolios.findIndex(p => p.icon === selectedPortfolio.icon);
                    let newPortfolios = [...prevPortfolios];
                    if (index > -1) {
                        // Portfolio exists, update it
                        newPortfolios[index] = selectedPortfolio; // already includes the updated URL
                    } else {
                        // Portfolio does not exist, add it
                        newPortfolios.push(selectedPortfolio);
                    }

                    // Update the profile with the new list of portfolios
                    setProfile((prevProfile) => ({
                        ...prevProfile,
                        portfolios: newPortfolios
                    }));

                    return sortPortfolios(newPortfolios); // Sort the portfolios after update/add
                } else {
                    // Update the profile with the selectedPortfolio
                    setProfile((prevProfile) => ({
                        ...prevProfile,
                        portfolios: [selectedPortfolio]
                    }));
                    // If there are no previous portfolios, start the list with the selectedPortfolio
                    return sortPortfolios([selectedPortfolio]);
                }
            });
        
            // Toggle the visibility of the portfolio editor
            setPortfolioEditVisible(prevVisible => !prevVisible);
        }
    };
    
    // Used by the portfolio editor to update the URL of the selected portfolio
    const handleUrlChange = (event) => {
        let newUrl = event.target.value.trim();

        // Remove "http://" and "https://" (especilaly when pasting a URL with "https://")
        newUrl = newUrl.replace(/^https?:\/\//, '');

        if(newUrl === "https:/") return;
        // Ensure the URL starts with "https://"
        if (!newUrl.startsWith(PREFIX)) {
            newUrl = PREFIX + newUrl.replace(/^https?:\/\//, '');
        }

        // Prevent deletion beyond the prefix
        if (newUrl.length < PREFIX.length) {
            newUrl = PREFIX;
        }

        setSelectedPortfolio(currentPortfolio => ({
            ...currentPortfolio,
            url: newUrl
        }));
        setUrl(newUrl);
    };

    const handleUrlSelection = (e) => {
        // Prevent selecting the prefix
        const input = e.target;
        if (input.selectionStart < PREFIX.length) {
            input.setSelectionRange(PREFIX.length, PREFIX.length);
        }
    };

    const handleMouseUp = (e) => handleUrlSelection(e);
      
    // Used by the portfolio editor to delete a portfolio
    const handleDeletePortfolioClick = (event, selectedPortfolio) => {
        event.stopPropagation(); // Stop event from propagating to parent
        // Delete portfolio
        const updatedPortfolios = portfolios.filter((portfolio) => portfolio.id !== selectedPortfolio.id);
        setPortfolios(updatedPortfolios);

        setProfile((prevProfile) => ({
            ...prevProfile,
            portfolios: updatedPortfolios
        }));
    };

    /**
     * The function `handleAccomplishmentChange` updates the accomplishments array based on user input
     * and removes any errors related to accomplishments.
     * @param e - The 'e' parameter in the `handleAccomplishmentChange` function is typically an event
     * object that represents an event being handled, such as a change event in a form input field. It
     * contains information about the event, including the target element that triggered the event (in
     * this case, an
     * @param id - The `id` parameter in the `handleAccomplishmentChange` function represents the
     * unique identifier of the accomplishment that is being updated. It is used to identify the
     * specific accomplishment in the `accomplishments` array that needs to be updated with the new
     * values provided by the user.
     */
    const handleAccomplishmentChange = (e, id) => {
        const { name, value } = e.target;
        // Only enable update if either title or body has changed (i.e. when user has typed something)
        // TODO: This should be updated to compare the original value with the new value
        const updatedAccomplishments = accomplishments.map((accomplishment) =>
            accomplishment.id === id 
                ? { ...accomplishment, [name]: value, metadata: accomplishment.metadata ? accomplishment.metadata : { op: "update" } }
                : { ...accomplishment }
        );

        // Update the accomplishments array with the new values
        setAccomplishments(updatedAccomplishments);

        // Remove any errors related to accomplishments
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.accomplishments;
            return newErrors;
        });

    };

    const handleDeleteAccomplishmentClick = (e, id) => {
        e.stopPropagation(); // Stop event from propagating to parent
        const updatedAccomplishments = updateMetadataOpById(accomplishments, id, "delete");
        setAccomplishments(updatedAccomplishments);
    };

    /**
     * The function `handleDeleteTagClick` is used to remove a specific tag from a nested data
     * structure based on its id and type.
     * @param event - The `event` parameter is the event object that is passed to the event handler
     * function. It contains information about the event that occurred, such as the type of event,
     * target element, and any additional data related to the event. In this case, it is used to
     * prevent the event from propagating
     * @param id - The `id` parameter in the `handleDeleteTagClick` function represents the unique
     * identifier of the tag that needs to be deleted.
     * @param type - The `type` parameter in the `handleDeleteTagClick` function represents the type of
     * tag that is being deleted. It is used to identify the specific tag within the `profile.tags`
     * array that needs to be updated or removed.
     */
    const handleDeleteTagClick = (event, id, type) => {
        event.stopPropagation(); // Stop event from propagating to parent
        const updatedTags = tags.map(tag => {
            if (tag.type === type) {
                return {
                    ...tag,
                    categories: tag.categories.map(category => ({
                        ...category,
                        tags: category.tags.filter(i => i.id !== id)
                    }))
                };
            }
            return tag;
        });

        setProfile((prevProfile) => ({
            ...prevProfile,
            tags: updatedTags
        }));
    };

    return (
        <Layout tabHeader="Profile">
            <div className='user-profile-page-container'>
                <div className='profile-container'>
                    <div className='profile-pic-section'>
                        <div className='profile-pic-info'>
                            <div className='editable-section'>
                                <div className='profile-upper-section'>
                                    <div className="large-profile-circle" style={{backgroundImage: `url(${profileAvatar})`}}>
                                        { authToken && userMetadata && loggedInUserId === userId && (
                                            <div className="profile-pic-subsection-button">
                                                <div className="profile-picture-wrapper">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={uploadFile}
                                                        style={{ display: 'none' }}
                                                        id="fileUploader"
                                                    />
                                                    <label htmlFor="fileUploader" className="upload-button">
                                                        {loading ? 'Uploading...' : (failedLoading ? 'Failed, try again.' : '')}
                                                    </label>
                                                </div>
                                                <button className="camera-icon-button" onClick={() => document.getElementById('fileUploader').click()}>
                                                    <img src={cameraIcon} alt="Camera Icon" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Alert variant="danger" className="animated-fast fadeInDownMenu">
                                    <Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
                                        {displayedError}
                                    </Alert.Heading>
                                </Alert>
                                <div className='profile-intro profile-description-card'>
                                    <div className='fields'>
                                        <div className='field-row'>
                                            <div>First Name:</div>
                                            <div className='profile-name'>
                                                <Form.Control
                                                type="text"
                                                id="f_name"
                                                value={firstName}
                                                placeholder="First Name*"
                                                onChange={handleFirstNameChange}
                                                />
                                            </div> 
                                            <div>Last Name:</div>
                                            <div className='profile-name'>
                                                <Form.Control
                                                type="text"
                                                id="l_name"
                                                value={lastName}
                                                placeholder="Last Name*"
                                                onChange={handleLastNameChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='field-row'>
                                            <div>I am a/an: </div>
                                            <div className='profile-title'>
                                            <CreatableSelect
                                                id="title"
                                                value={title ? title : ''}
                                                //onInputChange={handleTitleInputChange}
                                                onChange={handleTitleChange}
                                                onCreateOption={handleTitleChange}
                                                placeholder="occupational title*"
                                                options={filteredTitles()}
                                                isCreatable={true}
                                                isSearchable
                                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                                components={{ NoOptionsMessage }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                menuPosition={'fixed'} // Required for portal to work correctly
                                                menuPlacement={'auto'} // Top or bottom based on the available space
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderColor: '#edecec',  // Set border color to gray
                                                        borderRadius: '0.75em',  // Set border radius to 0.75em
                                                        margin: '0px 5px 5px 5px'
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided
                                                    }),
                                                    groupHeading: (provided, state) => ({
                                                        ...provided,
                                                        padding: '10px',
                                                    })
                                                }}
                                            />
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <div> and want to: </div>
                                                <div className='profile-goal'>
                                                    <Select
                                                        id="goal"
                                                        value={goal ? goal : ''}
                                                        onChange={handleGoalChange}
                                                        placeholder="identify my goal*"
                                                        options={defaultGoals}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderColor: '#edecec',  // Set border color to gray
                                                                borderRadius: '0.75em',  // Set border radius to 0.75em
                                                                margin: '0px 5px 5px 5px'
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='field-row'>
                                            <div>Location:</div>
                                            {loadError ? (
                                                <div>
                                                    Sorry, there was an issue loading the Google Maps API. Please try again later.
                                                </div>
                                            ) : (
                                                <div className='profile-location'>
                                                    <div className="input-container">
                                                        <Form.Control
                                                        type="text"
                                                        id="location"
                                                        value={location}
                                                        placeholder="Enter a location*"
                                                        onChange={handleLocationChange}
                                                        ref={inputRef}
                                                        />
                                                        <div
                                                            className="small-delete-button-input"
                                                            onClick={handleDeleteLocation}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='field-row'>
                                            <div>Date of Birth:</div>
                                            <div className='profile-dob'>
                                                <div className="input-container">
                                                    <Form.Control
                                                        type="date"
                                                        id="dob"
                                                        pattern="\d{2}/\d{2}/\d{4}"
                                                        value={dob ? dob : ''}
                                                        placeholder="mm/dd/yyyy"
                                                        onFocus={(e) => e.target.type = 'date'}
                                                        onBlur={(e) => e.target.type = 'text'}
                                                        onChange={handleDobChange}
                                                    />
                                                    <div
                                                        className="small-delete-button-input"
                                                        onClick={handleDeleteDob}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='profile-details'>
                        <div className='profile-intro profile-description-card'>
                            <h3>Introduction</h3>
                            <div className="divider"></div>
                                <div className="textAreaWrapper">
                                    <Form.Control
                                        as="textarea"
                                        name="profile-intro"
                                        rows={5}
                                        cols={40}
                                        maxLength={150}
                                        value={profileIntro}
                                        placeholder=
                                        {"What makes an interesting intro? \n - Your personal mission and next milestone. \n - Something people have to know about you. \n - Experience or personal interest that makes you stand out. \n - A gift you have for your community to encourage a connection."}
                                        onChange={handleProfileIntroChange}
                                    />
                                    <span className='char-counter-textarea'>{profileIntro.length}/150</span>
                                </div>
                        </div>
                        <div className='portfolio-section'>
                            <h3>Portfolios</h3>
                            <div className='portfolio-vertical'>
                                <div className='portfolio-links-container'>
                                    <div className='portfolio-links-inner-container'>
                                        {portfolios?.length > 0 ? (
                                            portfolios.map((portfolio, index) => (
                                                <div className='portfolio-link' key={index} onClick={() => handlePortfolioClick(portfolio)} style={{backgroundImage: `url(/portfolio-icons/${portfolio?.icon}.png)`}}>
                                                    <div className='delete-button' onClick={(e) => handleDeletePortfolioClick(e, portfolio)}/>
                                                </div>
                                            ))
                                        ) : (
                                            <div>No portfolios.</div>
                                        )}
                                        <div className='plus-icon portfolio-link' onClick={handlePortfolioClick}/>
                                    </div>
                                </div>
                                <div className={`portfolio-editor ${portfolioEditVisible ? 'visible fadeInDownMenu animated-fast' : ''}`} ref={portfolioEditorRef}>
                                    <div className='portfolio-editor-inner'>
                                        Choose a portfolio type and enter a link.
                                        <div className='portfolio-links-container'>
                                            <div className='portfolio-links-inner-container centered'>
                                            {portfolioSelection.map((portfolio, index) => (
                                                <div 
                                                className={`portfolio-link ${selectedPortfolio === portfolio ? 'selected' : ''}`}
                                                key={index}
                                                onClick={() => handlePortfolioClick({ ...portfolio, url: portfolio.url || "" }, true)}
                                                style={{ backgroundImage: `url(/portfolio-icons/${portfolio.icon}.png)` }}
                                                />
                                            ))}
                                            </div>
                                        </div>
                                        <div className='edit-portfolio'>
                                            <div className='portfolio-link' style={{backgroundImage: `url(${selectedPortfolio ? `/portfolio-icons/${selectedPortfolio.icon}.png` : ""})`}}/>
                                            <Form.Control 
                                                type="text" 
                                                value={selectedPortfolio ? selectedPortfolio.url || "" : ""}
                                                onChange={handleUrlChange} 
                                                placeholder="https://"
                                                className='portfolio-url-input'
                                                onMouseUp={handleMouseUp}
                                                onFocus={handleUrlSelection}
                                                disabled={!selectedPortfolio || "icon" in selectedPortfolio === false}
                                            />
                                            {
                                                isSelectedPortfolioInPortfolios ? 
                                                (
                                                    <div className='update-portfolio-item' onClick={handlePortfoliosUpdate}>
                                                    Update
                                                    </div>
                                                ) : (
                                                    <div className='plus-icon portfolio-link' onClick={handlePortfoliosUpdate}/>
                                                )
                                            }
                                        </div>
                                        {!isValidUrl && (
                                            <Alert variant="danger" className="url-error-message">
                                                Please enter a valid URL.
                                            </Alert>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tabs">
                            <div
                                className={`tab ${activeTab === 'background' ? 'active' : ''}`}
                                onClick={() => handleTabClick('background')}>
                                Background
                            </div>
                            <div className="circle-separator" />
                            <div
                                className={`tab ${activeTab === 'accomplishments' ? 'active' : ''}`}
                                onClick={() => handleTabClick('accomplishments')}>
                                Impressive Accomplishments
                                <div className={`number-count ${activeTab === 'accomplishments' ? 'active' : ''}`}>
									{accomplishments ? accomplishments.filter(accomplishment => accomplishment.metadata?.op !== 'delete').length : 0}	
								</div>
                            </div>
                        </div>
                        {activeTab === 'background' && (
                            <div className='background-section'>
                                <div className="profile-skillset profile-description-card">
                                        <h3>Experience</h3>
                                        <div className="divider"></div>
                                        <div className="textAreaWrapper">
                                            <Form.Control
                                            as="textarea"
                                            name="experience"
                                            rows={5}
                                            cols={40}
                                            maxLength={300}
                                            value={experience}
                                            onChange={handleExperienceChange}
                                            placeholder={"Share a summary of your experience and accomplishments that make you unique. \n(Go into more detail under 'Impressive Accomplishments')"}
                                            />
                                            <span className='char-counter-textarea'>{experience?.length ? experience.length : 0}/300</span>
                                        </div>
                                </div>
                                <div className='profile-education profile-description-card'>
                                    <h3>Education</h3>
                                    <div className="divider"></div>
                                        <div className="textAreaWrapper">
                                            <Form.Control
                                                as="textarea"
                                                name="education"
                                                rows={5}
                                                cols={40}
                                                maxLength={150}
                                                value={education}
                                                placeholder="List any relevant education and graduation year(s)."
                                                onChange={handleEducationChange}
                                            />
                                            <span className='char-counter-textarea'>{education?.length ? education.length : 0}/150</span>
                                        </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'accomplishments' && (
                            <div className='gray-vertical-container'>
                                {accomplishments.filter(accomplishment => accomplishment.metadata?.op !== 'delete').length > 0 && (
                                    <div className='personal-projects-container'>
                                        <div className='project-cards-feed horizontal-scrolling'>
                                        {accomplishments.filter(accomplishment => accomplishment.metadata?.op !== 'delete').map((accomplishment) => (
                                            <div key={accomplishment?.id ? accomplishment.id : null} className="profile-skillset profile-description-card">
                                                <Form.Control
                                                    type="text"
                                                    id={`${accomplishment?.id}`}
                                                    name="title"
                                                    value={accomplishment?.title}
                                                    placeholder="A title for your accomplishment"
                                                    onChange={(e) => handleAccomplishmentChange(e, accomplishment?.id)}
                                                    maxLength={50}
                                                />
                                                <div className="divider"></div>
                                                <div className="textAreaWrapper">
                                                    <Form.Control
                                                        as="textarea"
                                                        id={`${accomplishment?.id}`}
                                                        name="body"
                                                        rows={12} // large enough to display all 350 characters (maxLength)
                                                        cols={40}
                                                        maxLength={350}
                                                        value={accomplishment?.body}
                                                        placeholder="Go into detail with what you accomplished, why and how you did it."
                                                        onChange={(e) => handleAccomplishmentChange(e, accomplishment?.id)}
                                                    />
                                                    <span className="char-counter-textarea">{accomplishment.body?.length ? accomplishment.body.length : 0}/350</span>
                                                    <div className="small-delete-button-field" onClick={(e) => handleDeleteAccomplishmentClick(e, accomplishment?.id)} />
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                )}
                                <div className='plus-icon' onClick={handleAddAccomplishment} />
                            </div>
                        )}
                    </div>
                </div>
                <div className='profile-second-half'>
                    <div className='profile-second-half-container'>
                        <div className='profile-description-card'>
                            <h3>Skills & Expertise</h3>
                            <div className="divider"></div>
                            <div className='skills-expertise-subsection'>
                                <div className='skills-container'>
                                    {tags && tags.length > 0 ? (
                                        (() => {
                                        const skillTags = tags
                                            .filter(tag => tag.type === 'skill')
                                            .flatMap(tag => tag.categories.flatMap(category => category.tags));

                                        return skillTags.length > 0 ? (
                                            skillTags.map((skill, index) => (
                                            <div className='tag skill' key={index}>
                                                {skill.display_name}
                                                <div
                                                    className='small-delete-button'
                                                    onClick={(e) => handleDeleteTagClick(e, skill.id, 'skill')}
                                                />
                                            </div>
                                            ))
                                        ) : (
                                            <div className='tag skill'>(Skills not speficied)</div>
                                        );
                                        })()
                                    ) : (
                                        <div className='tag skill'>(Skills not speficied)</div>
                                    )}
                                    <Select
                                        id="skill"
                                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'skill')}
                                        placeholder="Select skills represent your capabilities"
                                        options={defaultSkills}
                                        className='skill-dropdown'
                                        value="Select skills represent your capabilities"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#edecec',  // Set border color to gray
                                                borderRadius: '0.75em',  // Set border radius to 0.75em
                                                margin: '0px 5px 0px 0px'
                                            })
                                        }}
                                    />
                                </div>
                                <div className='expertise-container'>
                                    {tags && tags.length > 0 ? (
                                            (() => {
                                            const expertiseTags = tags
                                                .filter(tag => tag.type === 'expertise')
                                                .flatMap(tag => tag.categories.flatMap(category => category.tags));

                                            return expertiseTags.length > 0 ? (
                                                expertiseTags.map((expertise, index) => (
                                                <div className='tag expertise' key={index}>
                                                    {expertise.display_name}
                                                    <div
                                                        className='small-delete-button'
                                                        onClick={(e) => handleDeleteTagClick(e, expertise.id, 'expertise')}
                                                    />
                                                </div>
                                                ))
                                            ) : (
                                                <div className='tag expertise'>(Expertises not speficied)</div>
                                            );
                                            })()
                                        ) : (
                                            <div className='tag expertise'>(Expertises not speficied)</div>
                                        )}
                                    <Select
                                        id="expertise"
                                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'expertise')}
                                        placeholder="Select your entrepreneurial expertises"
                                        options={defaultExpertises}
                                        className='expertise-dropdown'
                                        value="Select your entrepreneurial expertises"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#edecec',  // Set border color to gray
                                                borderRadius: '0.75em',  // Set border radius to 0.75em
                                                margin: '0px 5px 0px 0px'
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='profile-description-card'>
							<h3>Interests</h3>
							<div className="divider"></div>
							<div className='interests-subsection'>
								<div className='interests-container'>
                                    {tags && tags.length > 0 ? (
                                        (() => {
                                        const interestTags = tags
                                            .filter(tag => tag.type === 'interest')
                                            .flatMap(tag => tag.categories.map(category => ({
                                            category: category.category,
                                            tags: category.tags
                                        })));

                                        return interestTags.length > 0 ? (
                                            interestTags.map((category, index) => (
                                            <div key={index} className='category-group'>
                                                <h3>{category.category}</h3>
                                                {category.tags.map((tag, idx) => (
                                                <div className='tag interest' key={idx} style={{ marginBottom: '10px' }}>
                                                    {tag.display_name}
                                                    <div
                                                        className='small-delete-button'
                                                        onClick={(e) => handleDeleteTagClick(e, tag.id, 'interest')}
                                                    >
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                            ))
                                        ) : (
                                            <div className='tag interest'>(Interests not speficied)</div>
                                        );
                                        })()
                                    ) : (
                                        <div className='tag interest'>(Interests not speficied)</div>
                                    )}
                                    <Select
                                        id="interests"
                                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'interest')}
                                        placeholder="Select personal interests that define you"
                                        options={defaultInterests}
                                        className='expertise-dropdown'
                                        value="Select personal interests define you"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#edecec',  // Set border color to gray
                                                borderRadius: '0.75em',  // Set border radius to 0.75em
                                                margin: '0px 5px 0px 0px'
                                            })
                                        }}
                                    />
								</div>
							</div>
						</div>
                                        
                        <div className='personal-projects-container'>
							<h2 className='projects-section-title'>{firstName}'s Startups:</h2>
							<div className='gray-vertical-container'>
								<div className='personal-projects'>
                                    <div className='projects-not-found-message'>
                                        Edit any startups after saving your profile changes.
                                    </div>
								</div>
							</div>
						</div>

                    </div>
                    <div className='profile-pic-subsection profile-pic-subsection-button'>
                    <div className='action-buttons'>
                        <Button variant="secondary" className='secondary' onClick={handleCancel}>Cancel</Button>
                        <Button onClick={handleSubmit}>Save</Button>
                    </div>
                    <Alert variant="danger" className="animated-fast fadeInDownMenu">
                        <Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
                            {displayedError}
                        </Alert.Heading>
                    </Alert>
                </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserProfileEdit;
